<div class="popup-header">
    <h3 class="popup-title">Terms & Privacy Policy</h3>
    <div class="templates-box">
      <div (click)="dialogRef.close()" class="close-icon"><img src="/assets/Assets%20Website/Btn/close.svg"></div>
    </div>
</div>
<div class="terms-text">
  <div class="form-container">
    <div class="input-container">Before continue using application, you need to read and agree to our <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of service</a>
      and <a href="https://credenshare.io/privacy-policy" target="_blank">Privacy Policy</a></div>
  </div>
  <div class="confirm-buttons">
    <button (click)="confirm()" type="button" class="button-default">I have read and agree</button>
    <button class="button-secondary" type="button" (click)="dialogRef.close()">Close</button>
  </div>
</div>
