import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {ShareContentType, ShareForm, ShareFormErrors} from 'src/app/models/share.model';
import {AuthService} from 'src/app/services/auth.service';
import {InputPopupComponent} from '../input-popup/input-popup.component';

import {DataInputTypeService} from '../../services/data-input-type.service';
import {UserSubscription} from 'src/app/models/plan.model';
import {PasswordMeterService} from 'src/app/services/password-meter.service';
import {StrengthData} from 'src/app/models/password-strength-data.model';

import * as moment from 'moment';
import {DataService} from 'src/app/services/data.service';
import {getShareUrl} from '../shares-utils';

@Component({
  selector: 'app-create-share-popup',
  templateUrl: './create-share-popup.component.html',
  styleUrls: ['./create-share-popup.component.scss']
})
export class CreateSharePopupComponent {
  showPassword: boolean = false;
  showSharePassword: boolean = false;
  shareForm = new ShareForm();
  currentIndexForPassCheck: number;

  sharingInputTypes: ShareContentType[];

  formErrors: ShareFormErrors;

  generalPasswordStrength: StrengthData = new StrengthData();

  isShareCreated: boolean = false;
  createdShare: any;
  allShares: any[] = new Array<any>();
  userPlan: UserSubscription;
  maxFields: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CreateSharePopupComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private clipboard: ClipboardService,
    private toastrService: ToastrService,
    public dataInputTypeService: DataInputTypeService,
    private passwordMeter: PasswordMeterService,
    private dataService: DataService
  ) {
    this.userPlan = authService.userPlan;
    if (this.userPlan) {
      this.maxFields = this.userPlan.plan.features.find(f => f.feature_code === 'max_fields').feature_value;
    }

    this.sharingInputTypes = this.dataInputTypeService.shareDataInputTypes;
  }

  checkSecurity(event) {
    event.stopPropagation();
    this.passwordMeter.checkSecurity(event.target.value).then((res: StrengthData) => {
      if (res) {
        this.generalPasswordStrength = res;
      }
    });
  }

  close() {
    this.dialogRef.close(this.isShareCreated ? this.allShares : null);
  }

  copy(string: string, type: number) {
    if (type === 0) {
      this.clipboard.copy(`${getShareUrl()}${string}`);
      this.toastrService.success('Link copied to clipboard!');
    } else if (type === 1) {
      this.clipboard.copy(string);
      this.toastrService.success('Password copied to clipboard!');
    }
  }

  defineWhitelist(type: number) {
    const whiteListPopup = this.dialog.open(InputPopupComponent, {
      width: '50%',
      panelClass: 'popup-container',
      data: {type: type}
    });
    whiteListPopup.afterClosed().subscribe(data => {
      if (data && data.length > 0) {
        for (let d of data) {
          if (type === 0) {
            this.shareForm.ipWhitelist.push(d);
          } else {
            this.shareForm.domainWhitelist.push(d);
          }
        }
      }
    });
  }

  resetScreen() {
    this.isShareCreated = false;
    this.shareForm = new ShareForm();
  }

  inputsValid(): boolean {
    this.formErrors = new ShareFormErrors();
    if (!this.shareForm.name) {
      this.formErrors.nameError = true;
      this.toastrService.error('Secure share\'s title required');
      document.getElementById('name-input').focus();
      return false;
    }
    if (this.shareForm.askPassword && !this.shareForm.passwordValue) {
      this.formErrors.passwordError = true;
      this.toastrService.error('Password is required if password protection is enabled');
      document.getElementById('password-input').focus();
      return false;
    }
    return true;
  }

  updateExpirationType(type: number, maxValue: number) {
    this.shareForm.expirationType = type;
    if (this.shareForm.expirationDate > maxValue) {
      this.shareForm.expirationDate = maxValue;
    }
  }

  calculateExpiration(): string {
    if (this.shareForm.expireFrom === 0) {
      /**
       * expirationType(0) = HOURS
       * expirationType(1) = DAYS
       * expirationType(2) = MONTHS
       */
      switch (this.shareForm.expirationType) {
        case 0: {
          return moment().add(this.shareForm.expirationDate, 'hours').toISOString();
        }
        case 1: {
          return moment().add(this.shareForm.expirationDate, 'days').toISOString();
        }
        case 2: {
          return moment().add(this.shareForm.expirationDate, 'months').toISOString();
        }
      }
    } else {
      return null;
    }
  }

  calculateSeconds(): number {
    if (this.shareForm.expireFrom === 0) {
      return null;
    } else {
      switch (this.shareForm.expirationType) {
        case 0: {
          return Math.ceil(3600 * this.shareForm.expirationDate);
        }
        case 1: {
          return Math.ceil(86400 * this.shareForm.expirationDate);
        }
        case 2: {
          return Math.ceil(2629743.83 * this.shareForm.expirationDate);
        }
      }
    }
  }

  passwordSelected(event) {
    this.shareForm.secureView = event;
  }

  createShare() {
    if (this.inputsValid()) {
      const body = {
        title: this.shareForm.name,
        data: JSON.stringify(this.dataInputTypeService.shareData.value),
        description: this.shareForm.description,
        created_by: this.authService.currentUser.username,
        encryption_type: this.shareForm.encryptionType,
        access_counts_left: this.shareForm.viewingTimes && !this.shareForm.unlimitedViewTime ? this.shareForm.viewingTimesValue : null,
        attempts_left: this.shareForm.failedAttemptsTolerance === true ? this.shareForm.failedAttemptsValue : null,
        passcode: this.shareForm.askPassword === true ? this.shareForm.passwordValue : null,
        view_protected: this.shareForm.secureView,
        timed_view: this.shareForm.timedView === true ? this.shareForm.timedViewValue : null,
        requires_login: this.shareForm.requireLogin,
        requires_mfa: this.shareForm.requireMfa,
        expired_at: this.calculateExpiration(),
        expired_in_seconds_after_opened: this.calculateSeconds(),
        view_protected_message: this.shareForm.secureViewMessage,
        only_owner_can_delete: this.shareForm.onlyOwnerCanDelete,
      };

      const currentTeam = this.authService.getCurrentTeamId();
      if (currentTeam) {
        body['organization_id'] = currentTeam;
      }

      this.dataService.createShare(body).then(result => {
        if (result.success) {
          this.allShares.push(result.data);
          this.isShareCreated = true;
          this.createdShare = result.data;
          this.createdShare['shareLink'] = getShareUrl() + result.data.short_code;
          this.authService.getSubscription();
        }
      });
    }
  }
}
