<div class="crs-table-wrapper" *ngIf="hasAccess">
  <div class="filters-container">
    <a routerLink="/my-teams" class="back-link">< Back</a>
    <div class="right-groups">
      <div class="search-container">
        <span class="material-icons">
            search
        </span>
        <input (keyup)="filtering($event)" type="text" placeholder="Search..."/>
      </div>
      <button class="button-default mg-left-10" (click)="showEditTeamDialog()" *ngIf="isOwner() === true">Edit Team</button>
      <button class="button-default mg-left-10" (click)="showAddMemberDialog()" *ngIf="isOwner() === true">Add Member</button>
    </div>
  </div>
  <div *ngIf="deviceType === 1; else activeOnMobile">
    <table *ngIf="team.users && team.users.length > 0; else noTeams">
      <tr class="table-header">
        <th>Email</th>
        <th>Name</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      <tr class="table-data" *ngFor="let member of team.users; let i = index">
        <td>{{member.email}}</td>
        <td>{{member.full_name}}</td>
        <td>{{member.confirmed ? "confirmed" : "pending"}}</td>
        <td class="actions-column">
          <div class="actions-container" *ngIf="isOwner() === true && member.role !== 'owner'">
            <div class="resend-invite" *ngIf="!member.confirmed" (click)="resendInvitation(member.email)" title="Resend invitation">
              <img src="/assets/Assets%20Website/Btn/refresh.svg"/>
            </div>
            <div class="delete" (click)="deleteMember(i)" title="Delete member">
              <img src="/assets/Assets%20Website/Btn/close.svg"/>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <ng-template #activeOnMobile>
    <div class="shares-grid">
      <div *ngFor="let member of team.users; let i = index" class="share-card">
        <p class="share-title">
          <span class="title-text">{{member.email}}</span>
          <span class="resend-invite mg-right-10" *ngIf="!member.confirmed">
              <img (click)="resendInvitation(member.email)" src="/assets/Assets%20Website/Btn/refresh.svg"/>
          </span>
          <span class="delete" title="Delete member" *ngIf="isOwner() === true && member.role !== 'owner'">
              <img (click)="deleteMember(i)" src="/assets/Assets%20Website/Btn/close.svg"/>
          </span>
        </p>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #noTeams>
  <p
    class="no-shares">{{noSearchResults ? 'There are no results matching your search criteria' : 'There are currently no members' }}</p>
</ng-template>
