import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {StrengthData} from 'src/app/models/password-strength-data.model';
import {AuthService} from 'src/app/services/auth.service';
import {InputValidators} from '../../models/input-validators';
import {PasswordMeterService} from '../../services/password-meter.service';
import {QueryParamsService} from '../../services/query-params.service';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {CaptchaService} from '../../services/captcha.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  inputValidators = InputValidators;
  registerForm: FormGroup;

  agree: boolean = false;
  didRegister: boolean = false;

  strengthData: StrengthData;

  constructor(
    public authService: AuthService,
    public passwordMeter: PasswordMeterService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationsService: ToastrService,
    private queryParamsService: QueryParamsService,
    private gaService: GoogleAnalyticsService,
    private captchaService: CaptchaService
  ) {

    const selectedPlan = this.route.snapshot.queryParamMap.get('selected_plan');
    if (selectedPlan) {
      this.authService.isUserAuthenticated(false).then(isLoggedIn => {
        if (isLoggedIn) {
          this.queryParamsService.calculateTabs(selectedPlan);
          this.router.navigate(['/user-account'], {
            queryParams: {
              tab1: 1,
              tab2: this.queryParamsService.tab2,
              selected_plan: selectedPlan
            }
          });
        } else {
          sessionStorage.setItem('selected_plan', selectedPlan);
        }
      });
    }

    this.registerForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      company: new FormControl('', null),
      recaptchaReactive: new FormControl(null, Validators.required),
    });
  }

  checkPassword(event) {
    this.passwordMeter.checkSecurity(event.target.value).then((res: StrengthData) => {
      if (res) {
        this.strengthData = res;
      }
    });
  }

  register(captchaRes: string | null): void {
    this.didRegister = false;

    if (this.registerForm.valid) {
      if (this.registerForm.value.password === this.registerForm.value.confirmPassword) {
        if (this.agree) {
          if (captchaRes) {
            this.captchaService.verifyCaptcha(captchaRes).subscribe(res => {
              if (res) {
                this.authService.register(this.registerForm.value).then(res => {
                  if (res.success) {
                    this.didRegister = true;
                    this.gaService.registerEventEmitter();
                  } else if (res && res.error) {
                    this.notificationsService.error(res.error.message);
                  }
                });
              } else {
                this.notificationsService.error('Captcha resolve failed. Please refresh the page');
              }
            }, err => {
              if (err) {
                this.notificationsService.error(err.error.message);
              }
            });
          } else {
            this.notificationsService.error('Captcha resolve failed. Please refresh the page');
          }
        } else {
          this.notificationsService.error('You must agree to the Terms And Privacy Policy in order to register an account on CredenShare');
        }
      } else {
        this.notificationsService.error('The passwords don\'t match');
      }
    } else {
      this.notificationsService.error('Please provide valid data in order to register');
    }
  }
}
