import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expirationDateText'
})
export class ExpirationDateTextPipe implements PipeTransform {

  transform(type: number, value: number): string {
    /* 
      This pipe is used to handle UI text for the expiration value in a cleaner way than with terniary operators in the html
      the returned value should be with a leading space so the html text can properly be formatted
    */
      if (type === 0 && value === 1) {
        return ' hour';
      } else if (type === 0 && value > 1) {
        return ' hours';
      } else if (type === 1 && value === 1) {
        return ' day';
      } else if (type === 1 && value > 1) {
        return ' days';
      } else if (type === 2 && value === 1) {
        return ' month';
      } else if (type === 2 && value > 1) {
        return ' months';
      }
  }

}
