import {Injectable} from '@angular/core';
import {GaEvent} from '../models/ga-event.model';
import {environment} from '../../environments/environment';

declare const gtag: (...args: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public subPurchaseEventEmitter(planCode: string, total: number): void {
    gtag('event', GaEvent.Purchase, {
      plan_code: planCode,
      paid_amount: total,
    });
  }

  public addonPurchaseEventEmitter(addonCode: string, total: number): void {
    gtag('event', GaEvent.Purchase, {
      addon_code: addonCode,
      paid_amount: total,
    });
  }

  public registerEventEmitter(): void {
    gtag('event', GaEvent.Registration);
  }

  getClientId(): Promise<string> {
    return new Promise(resolve => {
      this.tryLoadGa().catch(e => {
        resolve('');
      }).then(() => {
        gtag('get', environment.gaMeasurementId, 'client_id', resolve);
      });
    });
  }

  tryLoadGa(): Promise<Response> {
    return fetch('https://www.google-analytics.com/analytics.js', {method: 'HEAD', mode: 'no-cors'});
  }
}
