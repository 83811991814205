import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {Plan} from '../models/plan.model';
import {HttpCaller} from './http-caller.service';
import {GoogleAnalyticsService} from './google-analytics.service';
import {ChargeSubscriptionResponse} from '../models/subscription.model';

@Injectable({
  providedIn: 'any'
})
export class SubscriptionPlansService {
  freePlanCode = 'free';

  constructor(private http: HttpCaller,
              private gaService: GoogleAnalyticsService,
  ) {
  }

  getAllPlans(): Observable<Plan[]> {
    return this.http.get('plans/all').pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getPlanByCode(planCode: string): Observable<Plan> {
    return this.http.get(`plans/${planCode}`).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getSubscriptionByUser(id: string, showLoading: boolean = true): Observable<any> {
    return this.http.get(`users/${id}/subscriptions`, showLoading).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getSubscriptionByTeamId(id: string, showLoading: boolean = true): Observable<any> {
    return this.http.get(`organizations/${id}/subscription`, showLoading).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getInvoicesByUserId(id: string): Observable<any> {
    return this.http.get(`users/${id}/invoices`).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getInvoiceById(id: string): Observable<any> {
    return this.http.get(`invoices/${id}/download`).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  subscribeToPlan(id: string, body: any): Observable<any> {
    return this.http.post(`users/${id}/subscriptions`, 'Successfully subscribed to a plan', body)
      .pipe(map((response: ChargeSubscriptionResponse) => {
        if (response) {
          this.gaService.subPurchaseEventEmitter(response.plan_code, response.total);
        }
        return response as any;
      }));
  }

  changeSubscription(id: string, body): Observable<any> {
    return this.http.post(`subscriptions/${id}`, 'Subscription successfully changed', body)
      .pipe(map((response: ChargeSubscriptionResponse) => {
        if (response) {
          this.gaService.subPurchaseEventEmitter(response.plan_code, response.total);
        }
        return response as any;
      }));
  }

  updateSubscriptionSettings(subscriptionId: string, body): Observable<any> {
    return this.http.put(`subscriptions/${subscriptionId}`, 'Subscription successfully updated', body).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  cancelSubscription(id: string, body): Observable<any> {
    return this.http.put(`subscriptions/${id}/cancel`, 'Subscription cancelled', body).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  async hasAccessTo(userId: string, feature: string): Promise<boolean> {
    const user = await this.getSubscriptionByUser(userId).toPromise();
    if (user && user.plan && user.plan.features) {
      for (const f of user.plan.features) {
        if (f.feature_code === feature && f.feature_value === 'true') {
          return true;
        }
      }
    }
    return false;
  }
}
