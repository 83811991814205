import { Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  padlockClass: string = 'lock';

  constructor( ) { 
    setInterval(() => {
      this.padlockClass = this.padlockClass === 'lock' ? 'lock unlocked' : 'lock';
    }, 1500);
  }
}
