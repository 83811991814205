<div class="page-container">
    <div class="purple-header">
        <div class="image-container">
            <img src="/assets/Assets Website/Logo/Credenshare-white.svg" />
        </div>
        <div class="login-form" *ngIf="!success; else successful">
            <p class="form-title">Update Password</p>
            <form [formGroup]="confirmPasswordForm" (ngSubmit)="checkCode()" class="inputs-container">
                <input type="password" formControlName="password" placeholder="New password" required/>
                <input type="password" formControlName="confirmPassword" placeholder="Confirm new password" required/>
                <button class="signin-button" type="submit">Confirm</button>
                <span class="error" *ngIf="errors">{{errors}}</span>
            </form>
        </div>
        <p class="login-copyright">&copy; {{authService.currentYear}} CredenShare. All rights reserved.</p>
    </div>
</div>

<ng-template #successful>
    <app-success title="Password reset" message="You will now be redirected to the login page" imageUrl="/assets/Assets Website/Icn/share created.svg"></app-success>
</ng-template>
