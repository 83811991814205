import {Injectable} from '@angular/core';
import {HttpCaller} from './http-caller.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {HttpParams} from '@angular/common/http';
import {Notification, Notifications} from '../models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private httpCaller: HttpCaller) {

  }

  markNotificationAsRead(n: Notification): Observable<any> {
    n.is_read = true;
    return this.httpCaller.put(`notifications/${n.ID}`, null, n).pipe(map((response: Response) => {
      if (response) {
        return {success: true, data: response};
      } else {
        return {success: false};
      }
    }));
  }

  getByUserId(userId: string, params?: HttpParams, hasLoading = false): Observable<Notifications> {
    return this.httpCaller.get(`users/${userId}/notifications`, hasLoading, params).pipe(map((response: Notifications) => {
      return response;
    }));
  }
}
