import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Auth} from 'aws-amplify';
import {Attributes, LoginUser, RegisterUser, UserDetails} from '../models/user.model';
import {MatDialog} from '@angular/material/dialog';
import {UserSubscription} from '../models/plan.model';
import {SubscriptionPlansService} from './subscription-plans.service';
import {Router} from '@angular/router';

import * as moment from 'moment';
import {UserService} from './user.service';
import {CURRENT_SELECTED_TEAM} from '../models/team.model';
import {ConfirmPopupComponent} from '../shared/confirm-popup/confirm-popup.component';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: UserDetails;
  userPlan: UserSubscription;
  currentYear: string;

  constructor(
    private notificationsService: ToastrService,
    private userService: UserService,
    private dialog: MatDialog,
    private subPlansService: SubscriptionPlansService,
    private router: Router,
    private location: Location,
  ) {
    this.currentYear = moment().format('YYYY');
  }

  isUserAuthenticated(showLoginPage: boolean, showLoading: boolean = true): Promise<boolean> {
    return new Promise(resolve => {
      Auth.currentSession().then((session: any) => {
        const {idToken, refreshToken, accessToken} = session;
        this.currentUser = new UserDetails(accessToken.payload.username, idToken.payload, accessToken.jwtToken);
        this.getSubscription(showLoading);
        resolve(true);
      }).catch(err => {
        if (showLoginPage) {
          this.router.navigate([`/login`], {
            queryParams: {
              redirect_url: window.location.href,
            }
          });
        }
        resolve(false);
      });
    });
  }

  getSubscription(showLoading: boolean = true): void {
    const selectedTeamId = this.getCurrentTeamId();
    if (selectedTeamId) {
      this.subPlansService.getSubscriptionByTeamId(selectedTeamId, showLoading).subscribe(planData => {
        this.handleSubscriptionData(planData);
      }, err => {
        console.error('plan call failed', err);
        this.switchTeam(null, true);
      });
    } else {
      this.subPlansService.getSubscriptionByUser(this.currentUser.username, showLoading).subscribe(planData => {
        this.handleSubscriptionData(planData);
      }, err => {
        console.error('plan call failed', err);
      });
    }
  }

  login(loginObject: LoginUser): Promise<any> {
    return new Promise(resolve => {
      this.cognitoLogin(loginObject).then(cognitoResult => {
        if (cognitoResult.success) {
          const user = cognitoResult.data;
          this.currentUser = new UserDetails(user.username, user.attributes, user.signInUserSession.accessToken.jwtToken);
          this.userService.getUserById(user.username).subscribe(data => {
            if (data && data.tos_agreed) {
              this.currentUser.details.tos_agreed = true;
            } else {
              this.currentUser.details.tos_agreed = false;
            }
            if (!this.currentUser.details.tos_agreed) {
              if (loginObject.agree) {
                const body = {
                  tos_agreed: true,
                  tos_agreement_date: moment().toISOString(),
                  email: this.currentUser.details.email
                };
                this.userService.updateUserById(this.currentUser.username, body).subscribe(res => {
                  resolve({success: true});
                });
              } else {
                resolve({
                  success: false,
                  error: {message: 'You must agree to the terms of service before using the application'},
                  showTosPopup: true,
                });
              }
            } else {
              resolve({success: true});
            }
          });
        } else {
          resolve({success: false, error: cognitoResult.error});
        }
      });
    });
  }

  private async cognitoLogin(loginObject: LoginUser): Promise<any> {
    try {
      const user = await Auth.signIn(loginObject.email, loginObject.password);
      return {success: true, data: user};
    } catch (error) {
      return {success: false, error: error};
    }
  }

  async logout(globalSignOut: boolean = false): Promise<any> {
    try {
      await Auth.signOut({
        global: globalSignOut
      });
      this.currentUser = null;
      this.switchTeam(null, false);
      return {success: true};
    } catch (error) {
      console.log('error signing out: ', error);
      this.notificationsService.error('We expirienced an error when trying to log you out. Please try again', 'Error');
      return {success: false, error};
    }
  }

  async register(registerObject: RegisterUser): Promise<any> {
    try {
      await Auth.signUp({
        username: registerObject.email,
        password: registerObject.password,
        attributes: {
          given_name: registerObject.firstName,
          family_name: registerObject.lastName,
          address: '',
          email: registerObject.email,
          'custom:company': registerObject.company
        }
      });
      return {success: true};
    } catch (error) {
      console.log('error signing up:', error);
      return {success: false, error};
    }
  }

  async confirmUserAccount(email: string, code: string, type: string): Promise<any> {
    try {
      if (type === 'register') {
        await Auth.confirmSignUp(
          email,
          code,
          {
            clientMetadata: {
              tos_agreement_date: moment().toISOString(),
              tos_agreed: 'true'
            }
          });
        this.notificationsService.success('You have successfully confirmed your email', 'Success');
        return {success: true};
      } else if (type === 'email_updated') {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttributeSubmit(user, 'email', code);
        await this.logout(true);
        this.notificationsService.success('You have successfully confirmed your email', 'Success');
        return {success: true};
      }
    } catch (error) {
      if (error === 'The user is not authenticated') {
        this.notificationsService.error('Please login first before verifying your email', 'Error');
        this.router.navigate([`/login`], {
          queryParams: {
            redirect_url: this.location.path(),
          }
        });
      }
      return {success: false, error};
    }
  }

  async resendConfirmationLink(email: string, type: string): Promise<any> {
    try {
      if (type === 'register') {
        await Auth.resendSignUp(email);
      } else {
        await Auth.verifyCurrentUserAttribute('email');
      }
      this.notificationsService.success('We have resent a confirmation link to your email. Please check', 'Success');
      console.log('code resent successfully');
      return {success: true};
    } catch (err) {
      console.log('error resending code: ', err);
      return {success: false, err};
    }
  }

  async resendUpdatedEmailVerification(): Promise<void> {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      try {
        const res = await this.resendConfirmationLink(user.attributes.email, 'email_updated');
        if (res && res.success) {
          this.dialog.open(ConfirmPopupComponent, {
            width: '50%',
            height: 'fit-content',
            panelClass: 'popup-container',
            data: {
              header: 'Resend verification successful',
              message: 'A verification email has been sent to your new email address. Please check your inbox and click the link provided to activate your new email.',
              noCancelButton: true,
              buttonMessage: 'OK'
            }
          });
        } else {
          this.notificationsService.error('Unable to resend verification email. Please try again later. Error: ' + res.err.message);
        }
      } catch (e) {
        this.notificationsService.error('Unable to resend verification email. Please try again later.');
        console.error(e);
      }
    } else {
      this.notificationsService.error('Please login first.');
    }
  }

  changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return new Promise(resolve => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldPassword, newPassword);
        }).then(res => {
        if (res === 'SUCCESS') {
          this.notificationsService.success('Password updated successfully', 'Success');
          return resolve({success: true});
        } else {
          this.notificationsService.error('There was an error when trying to update your password. Please try again later', 'Error');
        }
      }).catch(err => {
        if (err.code === 'NotAuthorizedException') {
          this.notificationsService.error('Wrong current password', 'Error');
        } else {
          this.notificationsService.error(err.message, 'Error');
        }
        return resolve({success: false, error: err});
      });
    });
  }

  forgotPasswordEmail(email: string): Promise<any> {
    return new Promise(resolve => {
      Auth.forgotPassword(email)
        .then(data => {
          console.log(data);
          return resolve({success: true});
        }).catch(err => {
        this.notificationsService.error('There was an error. Please try again later', 'Error');
        console.log(err);
        return resolve({success: false, error: err});
      });
    });
  }

  forgotPasswordConfirm(email: string, code: string, password: string): Promise<any> {
    return new Promise(resolve => {
      Auth.forgotPasswordSubmit(email, code, password)
        .then(data => {
          console.log(data);
          this.notificationsService.success('You have successfully changed your password', 'Success');
          return resolve({success: true});
        })
        .catch(err => {
          console.log(err);
          return resolve({success: false, error: err});
        });
    });
  }

  public switchTeam(teamId: string, reload = true): void {
    if (teamId) {
      localStorage.setItem(CURRENT_SELECTED_TEAM, teamId);
    } else {
      localStorage.removeItem(CURRENT_SELECTED_TEAM);
    }

    if (reload) {
      window.location.reload();
    }
  }

  public getCurrentTeamId(): string {
    return localStorage.getItem(CURRENT_SELECTED_TEAM);
  }

  async updateUserData(firstName: string, lastName: string, address: string, company: string): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    const data = {
      given_name: firstName,
      family_name: lastName,
      address: address,
      'custom:company': company,
    };
    const result = await Auth.updateUserAttributes(user, data);

    if (result === 'SUCCESS') {
      this.updateSavedUser(data);
      this.notificationsService.success('You have successfully updated your user data', 'Success');
      return {success: true};
    } else {
      this.notificationsService.error('The update of user data was not successful, please try again!', 'Error');
      return {success: false};
    }
  }

  async updateUserEmail(email: any): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {email: email});

    if (result && result === 'SUCCESS') {
      try {
        this.updateSavedUser({email: email, emailVerified: false});
        await this.userService.updateUserById(this.currentUser.username, {email: email}).toPromise();
        this.dialog.open(ConfirmPopupComponent, {
          width: '50%',
          height: 'fit-content',
          panelClass: 'popup-container',
          data: {
            header: 'Email updated successful',
            message: 'A verification email has been sent to your new email address. Please check your inbox and click the link provided to activate your new email.',
            noCancelButton: true,
            buttonMessage: 'OK'
          }
        });
      } catch (e) {
        this.notificationsService.error('The update of user email was not successful, please try again!', 'Error');
        return {success: false};
      }
    } else {
      this.notificationsService.error('The update of user data was not successful, please try again!', 'Error');
      return {success: false};
    }
  }

  private updateSavedUser(data: any): void {
    this.currentUser.details = new Attributes({...this.currentUser.details, ...data});
  }

  private handleSubscriptionData(planData: any): void {
    this.userPlan = planData;
    const arrayStrings = new Array<string>();
    for (const f of planData.plan.features) {
      if (f.feature_value === 'true') {
        arrayStrings.push(f.feature_name);
      }
    }
    this.userPlan.descriptiveFeatures = arrayStrings;
  }
}
