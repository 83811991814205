import {Injectable} from '@angular/core';
import {ShareContent, ShareContentType} from '../models/share.model';
import {StrengthData} from 'src/app/models/password-strength-data.model';
import {AuthService} from './auth.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataInputTypeService {
  //** This service is used to set, check and change type meta tag of input component */
  shareDataInputTypes = new Array<ShareContentType>(
    {value: 'text', selected: false, icon: 'text_fields'},
    {value: 'date', selected: false, icon: 'calendar_month'},
    {value: 'password', selected: false, icon: 'password'},
    {value: 'multiline', selected: false, icon: 'density_small'},
  );

  shareData = new BehaviorSubject<ShareContent[]>(
    [
      {
        item: 'Email',
        value: '',
        readonly: false,
        canDelete: false,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: 'text',
        order: 0
      },
      {
        item: 'Password',
        value: '',
        readonly: false,
        canDelete: true,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: 'password',
        order: 1
      }
    ]
  );

  constructor(private authService: AuthService) {
  }

  getInputType(index: number, inPopup?: boolean): string {
    let elem;
    if (inPopup) {
      elem = document.getElementById(`user-input-popup-${index}`);
    } else {
      elem = document.getElementById(`user-input-${index}`);
    }
    return elem.classList.contains('password-text') ? 'password' : 'text';
  }

  getInputTypeIcon(inputType: string): string {
    for (const i of this.shareDataInputTypes) {
      if (i.value === inputType) {
        return i.icon;
      }
    }

    return null;
  }

  resetInputType(index: number, inPopup?: boolean) {
    const input = inPopup ? document.getElementById(`user-input-popup-${index}`) : document.getElementById(`user-input-${index}`);
    if (this.getInputType(index, inPopup) === 'text') {
      input.classList.add('password-text');
      input.classList.remove('regular-text');
    } else if (this.getInputType(index, inPopup) === 'password') {
      input.classList.remove('password-text');
      input.classList.add('regular-text');
    }
  }

  reset() {
    this.shareData.next([
      {
        item: 'Email',
        value: '',
        readonly: false,
        canDelete: false,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: 'text',
        order: 0
      },
      {
        item: 'Password',
        value: '',
        readonly: false,
        canDelete: true,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: 'password',
        order: 1
      }
    ]);
  }
}
