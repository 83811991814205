import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-number-of-view',
  templateUrl: './number-of-view.component.html',
  styleUrls: ['./number-of-view.component.scss']
})
export class NumberOfViewComponent implements OnInit {

  unlimitedViewTime: boolean = true;
  viewingTimes: boolean = false;
  viewingTimesValue: number = 2;

  constructor() { }

  ngOnInit(): void {
  }

  reset(): void {
    this.unlimitedViewTime = true;
    this.viewingTimes = false;
    this.viewingTimesValue = 2;
  }

}
