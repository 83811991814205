import {Injectable} from '@angular/core';
import {HttpCaller} from './http-caller.service';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {FeatureTypes} from '../models/feature.model';
import {SubscriptionPlansService} from './subscription-plans.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecureRequestsService {
  constructor(
    private httpCaller: HttpCaller,
    private subscriptionPlanService: SubscriptionPlansService
  ) {
  }

  createSecureRequest(body: any): Observable<any> {
    return this.httpCaller.post('secure_requests', 'Secure Request successfully created', body).pipe(map((response: Response) => {
      if (response) {
        return {success: true, data: response};
      } else {
        return {success: false};
      }
    }));
  }

  editSecureRequest(shortCode: string, body: any): Observable<any> {
    return this.httpCaller.put(`secure_requests/${shortCode}`, 'Secure Request successfully updated', body).pipe(map((response: Response) => {
      if (response) {
        return {success: true, data: response};
      } else {
        return {success: false};
      }
    }));
  }

  getSecureRequests(userId: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`users/${userId}/secure_requests`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getExpiredSecureRequests(userId: string, params: HttpParams): Observable<any> {
    return this.httpCaller.get(`users/${userId}/expired_secure_requests`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getTeamSecureRequests(orgId: string, userId?: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`organizations/${orgId}/secure_requests`, true, this.appendUserIdParam(userId, params)).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getTeamExpiredSecureRequests(orgId: string, userId?: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`organizations/${orgId}/expired_secure_requests`, true, this.appendUserIdParam(userId, params)).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  deleteSecureRequest(shortCode: string): Observable<any> {
    return this.httpCaller.delete(`secure_requests/${shortCode}`, 'Secure request successfully deleted').pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getSecureRequest(shortCode: string, body: any, httpHeaders?: any): Observable<any> {
    return this.httpCaller.post(`secure_requests/${shortCode}`, null, body, null, httpHeaders).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getSecureRequestInfo(shortCode: string): Observable<any> {
    return this.httpCaller.get(`secure_requests/${shortCode}/info`, false, null, false).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  async hasAccessToSecureRequestFeature(userId: string): Promise<boolean> {
    let hasAccess;

    if (!environment.production) {
      return true;
    }

    hasAccess = await this.subscriptionPlanService
      .hasAccessTo(userId, FeatureTypes.SecureRequests);

    return hasAccess;
  }

  private appendUserIdParam(userId: string, params: HttpParams): HttpParams {
    if (userId) {
      if (!params) {
        params = new HttpParams();
      }
      return params.append('userId', userId);
    }

    return params;
  }
}
