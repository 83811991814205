import { Component, OnInit } from '@angular/core';
import { SlackModel } from '../../models/slack.model';
import { SlackService } from '../../services/slack.service';
import { LoaderService } from '../../services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-slack-auth',
  templateUrl: './slack-auth.component.html',
  styleUrls: ['./slack-auth.component.scss']
})
export class SlackAuthComponent {
  /** 0 for not set. 1 for success. -1 for error */
  slackOAuthVerifySuccess: 0 | 1 | -1 = 0;
  slackParams: SlackModel;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private slackService: SlackService,
    private loaderService: LoaderService,
  ) {
    this.loaderService.showLoading();
    this.route.queryParams.subscribe(params => {
      this.slackParams = params as SlackModel;
      this.confirmSlackOAuthCode();
      this.loaderService.hideLoading();
    });
  }

  confirmSlackOAuthCode(): void {
    this.slackService.confirmSlackOAuthCode(this.slackParams.code).subscribe(res => {
      this.slackOAuthVerifySuccess = 1;
      console.log(res);
    }, err => {
      this.slackOAuthVerifySuccess = -1;
      console.log(err.error);
    });
  }
}
