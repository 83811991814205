import { Injectable } from '@angular/core';

import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core'
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators';

import { StrengthData } from '../models/password-strength-data.model';

@Injectable({
  providedIn: 'root'
})
export class PasswordMeterService {

  public passwordValue = new Subject<string>();

  constructor() { 
    const options = {
      translations: zxcvbnEnPackage.translations,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
    }

    ZxcvbnOptions.setOptions(options);
   }

   checkSyncSecurity(value: string) : StrengthData {
     //** This function is used to check password strength for static data */
     let data = new StrengthData();
     data.passwordStrength = zxcvbn(value);
     data.passwordScore = data.passwordStrength.score;
     return data;
   }

   checkSecurity(inputValue: string) {
     //** This function is used to check password strength for dynamically typed data */
    return new Promise(resolve => {
     this.passwordValue.next(inputValue);
      let data = new StrengthData();
      this.passwordValue.pipe(debounceTime(250)).subscribe(passValue => {
        if (passValue && passValue != '') {
          data.passwordStrength = zxcvbn(passValue);
          data.passwordScore = data.passwordStrength.score;
          resolve(data);
        } else {
          data.passwordScore = 0;
          data.passwordStrength = null;
          resolve(data);
        }
      });
    });
   }
}

