<div class="filters-container">
    <select [(ngModel)]="sort" (change)="fetchSorted()">
        <option value="expired_at">Sort by Expiration Date</option>
        <option value="created_at">Sort by Creation Date</option>
        <option value="title">Sort by Name</option>
    </select>
    <div class="search-container">
        <span class="material-icons">
            search
        </span>
        <input (keyup)="filtering($event)" type="text" placeholder="Search..." />
    </div>
</div>
<mat-tab-group (selectedTabChange)="tabSwitched($event)">
    <mat-tab label="Active">
        <div *ngIf="deviceType === 1; else activeOnMobile">
            <app-pagination (numberClicked)="pageChanged($event, 0)" *ngIf="pagination && activeShares && pagination && pagination.totalPage > 1" [pageData]="pagination"></app-pagination>
            <table *ngIf="activeShares && activeShares.length > 0; else noActiveShares">
                <tr class="table-header">
                    <th>Name</th>
                    <th>Shared by</th>
                    <th>Shared on</th>
                    <th>Expires on</th>
                    <th>Protection</th>
                    <th>Actions</th>
                </tr>
                <tr class="table-data" *ngFor="let share of activeShares; let i = index">
                    <td>{{share.title}}</td>
                    <td>{{share.email}}</td>
                    <td>{{share.created_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</td>
                    <td *ngIf="share.expired_at">{{share.expired_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</td>
                    <td *ngIf="!share.expired_at">Date will be displayed after the secure share is viewed</td>
                    <td class="share-protection-column">{{share.protectionCounter}}</td>
                    <td class="actions-column">
                        <div class="actions-container">
                            <div class="delete" (click)="deleteShare(share.short_code, i)" title="Delete share">
                                <img src="/assets/Assets Website/Btn/trash.svg" />
                            </div>
                            <div class="copy" title="Copy to clipboard">
                                <img (click)="copy(share.short_code)" src="/assets/Assets Website/Btn/bx-copy.svg" />
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <ng-template #activeOnMobile>
            <div class="shares-grid">
                <div *ngFor="let share of activeShares; let i = index" class="share-card">
                    <p class="share-title">
                        <span class="title-text">{{share.title}}</span>
                        <span class="copy" title="Copy to clipboard">
                            <img (click)="copy(share.short_code)" src="/assets/Assets Website/Btn/bx-copy.svg" />
                        </span>
                        <span class="delete" title="Delete share">
                            <img (click)="deleteShare(share.short_code, i)" src="/assets/Assets Website/Btn/trash.svg" />
                        </span>
                    </p>
                    <hr />
                    <div class="share-data">
                      <div class="share-date">
                        <span class="date-title">Shared By</span>
                        <span class="share-creation">{{share.email}}</span>
                      </div>
                        <div class="share-date">
                            <span class="date-title">Shared On</span>
                            <span class="share-creation">{{share.created_at | date: 'MMM dd, yyyy'}}</span>
                        </div>
                        <div class="expiration-date">
                            <ng-container>
                                <span class="date-title">Expires on</span>
                                <span *ngIf="share.expired_at" class="share-expiration">{{share.expired_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</span>
                                <span *ngIf="!share.expired_at" class="share-expiration">Date will be displayed after the secure share is viewed</span>
                            </ng-container>
                        </div>
                        <div class="protections">
                            <span class="date-title">Protections</span>
                            <span class="share-protection">{{share.protectionCounter}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-tab>
    <mat-tab label="Expired">
        <div *ngIf="deviceType === 1; else expiredOnMobile">
            <app-pagination (numberClicked)="pageChanged($event, 1)" *ngIf="expiredPagination && expiredShares && expiredPagination && expiredPagination.totalPage > 1" [pageData]="expiredPagination"></app-pagination>
            <table *ngIf="expiredShares && expiredShares.length > 0; else noExpiredShares">
                <tr class="table-header">
                    <th>Name</th>
                    <th>Shared by</th>
                    <th>Shared on</th>
                    <th>Expires on</th>
                    <th>Protection</th>
<!--                    <th>Actions</th>-->
                </tr>
                <tr class="table-data" *ngFor="let exShare of expiredShares; let i = index">
                    <td>{{exShare.title}}</td>
                    <td>{{exShare.email}}</td>
                    <td>{{exShare.created_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</td>
                    <td *ngIf="exShare.expired_at">{{exShare.expired_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</td>
                    <td *ngIf="!exShare.expired_at">Date will be displayed after the secure share is viewed</td>
                    <td class="share-protection-column">{{exShare.protectionCounter}}</td>
<!--                    <td class="actions-column">-->
<!--                        <div class="actions-container">-->
<!--                            <div class="delete" (click)="deleteExpired(exShare.id, i)" title="Delete share">-->
<!--                                <img src="/assets/Assets Website/Btn/trash.svg" />-->
<!--                            </div>-->
<!--                            &lt;!&ndash; <div class="copy" title="Restore share">-->
<!--                                <img src="/assets/Assets Website/Btn/refresh.svg" />-->
<!--                            </div> &ndash;&gt;-->
<!--                        </div>-->
<!--                    </td>-->
                </tr>
            </table>
        </div>
        <ng-template #expiredOnMobile>
            <div class="shares-grid">
                <div *ngFor="let share of expiredShares; let i = index" class="share-card">
                    <p class="share-title">
                        <span class="title-text">{{share.title}}</span>
                        <span class="copy" title="Copy to clipboard">
                            <img (click)="copy(share.short_code)" src="/assets/Assets Website/Btn/bx-copy.svg" />
                        </span>
                        <span class="delete" title="Delete share">
                            <img (click)="deleteShare(share.short_code, i)" src="/assets/Assets Website/Btn/trash.svg" />
                        </span>
                    </p>
                    <hr />
                    <div class="share-data">
                      <div class="share-date">
                        <span class="date-title">Shared By</span>
                        <span class="share-creation">{{share.email}}</span>
                      </div>
                        <div class="share-date">
                            <span class="date-title">Shared On</span>
                            <span class="share-creation">{{share.created_at | date: 'MMM dd, yyyy'}}</span>
                        </div>
                        <div class="expiration-date">
                            <ng-container>
                                <span class="date-title">Expires on</span>
                                <span *ngIf="share.expired_at" class="share-expiration">{{share.expired_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</span>
                                <span *ngIf="!share.expired_at" class="share-expiration">Date will be displayed after the secure share is viewed</span>
                            </ng-container>
                        </div>
                        <div class="protections">
                            <span class="date-title">Protections</span>
                            <span class="share-protection">{{share.protectionCounter}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<ng-template #noExpiredShares>
    <p class="no-shares">{{noSearchResults ? 'There are no results matching your search criteria' : 'There are currently no expired shares'}}</p>
</ng-template>
<ng-template #noActiveShares>
    <p class="no-shares">{{noSearchResults ? 'There are no results matching your search criteria' : 'There are currently no active shares' }}</p>
</ng-template>
