import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from 'src/app/services/data.service';
import {SharesService} from 'src/app/services/shares.service';

import {MatDialog} from '@angular/material/dialog';
import {ShareFormErrors} from 'src/app/models/share.model';

import Chart from 'chart.js';
import {CreateSharePopupComponent} from 'src/app/shared/create-share-popup/create-share-popup.component';
import {DataInputTypeService} from 'src/app/services/data-input-type.service';
import {StrengthData} from 'src/app/models/password-strength-data.model';
import {ToastrService} from 'ngx-toastr';
import {ClipboardService} from 'ngx-clipboard';
import {UserDeviceService} from 'src/app/services/user-device.service';
import {QueryParamsService} from '../../services/query-params.service';
import {HttpParams} from '@angular/common/http';
import {TeamsService} from '../../services/teams.service';
import {UserService} from '../../services/user.service';
import {getShareUrl} from '../../shared/shares-utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  formErrors: ShareFormErrors = new ShareFormErrors();
  isFinished: boolean;
  recentShares: any[] = new Array<any>();
  stats: any;
  teamStats: any = null;
  noData = false;

  passStrengthData: StrengthData = new StrengthData();
  deviceType: number;

  constructor(
    public authService: AuthService,
    private dataService: DataService,
    private shareService: SharesService,
    private dialog: MatDialog,
    public dataInputTypeService: DataInputTypeService,
    private clipboard: ClipboardService,
    private toastrService: ToastrService,
    private device: UserDeviceService,
    private queryParamsService: QueryParamsService,
    private router: Router,
    private teamService: TeamsService,
    private userService: UserService,
  ) {

    const currentTeam = this.authService.getCurrentTeamId();
    const handleResp = (data) => {
      if (data && data.shares) {
        this.recentShares = data.shares;
      }
    };

    if (currentTeam) {
      const isOwner = this.teamService.isOwner(currentTeam, this.authService.currentUser.username);
      const userId = isOwner ? null : this.authService.currentUser.username;
      this.shareService.getTeamShares(currentTeam, userId, new HttpParams()).subscribe(data => handleResp(data));
    } else {
      this.shareService.getShares(this.authService.currentUser.username, new HttpParams()).subscribe(data => handleResp(data));
    }

    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });
  }

  ngOnInit(): void {
    const planFromStorage = sessionStorage.getItem('selected_plan');

    if (planFromStorage) {
      this.queryParamsService.calculateTabs(planFromStorage);
      this.router.navigate(['/user-account'], {queryParams: {tab1: 1, tab2: this.queryParamsService.tab2, selected_plan: planFromStorage}});
    }
  }

  ngAfterViewInit() {
    this.authService.getSubscription();
    this.setUpStatistics();
  }

  updateShares(share: any) {
    this.recentShares.unshift(share);
    this.authService.getSubscription();
    this.setUpStatistics();
  }

  createShare() {
    const popup = this.dialog.open(CreateSharePopupComponent, {
      width: '95%',
      height: 'fit-content',
      maxWidth: '1100px',
      panelClass: 'popup-container',
    });
    popup.afterClosed().subscribe((created: any[]) => {
      if (created) {
        if (created.length > 1) {
          this.recentShares.unshift(created);
        } else if (created.length === 1) {
          this.recentShares.unshift(created[0]);
        }
        this.setUpStatistics();
      }
    });
  }

  copy(shortCode: string) {
    this.clipboard.copy(getShareUrl() + shortCode);
    this.toastrService.success('Link copied to clipboard!');
  }

  async setUpStatistics(): Promise<void> {
    // Setup chart only on desktop view
    const chartCanvas = document.getElementById('statsChart');

    let params = new HttpParams();
    const currentTeam = this.authService.getCurrentTeamId();
    if (currentTeam) {
      params = params.append('orgId', currentTeam);
      const isTeamOwner = await this.teamService.isOwner(currentTeam, this.authService.currentUser.username);
      if (isTeamOwner) {
        this.teamService.getTeamStatisticsById(currentTeam).subscribe(res => {
          if (res) {
            this.teamStats = res;
          }
        });
      }
    }

    this.userService.getUserStatistics(this.authService.currentUser.username, params).subscribe(res => {
      if (res) {
        this.stats = res;
        let chartData = {};
        let tooltips = {};

        if (this.stats.number_of_shares === 0 && this.stats.expired_shares === 0 && this.stats.total_viewed_shares === 0) {
          chartData = {
            labels: [],
            datasets: [{
              backgroundColor: ['rgb(218,211,236)'],
              data: [1],
              borderWidth: 5,
              borderColor: 'rgb(243,248,251)',
              hoverBorderColor: 'rgb(243,248,251)'
            }]
          };
          tooltips = {enabled: false};
        } else {
          chartData = {
            labels: ['Active Shares', 'Expired Shares', 'Viewed Shares'],
            datasets: [{
              backgroundColor: ['rgb(119,62,177)', 'rgb(156,118,199)', 'rgb(193,174,221)'],
              data: [this.stats.number_of_shares, this.stats.expired_shares, this.stats.total_viewed_shares],
              borderWidth: 5,
              borderColor: 'rgb(243,248,251)',
              hoverBorderColor: 'rgb(243,248,251)'
            }],
          };
          tooltips = {enabled: true};
        }
        new Chart(chartCanvas, {
          type: 'doughnut',
          data: chartData,
          options: {
            legend: {
              display: false
            },
            tooltips: tooltips
          }
        });
      }
    });
  }
}
