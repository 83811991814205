import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, AfterViewInit {
  @Input('pageData') pageData: any;
  @Output('numberClicked') numberClicked = new EventEmitter();
  pageNumbers: number[] = new Array<number>();

  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    if (this.pageData) {
      let pages = Math.ceil(this.pageData.totalRecords / this.pageData.limit);
      for (let i = 1; i <= pages; i++) {
        this.pageNumbers.push(i);
      }
    }
  }

  emitNumber(page: number) {
    this.numberClicked.emit(page.toString());
  }

}
