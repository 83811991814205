import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from 'src/app/services/data.service';
import {ConfirmPopupComponent} from 'src/app/shared/confirm-popup/confirm-popup.component';
import {SharesService} from '../../services/shares.service';
import {TeamsService} from '../../services/teams.service';
import {getShareUrl} from '../shares-utils';

export abstract class BaseSharesComponent {
  activeShares: any[] = new Array<any>();
  expiredShares: any[] = new Array<any>();

  activeTab = 0;

  pagination: any;
  expiredPagination: any;

  searchValue = new Subject<string>();
  sort = 'expired_at';
  order: string;

  // Variable needed to change the message on empty screen if no results match the search criteria;
  noSearchResults: boolean;

  protected constructor(
    protected sharesService: SharesService,
    protected teamService: TeamsService,
    protected authService: AuthService,
    protected dataService: DataService,
    protected clipboard: ClipboardService,
    protected toastrService: ToastrService,
    protected dialog: MatDialog
  ) {
  }

  deleteShare(shortCode: string, index: number): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '50%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete Active Secure Share', message: 'Are you sure you want to delete this secure share?'}
    });
    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        const username = (this.authService.currentUser) ? this.authService.currentUser.username : null;
        this.sharesService.deleteShare(shortCode, username).subscribe(res => {
          if (res) {
            this.activeShares.splice(index, 1);
            this.authService.getSubscription();
          }
        });
      }
    });
  }

  deleteExpired(id: string, index: number): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '50%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete Expired Secure Share', message: 'Are you sure you want to delete this secure share?'}
    });
    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        this.sharesService.deleteExpiredShare(id).subscribe(res => {
          if (res) {
            this.expiredShares.splice(index, 1);
          }
        });
      }
    });
  }

  tabSwitched(event): void {
    this.activeTab = event.index;
    const params = new HttpParams().append('page', '1').append('sort', this.sort);
    if (event.index === 1) {
      this.getPastShares(params, false);
    } else if (event.index === 0) {
      this.getActiveShares(params, false);
    }
  }

  pageChanged(event, type): void {
    const params = new HttpParams().append('page', event).append('sort', this.sort);
    if (type === 0) {
      this.getActiveShares(params);
    } else {
      this.getPastShares(params);
    }
  }

  filtering(event): void {
    this.searchValue.next(event.target.value);
  }

  copy(shortCode: string): void {
    const link = `${getShareUrl()}${shortCode}`;
    this.clipboard.copy(link);
    this.toastrService.success('Link copied to clipboard!');
  }

  fetchSorted(): void {
    let params;
    if (this.activeTab === 0) {
      params = new HttpParams().append('page', this.pagination.page).append('sort', this.sort);
      this.getActiveShares(params);
    } else {
      params = new HttpParams().append('page', this.expiredPagination.page).append('sort', this.sort);
      this.getPastShares(params);
    }
  }

  calculateProtection(share: any): string {
    let counter = 0;
    if (share.attempts_left > 0) {
      counter++;
    }
    if (share.password_protected) {
      counter++;
    }
    if (share.requires_login) {
      counter++;
    }
    if (share.requires_mfa) {
      counter++;
    }
    if (share.timed_view) {
      counter++;
    }
    if (counter === 0) {
      return 'N/A';
    } else {
      const str = counter === 1 ? 'level' : 'levels';
      return `${counter} ${str} of protection`;
    }
  }

  abstract getActiveShares(params: HttpParams, isUserSearching?: boolean): void;

  abstract getPastShares(params: HttpParams, isUserSearching?: boolean): void;
}


