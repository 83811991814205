import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {HttpCaller} from './http-caller.service';
import {UserBillingInfo, UserData, UserStatistics} from '../models/user.model';
import {Addon} from '../models/addon-model';
import {GoogleAnalyticsService} from './google-analytics.service';
import {ChargeAddonResponse} from '../models/subscription.model';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'any'
})
export class UserService {

  constructor(
    private httpCaller: HttpCaller,
    private gaService: GoogleAnalyticsService,
  ) {
  }

  getUserById(userId: string): Observable<UserData> {
    return this.httpCaller.get(`users/${userId}`).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getUserBillingInfo(userId: string, showError: boolean = true): Observable<UserBillingInfo> {
    return this.httpCaller.get(`users/${userId}/billing`, true, null, showError).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  updateUserBillingInfo(userId: string, recurlyToken: string): Observable<UserBillingInfo> {
    return this.httpCaller.put(`users/${userId}/billing`, 'Billing info updated successfully', {
      recurly_token: recurlyToken
    }).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  deleteUserBillingInfo(userId: string): Observable<any> {
    return this.httpCaller.delete(`users/${userId}/billing`, 'Billing info deleted successfully').pipe(map((response: Response) => {
      return response as any;
    }));
  }

  purchaseAddon(userId: string, body: any): Observable<Addon> {
    return this.httpCaller.post(`users/${userId}/addons`, 'Purchase successful', body).pipe(map((response: ChargeAddonResponse) => {
      if (response) {
        this.gaService.addonPurchaseEventEmitter(response.addon_code, response.total);
      }
      return response as any;
    }));
  }

  updateUserById(userId: string, body: any): Observable<any> {
    return this.httpCaller.put(`users/${userId}`, null, body).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  deleteUserById(userId: string): Observable<any> {
    return this.httpCaller.delete(`users/${userId}`, 'Account successfully deleted').pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getUserStatistics(id: string, params?: HttpParams): Observable<UserStatistics> {
    return this.httpCaller.get(`users/${id}/statistics`, false, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }
}
