import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {SecureRequestsService} from '../../../services/secure-requests.service';
import {HttpParams} from '@angular/common/http';
import {AuthService} from '../../../services/auth.service';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {UserDeviceService} from '../../../services/user-device.service';
import {TeamsService} from '../../../services/teams.service';
import {ConfirmPopupComponent} from '../../../shared/confirm-popup/confirm-popup.component';
import {debounceTime} from 'rxjs/internal/operators';
import {NewEditRequestComponent} from '../dialogs/new-edit-request/new-edit-request.component';
import {getShareUrl} from '../../../shared/shares-utils';

@Component({
  selector: 'app-requests-overview',
  templateUrl: './requests-overview.component.html',
  styleUrls: ['./requests-overview.component.scss']
})
export class RequestsOverviewComponent implements OnInit {

  deviceType: number;

  activeTab = 0;

  pagination: any;
  expiredPagination: any;

  searchValue = new Subject<string>();
  sort = 'expired_at';
  order: string;

  // Variable needed to change the message on empty screen if no results match the search criteria;
  noSearchResults: boolean;

  activeSecureRequests: any[] = new Array<any>();
  expiredSecureRequests: any[] = new Array<any>();
  hasAccess: boolean;

  constructor(private secureRequestService: SecureRequestsService,
              private teamService: TeamsService,
              private authService: AuthService,
              private clipboard: ClipboardService,
              private toastrService: ToastrService,
              private dialog: MatDialog,
              private device: UserDeviceService) {
    this.secureRequestService = secureRequestService;
    this.authService = authService;
  }

  ngOnInit(): void {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    const params = new HttpParams().append('sort', this.sort);
    this.getActiveSecureRequests(params);
    this.searchValue.pipe(debounceTime(500)).subscribe(data => {
      const params = new HttpParams().append('filter', data);
      this.activeTab === 0 ? this.getActiveSecureRequests(params, true) : this.getExpiredSecureRequests(params, true);
    });

    this.secureRequestService.hasAccessToSecureRequestFeature(this.authService.currentUser.username).then(res => this.hasAccess = res);
  }

  tabSwitched(event): void {
    this.activeTab = event.index;
    const params = new HttpParams().append('page', '1').append('sort', this.sort);
    if (event.index === 1) {
      this.getExpiredSecureRequests(params, false);
    } else if (event.index === 0) {
      this.getActiveSecureRequests(params, false);
    }
  }

  pageChanged(event, type): void {
    const params = new HttpParams().append('page', event).append('sort', this.sort);
    if (type === 0) {
      this.getActiveSecureRequests(params);
    } else {
      this.getExpiredSecureRequests(params);
    }
  }

  filtering(event): void {
    this.searchValue.next(event.target.value);
  }

  copy(shortCode: string): void {
    const link = `${getShareUrl()}r/${shortCode}`;
    this.clipboard.copy(link);
    this.toastrService.success('Link copied to clipboard!');
  }

  fetchSorted(): void {
    let params;
    if (this.activeTab === 0) {
      params = new HttpParams().append('page', this.pagination.page).append('sort', this.sort);
      this.getActiveSecureRequests(params);
    } else {
      params = new HttpParams().append('page', this.expiredPagination.page).append('sort', this.sort);
      this.getExpiredSecureRequests(params);
    }
  }

  getActiveSecureRequests(params: HttpParams, isUserSearching?: boolean): void {
    const currentTeam = this.authService.getCurrentTeamId();
    const handleResp = (data) => {
      if (data && data.secure_requests && data.secure_requests.length > 0) {
        this.activeSecureRequests = data.secure_requests;
        this.pagination = data.pagination;
        this.noSearchResults = !isUserSearching;
      } else {
        this.activeSecureRequests = new Array<any>();
        this.pagination = null;
        this.noSearchResults = isUserSearching;
      }
    };

    if (currentTeam) {
      const isOwner = this.teamService.isOwner(currentTeam, this.authService.currentUser.username);
      const userId = isOwner ? null : this.authService.currentUser.username;
      this.secureRequestService.getTeamSecureRequests(currentTeam, userId, params).subscribe(data => handleResp(data));
    } else {
      this.secureRequestService.getSecureRequests(this.authService.currentUser.username, params).subscribe(data => handleResp(data));
    }
  }

  getExpiredSecureRequests(params: HttpParams, isUserSearching?: boolean): void {
    const currentTeam = this.authService.getCurrentTeamId();
    const handleResp = (data) => {
      if (data && data.secure_requests && data.secure_requests.length > 0) {
        this.expiredSecureRequests = data.secure_requests;
        this.expiredPagination = data.pagination;
        this.noSearchResults = !isUserSearching;
      } else {
        this.expiredSecureRequests = new Array<any>();
        this.expiredPagination = null;
        this.noSearchResults = isUserSearching;
      }
    };

    if (currentTeam) {
      const isOwner = this.teamService.isOwner(currentTeam, this.authService.currentUser.username);
      const userId = isOwner ? null : this.authService.currentUser.username;
      this.secureRequestService.getTeamExpiredSecureRequests(currentTeam, userId, params);
    } else {
      this.secureRequestService.getExpiredSecureRequests(this.authService.currentUser.username, params).subscribe(data => handleResp(data));
    }
  }

  deleteSecureRequest(shortCode: string, index: number): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '50%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete Active Secure Request', message: 'Are you sure you want to delete this request?'}
    });
    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        this.secureRequestService.deleteSecureRequest(shortCode).subscribe(res => {
          if (res) {
            this.activeSecureRequests.splice(index, 1);
            this.authService.getSubscription();
          }
        });
      }
    });
  }

  deleteExpiredSecureRequest(shortCode: string, index: number): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '50%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete Expired Secure Request', message: 'Are you sure you want to delete this request?'}
    });

    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        this.secureRequestService.deleteSecureRequest(shortCode).subscribe(res => {
          if (res) {
            this.expiredSecureRequests.splice(index, 1);
          }
        });
      }
    });
  }

  showCreateEditSecureRequestDialog(): void {
    const createEditSecureRequestDialogRef = this.dialog.open(NewEditRequestComponent, {
      width: (this.deviceType === 1) ? '70%' : '100%',
      maxWidth: (this.deviceType === 1) ? '70%' : '100%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {
        editMode: false,
      }
    });

    createEditSecureRequestDialogRef.afterClosed().subscribe((created: any[]) => {
      if (created && created.length > 1) {
        this.activeSecureRequests.unshift(...created);
      } else if (created && created.length === 1) {
        this.activeSecureRequests.unshift(created[0]);
      }
      if (this.activeSecureRequests.length === 10) {
        this.activeSecureRequests.pop();
      }
    });
  }
}
