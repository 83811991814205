import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpHeaders} from '@angular/common/http';
import {ShareCallError} from '../../../../models/share.model';
import {SecureRequestsService} from '../../../../services/secure-requests.service';

@Component({
  selector: 'app-secure-request-protection',
  templateUrl: './secure-request-protection.component.html',
  styleUrls: ['./secure-request-protection.component.scss']
})
export class SecureRequestProtectionComponent implements OnInit {
  password: string;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { info: any, headers: HttpHeaders, template: any },
    private dialogRef: MatDialogRef<SecureRequestProtectionComponent>,
    private secureRequestsService: SecureRequestsService,
  ) { }

  ngOnInit(): void {

  }

  valid(): boolean {
    let anyErrors: boolean;
    if (!this.password) {
      this.errorMessage = 'Please provide a valid password';
      anyErrors = true;
    }
    if (anyErrors) {
      return false;
    } else {
      return true;
    }
  }
  close() {
    let body = {};
    if (this.data.info.password_protected) {
      if (this.valid()) {
        body = { passcode: this.password };
      } else {
        return;
      }
    }
    this.getSecureRequestData(body);
  }

  getSecureRequestData(body) {
    this.secureRequestsService.getSecureRequest(this.data.info.short_code, body, this.data.headers).subscribe(res => {
      if (res) {
        this.dialogRef.close({ success: true, secureRequest: res });
      }
    }, (err: ShareCallError) => {
      this.dialogRef.close({ success: false, secureRequest: null, error: err.error.message });
    });
  }


}
