import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RecaptchaComponent} from 'ng-recaptcha';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../services/auth.service';
import {DataInputTypeService} from '../../../services/data-input-type.service';
import {LoaderService} from '../../../services/loader.service';
import {PasswordMeterService} from '../../../services/password-meter.service';
import {DataService} from '../../../services/data.service';
import {DatePipe} from '@angular/common';
import {CaptchaService} from '../../../services/captcha.service';
import {ShareCallError, ShareContent} from '../../../models/share.model';
import {SecureRequestsService} from '../../../services/secure-requests.service';
import {SecureRequestProtectionComponent} from '../dialogs/secure-request-protection/secure-request-protection.component';
import * as moment from 'moment';
import {StrengthData} from '../../../models/password-strength-data.model';
import {IsUUID} from '../../../shared/shares-utils';
import {TeamsService} from '../../../services/teams.service';
import {UserService} from '../../../services/user.service';
import {AngularFaviconService} from 'angular-favicon';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-request-submission',
  templateUrl: './request-submission.component.html',
  styleUrls: ['./request-submission.component.scss']
})
export class RequestSubmissionComponent implements OnInit, OnDestroy, AfterViewInit {
  shortCode: string;
  secureRequestInfo: any;

  secureRequestFull: any;

  isLoggedIn: boolean;

  secureRequestBox: boolean = true;
  secureRequestExpired: boolean = false;
  failedCaptcha = false;
  componentMessage: string;
  showPassword: boolean;

  estimatedExpiration: string;
  submittedRequest = false;
  template: string;
  isEmbedMode = false;
  @ViewChild('captchaRef') recaptchaComponent: RecaptchaComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private secureRequestsService: SecureRequestsService,
    private dialog: MatDialog,
    public authService: AuthService,
    public dataInputType: DataInputTypeService,
    public loaderService: LoaderService,
    private passwordMeter: PasswordMeterService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private notificationsService: ToastrService,
    private captchaService: CaptchaService,
    private teamService: TeamsService,
    private userService: UserService,
    private titleService: Title,
    private faviconService: AngularFaviconService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.isEmbedMode = this.route.snapshot.data.isEmbed;
    this.shortCode = this.route.snapshot.params['short-code'];
    const secureRequestInfoResp = await this.secureRequestsService.getSecureRequestInfo(this.shortCode).toPromise().catch(err => {
       if (err.error) {
          return err.error;
       }
    });

    if (secureRequestInfoResp && secureRequestInfoResp.error_code) {
      this.secureRequestBox = false;
      this.componentMessage = secureRequestInfoResp.message;
      if (secureRequestInfoResp.error_code === 71 || secureRequestInfoResp.error_code === 70 || secureRequestInfoResp.error_code === 75) {
        this.secureRequestExpired = true;
        const teamId = (secureRequestInfoResp.organization_id) ? secureRequestInfoResp.organization_id : null;
        await this.resolveTemplate(secureRequestInfoResp.created_by, teamId);
      } else {
        this.template = 'default';
      }
      this.loaderService.hideLoading();
    } else if (secureRequestInfoResp && secureRequestInfoResp.short_code) {
      this.secureRequestInfo = secureRequestInfoResp;
      const teamId = (this.secureRequestInfo.organization_id) ? this.secureRequestInfo.organization_id : null;
      await this.resolveTemplate(this.secureRequestInfo.created_by, teamId);
    } else {
      this.template = 'default';
    }
  }

  async showSecureRequest(): Promise<void> {
    if (!this.secureRequestInfo) {
      return;
    }

    if (!this.secureRequestInfo.success && this.secureRequestInfo.message) {
      this.secureRequestBox = false;
      this.componentMessage = this.secureRequestInfo.message;
      if (this.secureRequestInfo.error_code === 70) {
        this.router.navigate(['404']);
      }
    } else {
      let body = {};
      let httpHeaders: any;
      this.authService.isUserAuthenticated(false, false).then(loggedIn => {
        this.isLoggedIn = loggedIn;

        if (this.isLoggedIn) {
          if (this.secureRequestInfo.requires_login) {
            httpHeaders = {item: 'accessToken', value: this.authService.currentUser.accessToken};
          }
        } else {
          if (this.secureRequestInfo.requires_login) {
            this.loaderService.hideLoading();
            this.secureRequestBox = false;
            this.componentMessage = 'In order to view this secure request you need to login first';
            return;
          }
        }

        if (this.secureRequestInfo.view_protected || this.secureRequestInfo.password_protected) {
          const protectionPopup = this.dialog.open(SecureRequestProtectionComponent, {
            width: '460px',
            height: '471px',
            panelClass: 'popup-container',
            backdropClass: 'backdrop-blur',
            data: {info: this.secureRequestInfo, headers: httpHeaders, template: this.template},
            disableClose: true,
          });

          protectionPopup.afterClosed().subscribe((res: { success: boolean, secureRequest: any, error?: string }) => {
            if (res && res.success) {
              this.setUpSecureRequestData(res.secureRequest);
            } else {
              this.secureRequestBox = false;
              this.componentMessage = res.error;
            }
            this.loaderService.hideLoading();
          });
        } else {
          this.getSecureRequestData(body, httpHeaders);
        }
      });
    }
  }

  getSecureRequestData(body: any, headers: any): void {
    this.secureRequestsService.getSecureRequest(this.shortCode, body, headers).subscribe(sr => {
      if (sr) {
        this.setUpSecureRequestData(sr);
      }
    }, (err: ShareCallError) => {
      this.secureRequestBox = false;
      this.componentMessage = err.error.message;
      if (err.error.error_code === 71 || err.error.error_code === 70 || err.error.error_code === 75) {
        this.secureRequestExpired = true;
      }
    }, () => {
      this.loaderService.hideLoading();
    });
  }

  setUpSecureRequestData(sr: any): void {
    this.secureRequestFull = sr;
    this.secureRequestFull.metadata = JSON.parse(this.secureRequestFull.metadata);
    this.secureRequestFull.metadata.fields.sort((a, b) => {
      if (!a.order && !b.order) {
        return 0;
      }

      if (a.order && b.order) {
        return a.order - b.order;
      }
    });

    this.secureRequestFull.metadata.fields.forEach(x => {
      if (x.typeOfField === 'date') {
        x.value = this.datePipe.transform(x.value, 'M/d/yyy');
      }
    });
    this.checkPasswordStrength(this.secureRequestFull.metadata.fields);
    this.estimatedExpiration = this.calculateExpiration(this.secureRequestFull.metadata.expire_from,
      this.secureRequestFull.metadata.expiration_type,
      this.secureRequestFull.metadata.expiration_date);
  }

  goToLogin() {
    this.router.navigateByUrl('/login/from-secure-request/' + this.shortCode);
  }

  checkPasswordStrength(data: ShareContent[]) {
    for (let x of data) {
      if (x.typeOfField === 'password' && x.value) {
        x.strengthData = this.passwordMeter.checkSyncSecurity(x.value);
      }
    }
  }

  confirmCaptcha(captchaRes: string | null): void {
    if (captchaRes) {
      this.captchaService.verifyCaptcha(captchaRes, false).subscribe(res => {
        if (res) {
          this.showSecureRequest();
        } else {
          this.failedCaptcha = true;
          this.notificationsService.error('Captcha resolve failed. Please refresh the page');
        }
      }, error => {
        this.failedCaptcha = true;
      });
    } else {
      this.failedCaptcha = true;
      this.notificationsService.error('Captcha resolve failed. Please refresh the page');
    }
  }

  ngAfterViewInit(): void {
    this.loaderService.showLoading();
    if (this.recaptchaComponent) {
      this.recaptchaComponent.execute();
    }
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  submitRequest(): void {
    const fields = [];
    for (const f of this.secureRequestFull.metadata.fields) {
      fields.push({
        item: f.item,
        value: f.value,
        readonly: false,
        canDelete: false,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: f.typeOfField,
        order: f.order || 0,
      });
    }

    const body = {
      title: 'Submitted secure request',
      data: JSON.stringify(fields),
      created_by: (this.authService.currentUser) ? this.authService.currentUser.username : null,
      encryption_type: 'aes256',
      access_counts_left: null,
      attempts_left: null,
      passcode: this.secureRequestFull.metadata.passcode ? this.secureRequestFull.metadata.passcode : null,
      view_protected: this.secureRequestFull.metadata.view_protected,
      timed_view: null,
      requires_login: this.secureRequestFull.metadata.requires_login,
      requires_mfa: this.secureRequestFull.metadata.requires_mfa,
      expired_at: this.calculateExpiration(this.secureRequestFull.metadata.expire_from,
        this.secureRequestFull.metadata.expiration_type,
        this.secureRequestFull.metadata.expiration_date),
      expired_in_seconds_after_opened: this.calculateSeconds(this.secureRequestFull.metadata.expire_from,
        this.secureRequestFull.metadata.expiration_type,
        this.secureRequestFull.metadata.expiration_date),
      view_protected_message: this.secureRequestFull.metadata.view_protected_message,
      is_public: !this.authService.currentUser,
      secure_request_short_code: this.secureRequestFull.short_code,
    };

    const currentTeam = this.authService.getCurrentTeamId();
    if (currentTeam) {
      body['organization_id'] = currentTeam;
    }

    this.dataService.createShare(body, false).then(result => {
      console.log(result);
      if (!result.success && result.error && result.error.error.error_code === 61) {
        console.log('aaa');
        this.secureRequestExpired = true;
        this.componentMessage = 'No secure share credits available, please contact owner of this form.';
      } else if (result.success) {
        this.submittedRequest = true;
      }

      this.secureRequestBox = false;
    });
  }

  calculateExpiration(expireFrom: number, expireType: number, expirationDate: number): string {
    if (expireFrom === 0) {
      /**
       * expirationType(0) = HOURS
       * expirationType(1) = DAYS
       * expirationType(2) = MONTHS
       */
      switch (expireType) {
        case 0: {
          return moment().add(expirationDate, 'hours').toISOString();
        }
        case 1: {
          return moment().add(expirationDate, 'days').toISOString();
        }
        case 2: {
          return moment().add(expirationDate, 'months').toISOString();
        }
      }
    } else {
      return null;
    }
  }

  calculateSeconds(expireFrom: number, expireType: number, expirationDate: number): number {
    if (expireFrom === 0) {
      return null;
    } else {
      switch (expireType) {
        case 0: {
          return Math.ceil(3600 * expirationDate);
        }
        case 1: {
          return Math.ceil(86400 * expirationDate);
        }
        case 2: {
          return Math.ceil(2629743.83 * expirationDate);
        }
      }
    }
  }

  async resolveTemplate(userId: string, teamId: string): Promise<void> {
    try {
      let configurations;
      if (teamId) {
        const team = await this.teamService.getTeamById(teamId).toPromise();
        if (team) {
          if (team.configurations) {
            configurations = (team && team.configurations) ? JSON.parse(team.configurations) : null;
          } else {
            const owner = this.teamService.getTeamOwner(team);
            configurations = (owner && owner.configurations) ? JSON.parse(owner.configurations) : null;
          }
        }
      } else if (IsUUID(userId)) {
        const createdBy = await this.userService.getUserById(userId).toPromise();
        configurations = (createdBy && createdBy.configurations) ? JSON.parse(createdBy.configurations) : null;
      }

      if (configurations) {
        this.template = (configurations.template.name === 'default') ? 'default' : configurations.template.name;
        if (this.template === 'qm') {
          this.titleService.setTitle('SecureRequest Submission - QuoteMedia');
          this.faviconService.setFavicon('https://quotemedia.com/favicon.png');
        } else if (this.template === 'bm') {
          this.titleService.setTitle('SecureRequest Submission - Bravenet Marketing');
          this.faviconService.setFavicon('https://bravenetmarketing.com/favicon/16x16.ico');
        }
      } else {
        this.template = 'default';
      }
    } catch (e) {
      console.error(e);
      this.template = 'default';
    }
  }
}
