// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL: 'https://dev.crs.sh/',
  apiURL: 'https://api-dev.credenshare.io/',
  apiKey: 'dJKzuikwAx6JElg9iKfBV5GXtmNM54QE1FfxlMAx',
  recurlyPublicKey: 'ewr1-TWcXTnvYOK7DHuFDrIeKP9',
  hostParameter: 'api-dev.credenshare.io',
  captchaSiteKey: '6LfPDjklAAAAAJmG7MxLYsZKlAWJmzXf3maRblY-',
  gaMeasurementId: 'G-E3LDH2DB62',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
