<!-- For public -->
<mat-sidenav-container [hasBackdrop]="false" *ngIf="this.isPublicVersion" class="h-full">
  <div class="flex flex-col h-full">
    <div class="w-full bg-[#773eb1] h-14 flex items-center px-4 mb-2">
      <a href="https://credenshare.io/?utm_source=paste&utm_medium=web&utm_campaign=pastebin"><img
        src="/assets/Assets Website/Logo/Credenshare-white.svg" class="h-9"/></a>
      <div class="text-white text-xl ml-2"> - SecurePaste</div>
    </div>
    <ng-container *ngTemplateOutlet="securePasteComponent" class="flex-1"></ng-container>
    <div class="w-full bg-[#773eb1] h-14 px-4 mt-2 text-center flex items-center justify-center">
      <div>
        <a href="https://credenshare.io/privacy-policy" class="text-white text-sm">Privacy Policy</a>
        <span class="text-white text-sm mx-2">|</span>
        <a href="https://credenshare.io/terms-of-service" class="text-white text-sm">Terms of Service</a>
        <div class="flex-1 text-white text-sm">© CredenShare Inc. 2024 - All Rights Reserved</div>
      </div>
    </div>
  </div>
</mat-sidenav-container>

<!-- For logged in user -->
<div *ngIf="!this.isPublicVersion" class="flex flex-col h-full pb-4">
    <ng-container *ngTemplateOutlet="securePasteComponent" class="flex-1"></ng-container>
</div>
<ng-template #securePasteComponent>
  <div class="p-4 bg-[#f3f8fb]" [ngClass]="(this.shareLink && this.shareLink !== '') ? '' : '!hidden'">
    <div class="flex gap-x-3 items-center">
      <div>Secure Share Link</div>
      <input type="text" value="{{this.shareLink}}" class="flex-1" readonly/>
      <img (click)="copy(this.shareLink)" src="/assets/Assets Website/Btn/bx-copy.svg" class="cursor-pointer"/>
    </div>
    <button class="button-default w-full mt-4" (click)="reset()">Create Another Paste</button>
  </div>
  <mat-sidenav #securePasteSettings mode="over" position="end" fixedInViewport="true" class="w-fit">
    <div class="flex justify-center">
      <div class="block">
        <div class="bg-[#f1ecf7] p-6 flex items-center justify-between">
          <div class="text-lg">
            Settings
          </div>
          <div class="material-icons cursor-pointer" (click)="securePasteSettings.close()">close</div>
        </div>
        <div class="px-3 bg-white grid grid-cols-1 divide-solid divide-y divide-gray-300 lg:min-w-[600px]">
          <app-expires-in class="border-x-0"></app-expires-in>
          <app-number-of-view class="border-x-0" *ngIf="!isPublicVersion"></app-number-of-view>
          <app-password-to-view class="border-x-0" *ngIf="!isPublicVersion"
                                [user-plan]="authService.userPlan"></app-password-to-view>
          <app-misc-share-settings class="border-x-0" *ngIf="!isPublicVersion"></app-misc-share-settings>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <div class="flex-1 flex flex-col">
    <div class="px-4 lg:grid lg:grid-cols-4 lg:grid-flow-col lg:flex-1 gap-x-4"
         [ngClass]="(!this.shareLink || this.shareLink === '') ? '' : '!hidden'">
      <mat-tab-group #matTabGroup animationDuration="0" class="secure-paste-tabs lg:col-span-3">
        <mat-tab label="Editor">
        <textarea placeholder="Paste here"
                  [ngClass]="this.content.errors?.required && this.content.touched ? 'border !border-red-500' : ''"
                  class="w-full mt-4 resize-y" [formControl]="this.content"
                  *ngIf="selectedFormat === 'markdown'"></textarea>

          <div class="mt-4 w-full" *ngIf="selectedFormat === 'source_code'">
            <ngx-codemirror
              #codeMirror
              [formControl]="content"
              [options]="{ theme: 'material', mode: selectedLanguageCtrl.value.mode }"
            ></ngx-codemirror>
          </div>

        </mat-tab>
        <mat-tab label="Preview" *ngIf="selectedFormat === 'markdown'">
          <div markdown [data]="this.content.value" class="markdown-body min-h-[24rem] !mt-4 w-full">
          </div>
        </mat-tab>
        <mat-tab disabled>
          <ng-template mat-tab-label>
            <div class="flex w-full items-center text-left ml-2">
              <div class="flex-1">
                <select #pasteFormat class="w-[150px] lg:w-[200px] mr-4" (change)="changeFormat(pasteFormat.value)"
                        [value]="selectedFormat">
                  <option value="markdown">Markdown</option>
                  <option value="source_code">Source Code</option>
                </select>
                <mat-form-field appearance="outline" class="w-[150px] lg:w-[200px]"
                                [ngClass]="selectedFormat !== 'source_code' ? '!hidden' : ''">
                  <mat-select #selectLanguage (selectionChange)="changeLanguage($event)"
                              [formControl]="selectedLanguageCtrl">
                    <mat-option>
                      <ngx-mat-select-search noEntriesFoundLabel="No matching language found" placeholderLabel="Search"
                                             [formControl]="selectedLanguageFilterCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let lang of filteredLanguages | async" [value]="lang">{{ lang.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="material-icons lg:ml-4 text-[#773eb1] cursor-pointer" mat-button
                   (click)="securePasteSettings.toggle()" *ngIf="!isPublicVersion">settings
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <div class="w-full flex flex-col mt-4 lg:mt-0">
        <div class="bg-[#f1ecf7] rounded-t-2xl p-6 text-lg border-solid border border-purple-300 border-b-0">
          {{ (isPublicVersion) ? 'Settings' : 'Selected Settings' }}
        </div>
        <div class="px-3 border-solid border border-purple-300 bg-white border-t-0 rounded-b-2xl py-4">
          <app-expires-in class="border-x-0" *ngIf="isPublicVersion"></app-expires-in>
          <ul *ngIf="viewInitialized && !isPublicVersion">
            <li *ngIf="this.expiresComponent">Secure Paste will be expired
              in {{ this.expiresComponent.expirationDate }} {{ this.expiresComponent.expirationType | expirationDateText : this.expiresComponent.expirationDate }}
              <span *ngIf="expiresComponent.expireFrom === 1">after first view</span>.
            </li>
            <li
              *ngIf="this.numberOfViewComponent && (!this.numberOfViewComponent.viewingTimes || this.numberOfViewComponent.unlimitedViewTime)">
              Secure Paste can be viewed unlimited times.
            </li>
            <li
              *ngIf="this.numberOfViewComponent && (this.numberOfViewComponent.viewingTimes && !this.numberOfViewComponent.unlimitedViewTime)">
              Secure Paste can be viewed {{ this.numberOfViewComponent.viewingTimesValue }} times.
            </li>
            <li *ngIf="this.passwordComponent && passwordComponent.askPassword.value">Secure Paste is password
              protected.
            </li>
            <li *ngIf="this.passwordComponent && passwordComponent.failedAttemptsToleranceEnabled.value">Secure Paste
              will
              be deleted after {{ passwordComponent.failedAttemptsValue }} times of entering wrong password.
            </li>
            <li *ngIf="this.passwordComponent && passwordComponent.secureViewPromptEnabled.value">Secure Paste has
              secure
              view prompt.
            </li>
            <li *ngIf="this.miscShareSettingsComponent && miscShareSettingsComponent.timedViewEnabled.value">Secure
              Paste
              will be expired in {{ miscShareSettingsComponent.timedViewValue }} seconds after opened.
            </li>
            <li *ngIf="this.miscShareSettingsComponent && miscShareSettingsComponent.requireLoginEnabled.value">Secure
              Paste requires user logged in to view.
            </li>
            <li *ngIf="this.miscShareSettingsComponent && miscShareSettingsComponent.onlyOwnerCanDeleteEnabled.value">
              Only
              owner can delete this Secure Paste.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="px-4 flex items-center gap-2" *ngIf="!this.shareLink || this.shareLink === ''">
      <button class="button-default mt-2" (click)="createShare()">Create</button>
      <div class="mt-2 text-sm" *ngIf="isPublicVersion">
        By clicking "Create" you agree to our <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of Service</a> and understand that this SecurePaste will be available as a publicly available link
      </div>
    </div>
  </div>
</ng-template>
