<div class="page-container">
  <div class="left-side">
    <re-captcha #captchaRef size="invisible" (resolved)="$event && confirmCaptcha($event)"></re-captcha>
    <ng-container class="elements-wrapper">
      <div class="app-logo">
        <img src="https://quotemedia.com/assets/img/quotemedia_large_company_logo.png"/>
      </div>
      <div class="credentials-box" *ngIf="secureRequestBox">
        <h3>Secure Request Form</h3>
        <div class="spinner-wrapper" *ngIf="!secureRequestFull && !failedCaptcha"><div class="spinner"></div></div>
        <p *ngIf="failedCaptcha" class="failed-captcha">Captcha verification failed. Please refresh the page.</p>
        <ng-container *ngIf="this.secureRequestFull && this.secureRequestFull.metadata">
          <div class="input-wrapper" *ngFor="let input of this.secureRequestFull.metadata.fields; let i = index">
            <span class="field-title">{{input.item}}</span>
            <div class="wrapper-inner">
              <input *ngIf="input.typeOfField !== 'date' && input.typeOfField !== 'multiline'"
                     [id]="'user-input-' + i" [(ngModel)]="input.value"
                     [ngClass]="input.typeOfField === 'password' ? 'password-text' : 'regular-text'"/>
              <textarea *ngIf="input.typeOfField === 'multiline'" class="regular-text" placeholder="Please enter"
                        [(ngModel)]="input.value">
                    </textarea>
              <mat-form-field *ngIf="input.typeOfField === 'date'" appearance="outline">
                <input [id]="'user-input-' + i" [(ngModel)]="input.value" readonly placeholder="Choose a date"
                       [ngxMatDatetimePicker]="picker" class="regular-text !border-none">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
              </mat-form-field>
              <div class="eye-container" *ngIf="input.typeOfField === 'password'">
                <img class="eye-icon"
                     (click)="dataInputType.resetInputType(i, false); showPassword = !showPassword"
                     [src]="showPassword ? '/assets/Assets Website/Btn/view-on.svg' : '/assets/Assets Website/Btn/view-off.svg'"
                     alt="show/hide password"/>
              </div>
            </div>
          </div>
          <div class="input-wrapper">
            <div class="notice">
              <mat-icon color="primary">
                info
              </mat-icon>
              <div>
                You will be submitting your information securely to the requesting party. All data is transmitted using TLS encryption and
                stored using AES256 encryption. <span *ngIf="this.secureRequestFull.metadata.expire_from === 0">The information you share will be scrubbed from our database by {{this.secureRequestFull.expired_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</span>
              </div>
            </div>
          </div>
          <div class="action-buttons">
            <button class="button-default signin-button" (click)="submitRequest()">Submit</button>
          </div>
        </ng-container>
      </div>
      <app-success *ngIf="secureRequestExpired" imageUrl="/assets/Assets Website/Icn/shared expired.svg"
                   [showDashboardLink]="false"
                   [title]="componentMessage ? componentMessage : 'Secure Request Form Expired'"
                   [message]="secureRequestExpired ? 'The secure request form is no longer submittable.' : null"></app-success>

      <app-success *ngIf="submittedRequest" imageUrl="/assets/Assets Website/Icn/share created.svg"
                   [showDashboardLink]="true"
                   [title]="componentMessage ? componentMessage : 'Secure Request Form Submitted'"
                   [message]="submittedRequest ? 'Thank you for submitting the requested information. The request sender will receive the data shortly.' : null"></app-success>
    </ng-container>
  </div>
  <div class="right-side">
    <div class="right-side-inner">
      <div class="app-logo">
        <img src="https://quotemedia.com/assets/img/quotemedia_large_company_logo.png"/>
      </div>
      <div class="description-wrapper">
        <p class="description">
          The information in this page may include non-public, proprietary, confidential or intellectual property of
          Quotemedia Inc. and its affiliates and is only directed to its intended recipient. <br /> <br />
          Any dissemination, distribution or copying of the information contained in or transmitted with this link is
          unauthorized and strictly prohibited. <br /> <br />
          If you have received this link in error, please immediately notify <a href="mailto:support@quotemedia.com">support@quotemedia.com</a> and permanently
          delete this information by clicking the Red <span style="color:red; font-style: italic;">"Delete the Secure Share Forever"</span>&nbsp;&nbsp;button at the bottom of the form, and any
          copies of it immediately. You should not retain, copy or use this link or any information contained herein for
          any purpose, nor disclose all or any part of the contents to any other person, entity or system.
        </p>
      </div>

      <div class="logout-button">
        <label>
          Copyright © 2022 QuoteMedia, Inc. All Rights Reserved <br />
          <a href="https://quotemedia.com" target="_blank">Home</a>&nbsp;|&nbsp;<a href="https://quotemedia.com/legal/tos/" target="_blank">Terms of Use</a>&nbsp;|&nbsp;<a href="https://quotemedia.com/legal/privacy/" target="_blank">Privacy Policy</a>
        </label>
      </div>
    </div>
  </div>
</div>
