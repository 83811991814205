import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {
  @Input('strength-data') public strengthData: any;
  @Input('score') public score: number;

  constructor(
  ) { }

  ngOnInit(): void {
  }


}
