import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
import {LoaderService} from './loader.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class HttpCaller {
  private baseUrl = environment.apiURL;

  constructor(
    private http: HttpClient,
    private notificationsService: ToastrService,
    public loaderService: LoaderService,
    private router: Router
  ) { }

  get(url: string, hasLoading: boolean = true, params?:HttpParams, errorMessage: boolean = true): Observable<any> {
    if (hasLoading) {
      this.loaderService.showLoading();
    }

    return this.http.get<any>(this.baseUrl + url, {observe: 'response', params: params})
    .pipe(
      catchError(error => {
        this.handleError(error, errorMessage);
        throw error;
      }),
      map(response => {
        if (hasLoading) {
          this.loaderService.hideLoading();
        }
        return response.body;
      })
    );
  }

  put(url: string, successMessage?: string, body?: any, hasLoading: boolean = true): Observable<any> {
    if (hasLoading) {
      this.loaderService.showLoading();
    }

    return this.http.put<any>(this.baseUrl + url, body, {observe: 'response'})
    .pipe(
      catchError(error => {
        this.handleError(error);
        throw error;
      }),
      map(response => {
        if (successMessage) {
          this.notificationsService.success(successMessage, 'Success!');
        }
        if (hasLoading) {
          this.loaderService.hideLoading();
        }
        return response.body;
      })
    );
  }


  post(url: string, successMessage?: string, body?: any, hasLoading: boolean = true, headers?: any): Observable<any> {
    if (hasLoading) {
      this.loaderService.showLoading();
    }
    let setHeaders: HttpHeaders;
    if (headers) {
      setHeaders = new HttpHeaders({});
      setHeaders = setHeaders.append(headers.item, headers.value);
    }
    return this.http.post<Response>(this.baseUrl + url, body, {headers: setHeaders, observe: 'response'})
    .pipe(
      catchError(error => {
        this.handleError(error);
        throw error;
      }),
      map(response => {
        if (successMessage) {
          this.notificationsService.success(successMessage, 'Success!');
        }
        if (hasLoading) {
          this.loaderService.hideLoading();
        }
        return response.body;
      })
    );
  }

  delete(url: string, successMessage: string, hasLoading: boolean = true, params?: HttpParams): Observable<any> {
    if (hasLoading) {
      this.loaderService.showLoading();
    }

    return this.http.delete<any>(this.baseUrl + url, {observe: 'response', params})
    .pipe(
        catchError(error => {
            this.handleError(error);
            throw error;
          }),
          map(response => {
            this.notificationsService.success(successMessage, 'Success!');
            if (hasLoading) {
              this.loaderService.hideLoading();
            }
            return response.body;
          })
    );
  }

  private handleError(error: any, showNotification: boolean = true): any {
    if (error.error.error_code === 401) {
      this.notificationsService.error('Invalid credentials', 'Unauthorized');
      this.router.navigate(['/login']);
    } else if (error.error.message && showNotification) {
      this.notificationsService.error(error.error.message, 'Error');
    }

    this.loaderService.hideLoading();
    return throwError('Something bad happened; please try again later.');
  }
}
