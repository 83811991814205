<section class="settings-group">
  <div class="setting">
    <div class="label checkbox-label">
      <input type="checkbox" [(ngModel)]="viewingTimes" />
      <label>Number of viewing times before deletion</label>
    </div>
    <div class="toggles limitation">
      <button [disabled]="!viewingTimes" type="button"
              [ngClass]="!unlimitedViewTime ? 'button-default' : 'button-secondary'"
              (click)="unlimitedViewTime = false">Limited</button>
      <button [disabled]="!viewingTimes" type="button"
              [ngClass]="unlimitedViewTime ? 'button-default' : 'button-secondary'"
              (click)="unlimitedViewTime = true">Unlimited</button>
    </div>
  </div>
  <div class="setting">
    <div class="label"></div>
    <div class="slider">
      <span class="slider-label">1</span>
      <mat-slider [(ngModel)]="viewingTimesValue"
                  [disabled]="!viewingTimes || unlimitedViewTime" min="1" max="10" step="1"
                  thumbLabel="true">
      </mat-slider>
      <span class="slider-label">10</span>
    </div>
  </div>
  <div class="setting">
    <div class="label"></div>
    <div class="slider">
      <p class="expire-note">
        This secure share can be viewed {{!viewingTimes || unlimitedViewTime ? 'unlimited' : viewingTimesValue}} times
      </p>
    </div>
  </div>
</section>
