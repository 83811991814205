<div class="popup-header">
    <h3 class="popup-title">{{data.type === 0 ? 'IP' : 'Domain'}} Whitelist</h3>
</div>
<div class="data-container">
    <div class="input-container">
        <input [(ngModel)]="itemValue" type="text" placeholder="Please enter..." />
    </div>
    <ng-container *ngIf="list && list.length > 0">
        <div class="item" *ngFor="let item of list; let i = index">
            <span>{{item}}</span>
            <img (click)="removeItem(i)" src="/assets/Assets Website/Btn/trash.svg" />
        </div>
    </ng-container>
</div>
<hr/>
<div class="buttons-container">
    <button class="button-default another" (click)="addToList()">{{list && list.length > 0 ? 'Add another' : 'Add'}}</button>
    <button class="button-secondary" (click)="close()">Done</button>
</div>