import {Component} from '@angular/core';
import {ShareViewComponent} from '../share-view.component';

@Component({
  selector: 'app-bm-share-view',
  templateUrl: './share-view.component.html',
  styleUrls: ['./share-view.component.scss']
})
export class BmShareViewComponent extends ShareViewComponent {


}
