<div class="page-wrap">
  <div class="chart-and-plan">
    <div class="quickshare-container" *ngIf="stats">
      <app-quick-share [is-quick-share]="true" (shareCreated)="updateShares($event)"
                       (fullSettings)="createShare()"></app-quick-share>
    </div>
    <div class="legend-container" *ngIf="stats && !teamStats">
      <h3>Your stats</h3>
      <div class="legend-items">
        <div class="item">
          <div class="bar first"></div>
          <div class="value">
            <span class="bar-header">{{stats.number_of_shares | number: '1.0-0'}}</span>
            <span class="bar-title">Active Shares</span>
          </div>
        </div>
        <div class="item">
          <div class="bar second"></div>
          <div class="value">
            <span class="bar-header">{{stats.expired_shares | number: '1.0-0'}}</span>
            <span class="bar-title">Expired Shares</span>
          </div>
        </div>
        <div class="item">
          <div class="bar third"></div>
          <div class="value">
            <span class="bar-header">{{stats.total_viewed_shares | number: '1.0-0'}}</span>
            <span class="bar-title">Viewed Shares</span>
          </div>
        </div>
      </div>
    </div>

    <div class="legend-container" *ngIf="teamStats">
      <h3>Team stats</h3>
      <div class="legend-items">
        <div class="item">
          <div class="bar first"></div>
          <div class="value">
            <span class="bar-header">{{teamStats.total_confirmed_members | number:'1.0-0'}}</span>
            <span class="bar-title">Confirmed members</span>
          </div>
        </div>
        <div class="item">
          <div class="bar second"></div>
          <div class="value">
            <span class="bar-header">{{teamStats.total_pending_members | number: '1.0-0'}}</span>
            <span class="bar-title">Pending members</span>
          </div>
        </div>
        <div class="item">
          <div class="bar third"></div>
          <div class="value">
            <span class="bar-header">{{teamStats.total_shares_created | number: '1.0-0'}}</span>
            <span class="bar-title">Total Shares Created</span>
          </div>
        </div>
        <div class="item">
          <div class="bar fourth"></div>
          <div class="value">
            <span class="bar-header">{{teamStats.avg_shares_per_week | number: '1.0-0'}}</span>
            <span class="bar-title">Avg Shares Per Week</span>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="deviceType === 0" class="canvas-container">
      <canvas id="statsChart"></canvas>
      <p class="no-data-pie" *ngIf="noData">No data to display</p>
    </div>
    <app-user-plan *ngIf="stats" [wide]="true" [planData]="authService.userPlan" [cancellingSubscription]="false">
    </app-user-plan>
  </div>
  <ng-container *ngIf="recentShares && recentShares.length > 0">
    <h3>Latest active shares</h3>
    <div class="shares-grid">
      <div *ngFor="let share of recentShares" class="share-card">
        <p class="share-title">
          <span class="title-text">{{share.title}}</span>
          <span class="copy" title="Copy to clipboard">
                        <img (click)="copy(share.short_code)" src="/assets/Assets Website/Btn/bx-copy.svg"/>
                    </span>
        </p>
        <div class="share-date">
          <span class="date-title">Shared On</span>
          <span>{{share.created_at | date: 'MMM dd, yyyy'}}</span>
        </div>
        <hr/>
        <div class="expiration-date">
          <ng-container *ngIf="share.expired_at; else expiresAfterFirstView">
            <span class="date-title">Expiry Date</span>
            <span>{{share.expired_at | date: 'MMM dd, yyyy'}}</span>
          </ng-container>
          <ng-template #expiresAfterFirstView>
            <span class="no-expiry">Expiration date will be displayed after the secure share is viewed</span>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
