<div class="popup-header">
    <h3>{{data.header}}</h3>
    <div (click)="close(false)" class="close-icon">
        <img style="cursor: pointer;" src="/assets/Assets Website/Btn/close.svg">
    </div>
</div>
<div class="confirm-body">
    <p [innerHTML]="data.message"></p>
</div>
<div class="confirm-buttons">
    <button *ngIf="!data.noCancelButton" (click)="close(false)" class="button-secondary">Cancel</button>
    <button class="button-default" (click)="close(true)">{{data.buttonMessage ? data.buttonMessage : 'Confirm'}}</button>
</div>
