<section class="settings-group">
  <div class="setting">
    <p class="label">Expires in</p>
    <div class="toggles">
      <button type="button" (click)="updateExpirationType(0, 24)"
              [ngClass]="expirationType === 0 ? 'button-default' : 'button-secondary'">Hours</button>
      <button type="button" (click)="updateExpirationType(1, 31)"
              [ngClass]="expirationType === 1 ? 'button-default' : 'button-secondary'">Days</button>
      <button type="button" (click)="updateExpirationType(2, 3)"
              [ngClass]="expirationType === 2 ? 'button-default' : 'button-secondary'">Months</button>
    </div>
  </div>
  <div class="setting">
    <div class="label"></div>
    <div class="slider">
      <span class="slider-label">1</span>
      <mat-slider [(ngModel)]="expirationDate" min="1"
                  [max]="expirationType === 0 ? '24' : expirationType === 1 ? '31' : '3'"
                  step="1" thumbLabel="true">
      </mat-slider>
      <span class="slider-label">{{expirationType === 0 ? '24' : expirationType === 1
        ?
        '31' : '3'}}</span>
    </div>
  </div>
  <div class="setting">
    <div class="label"></div>
    <div class="slider">
      <p class="expire-note">
        This secure share expires in
        {{expirationDate}} {{expirationType | expirationDateText : expirationDate}}
      </p>
    </div>
  </div>
  <div class="setting">
    <div class="label"></div>
    <div class="toggles flex-col gap-y-2">
      <button type="button" class="expire-from !w-full" (click)="expireFrom = 0"
              [ngClass]="expireFrom === 0 ? 'button-default' : 'button-secondary'">From
        Creation</button>
      <button type="button" class="expire-from !w-full" (click)="expireFrom = 1"
              [ngClass]="expireFrom === 1 ? 'button-default' : 'button-secondary'">From First
        View</button>
    </div>
  </div>
</section>
