<mat-tab-group #tabs (selectedTabChange)="onTabChanged($event);">
  <mat-tab label="Personal data">
    <div class="personal-data-container">
      <h4></h4>
      <form class="email-container" [formGroup]="userEmailForm" (ngSubmit)="changeEmail()">
        <div class="data-label">
          <label>Email</label>
        </div>
        <div class="email-input">
          <input type="text" formControlName="email" placeholder="Email"/>
        </div>
        <div class="data-label"  *ngIf="!this.authService.currentUser.details.emailVerified"></div>
        <div  *ngIf="!this.authService.currentUser.details.emailVerified" class="email-not-verified-text">Email is not verified. <a href="javascript:;" (click)="resendVerificationEmail()">Click here</a> to resend verification email.</div>
        <div class="data-label"></div>
        <div class="data-input">
          <button class="button-default submit" type="submit">Change email</button>
        </div>
      </form>
      <h4>Personal data</h4>
      <form [formGroup]="userDetailsForm" (ngSubmit)="updateUserDetails()">
        <div class="data-label">
          <label>First name</label>
        </div>
        <div class="data-input">
          <input type="text" formControlName="firstName" [pattern]="inputValidators.textRegex"
                 placeholder="First name" required/>
        </div>

        <div class="data-label">
          <label>Last name</label>
        </div>
        <div class="data-input">
          <input type="text" formControlName="lastName" [pattern]="inputValidators.textRegex"
                 placeholder="Last name" required/>
        </div>

        <div class="data-label">
          <label>Address</label>
        </div>
        <div class="data-input">
          <input type="text" formControlName="address" placeholder="Address"
                 [pattern]="inputValidators.addressRegex" required/>
        </div>

        <div class="data-label">
          <label>Company</label>
        </div>
        <div class="data-input">
          <input type="text" formControlName="company" placeholder="Company name"/>
        </div>
        <div class="data-label"></div>
        <div class="data-input">
          <button class="button-default submit" type="submit">Update details</button>
        </div>
      </form>
      <h4>Security</h4>
      <form [formGroup]="security" (ngSubmit)="updatePassword()">
        <!-- <div class="data-label">
            <label>Two-factor Authentication</label>
        </div>
        <div class="data-input">
            <div class="switch-container" (click)="twoFactorAuth = !twoFactorAuth">
                <button type="button" [ngClass]="twoFactorAuth ? 'switch-button' : 'switch-active'">OFF</button>
                <button type="button" [ngClass]="twoFactorAuth ? 'switch-active' : 'switch-button'">ON</button>
            </div>
        </div> -->
        <div class="data-label">
          <label>Password</label>
        </div>
        <div class="data-input">
          <input type="password" formControlName="currentPassword" placeholder="Current password" required/>
        </div>
        <div class="data-label"></div>
        <div class="data-input">
          <input type="password" formControlName="newPassword" placeholder="New password" required/>
        </div>
        <div class="data-label"></div>
        <div class="data-input">
          <input type="password" formControlName="confirmPassword" placeholder="Confirm new password"
                 required/>
        </div>
        <div class="data-label"></div>
        <div class="data-input">
          <button class="button-default submit" type="submit">Change password</button>
        </div>
      </form>
    </div>
    <div (click)="deleteAccount()" class="delete-account">
      <span>Delete Account</span>
    </div>
  </mat-tab>
  <mat-tab label="Subscription">
    <button *ngIf="authService.userPlan && authService.userPlan.plan.plan_code !== subPlansService.freePlanCode"
            class="button-default cancel-subscription" (click)="cancelSubscription()">Cancel
      Subscription
    </button>
    <div class="page-wrap">
      <app-user-plan [planData]="authService.userPlan" [teamMembersStatistics]="this.teamMembersStatistics"
                     [cancellingSubscription]="false"></app-user-plan>
      <h3 class="other-plans">Other Plans</h3>
      <div class="plan-switch">
        <div class="switch-container">
          <button (click)="changePlanType(0)" type="button"
                  [ngClass]="typeOfPlan === 0 ? 'switch-active2' : 'switch-button2'">Annual billing
            (save up to 20%)
          </button>
          <button (click)="changePlanType(1)" type="button"
                  [ngClass]="typeOfPlan === 1 ? 'switch-active2' : 'switch-button2' ">Monthly
            billing
          </button>
          <button *ngIf="authService.userPlan && !authService.userPlan.plan.plan_code.includes('enterprise')" (click)="changePlanType(2)"
                  type="button"
                  [ngClass]="typeOfPlan === 2 ? 'switch-active2' : 'switch-button2'">
            Buy more shares
          </button>
        </div>
      </div>
      <!-- <mat-button-toggle-group style="margin-bottom: 15px;" [(ngModel)]="typeOfPlan">
          <mat-button-toggle [value]="false">Monthly</mat-button-toggle>
          <mat-button-toggle [value]="true">Yearly</mat-button-toggle>
      </mat-button-toggle-group> -->
      <div class="plans-container">
        <ng-container *ngIf="typeOfPlan === 0">
          <div class="plan-box" *ngFor="let plan of yearlyPlans">
            <div class="box-header">
              <h4>{{plan.plan_name}}</h4>
              <span class="price"
                    *ngIf="!plan.plan_code.includes('enterprise')">{{plan.price === 0 ? 'Free' : '$' + plan.price}}</span>
            </div>
            <div class="box-body">
              <div *ngFor="let f of plan.features" class="feature-row">
                <span class="f-name">{{f.feature_name}}</span>
                <span *ngIf="f.feature_value === 'true'" class="f-value">Yes</span>
                <span *ngIf="f.feature_value === 'false'" class="f-value">No</span>
                <span *ngIf="f.feature_value !== 'true' && f.feature_value !== 'false'"
                      class="f-value">{{f.feature_value === '-1' ? 'Unlimited' : f.feature_value}}</span>
              </div>
              <div *ngFor="let a of plan.addons" class="feature-row">
                <span class="f-name">{{a.addon_name}}</span>
                <span class="f-value">${{a.price}}/user/{{plan.plan_code.includes("year") ? 'year' : 'month'}}</span>
              </div>
            </div>
            <button *ngIf="!plan.plan_code.includes('enterprise')"
                    [disabled]="authService.userPlan.plan.plan_id && plan.plan_id === authService.userPlan.plan.plan_id"
                    class="button-default" (click)="selectPlan(plan)">{{authService.userPlan.plan.plan_id &&
            plan.plan_id === authService.userPlan.plan.plan_id ? 'Selected' :
              'Select plan'}}</button>
            <a *ngIf="plan.plan_code.includes('enterprise')" class="button-default"
               href="mailto:support@credenshare.io">Contact us</a>
          </div>
        </ng-container>
        <ng-container *ngIf="typeOfPlan === 1">
          <ng-container *ngFor="let plan of monthlyPlans">
            <div class="plan-box" *ngIf="plan.plan_code !== subPlansService.freePlanCode">
              <div class="box-header">
                <h4>{{plan.plan_name}}</h4>
                <span class="price"
                      *ngIf="!plan.plan_code.includes('enterprise')">{{plan.price === 0 ? 'Free' : '$' + plan.price}}</span>
              </div>
              <div class="box-body">
                <div *ngFor="let f of plan.features" class="feature-row">
                  <span class="f-name">{{f.feature_name}}</span>
                  <span *ngIf="f.feature_value === 'true'" class="f-value">Yes</span>
                  <span *ngIf="f.feature_value === 'false'" class="f-value">No</span>
                  <span *ngIf="f.feature_value !== 'true' && f.feature_value !== 'false'"
                        class="f-value">{{f.feature_value === '-1' ? 'Unlimited' :
                    f.feature_value}}</span>
                </div>
                <div *ngFor="let a of plan.addons" class="feature-row">
                  <span class="f-name">{{a.addon_name}}</span>
                  <span class="f-value">${{a.price}}/user/{{plan.plan_code.includes("year") ? 'year' : 'month'}}</span>
                </div>
              </div>
              <button *ngIf="!plan.plan_code.includes('enterprise')"
                      [disabled]="authService.userPlan.plan.plan_id && plan.plan_id === authService.userPlan.plan.plan_id"
                      class="button-default" (click)="selectPlan(plan)">{{authService.userPlan.plan.plan_id &&
              plan.plan_id === authService.userPlan.plan.plan_id ? 'Selected' :
                'Select plan'}}</button>
              <a *ngIf="plan.plan_code.includes('enterprise')" class="button-default"
                 href="mailto:support@credenshare.io">Contact us</a>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="typeOfPlan === 2 && addons && addons.length > 0">
                    <span *ngFor="let a of addons">
                        <div class="plan-box" *ngIf="a.is_show">
                          <div class="box-header">
                              <h4>
                                  {{a.addon_name}}
                              </h4>
                              <span class="price">${{a.price}}</span>
                          </div>
                          <div class="box-body">
                              <span class="more-shares-desc">{{a.description}}</span>
                          </div>
                          <button class="button-default" (click)="purchaseAddon(a)">Buy</button>
                      </div>
                    </span>
        </ng-container>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Payment history">
    <div *ngIf="invoiceDetails && invoiceDetails.length > 0; else noPayments">
      <table *ngIf="deviceType === 1">
        <tr class="table-header">
          <th class="date">Date</th>
          <th class="purpose">Purpose</th>
          <th class="amount">Amount</th>
          <th class="actions">Actions</th>
        </tr>
        <tr class="table-data" *ngFor="let d of invoiceDetails">
          <td class="date">{{d.created_at | date:'MMM dd yyyy'}}</td>
          <td class="purpose">{{d.plan.description ? d.plan.description : 'Secure shares pack'}} {{d.type}}</td>
          <td class="amount">{{d.total | currency: 'USD'}}</td>
          <td class="actions">
            <img (click)="downloadInvoice(d.plan.description, d.id)"
                 src="/assets/Assets Website/Btn/actions.svg"/>
          </td>
        </tr>
      </table>
      <div *ngIf="deviceType === 0" class="shares-grid">
        <div *ngFor="let d of invoiceDetails" class="share-card">
          <p class="share-title">
            <span
              class="title-text">{{d.plan.description ? d.plan.description : 'Secure shares pack'}} {{d.type}}</span>
            <span class="copy" title="Copy to clipboard">
                            <img (click)="downloadInvoice(d.plan.description, d.id)"
                                 src="/assets/Assets Website/Btn/actions.svg"/>
                        </span>
          </p>
          <hr/>
          <div class="share-data">
            <div class="share-date">
              <span class="date-title">Date</span>
              <span class="share-creation">{{d.created_at | date:'MMM dd yyyy'}}</span>
            </div>
            <div class="expiration-date">
              <ng-container>
                <span class="date-title">Amount</span>
                <span class="share-creation">$ {{d.total}}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noPayments>
      <p class="no-shares">No payment history associated with your account</p>
    </ng-template>
  </mat-tab>
  <mat-tab label="Billing">
    <div class="billing-tab">
      <div class="card-container" *ngIf="this.userBillingInfo">
        <div class="card-logo-container">
          <img class="card-logo" src="/assets/logos/{{paymentLogo(this.userBillingInfo.payment_method.card_type)}}"
               *ngIf="paymentLogo(this.userBillingInfo.payment_method.card_type)"/>
          <mat-icon class="card-logo" color="primary"
                    *ngIf="!paymentLogo(this.userBillingInfo.payment_method.card_type)">
            credit_card
          </mat-icon>
        </div>
        <div class="billing-info">
          <span>{{this.userBillingInfo.first_name}} {{this.userBillingInfo.last_name}}</span>
          <span>{{(this.userBillingInfo.address.street1) ? this.userBillingInfo.address.street1 : this.userBillingInfo.address.street2 }} {{this.userBillingInfo.address.region}} {{this.userBillingInfo.address.city}} {{this.userBillingInfo.address.country}}</span>
          <span>{{this.userBillingInfo.payment_method.first_six}}
            ******{{this.userBillingInfo.payment_method.last_four}}</span>
          <span>{{this.userBillingInfo.payment_method.exp_month}}
            /{{this.userBillingInfo.payment_method.exp_year}}</span>
        </div>
        <img style="cursor: pointer" (click)="deleteBillingInfoDialog()" src="/assets/Assets Website/Btn/trash.svg" />
      </div>
      <button class="button-default" (click)="updateBillingInfoDialog()">Add or replace payment card</button>
    </div>
  </mat-tab>
</mat-tab-group>
