import {DatePipe} from '@angular/common';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {Addon} from 'src/app/models/addon-model';
import {InputValidators} from 'src/app/models/input-validators';
import {Plan, UserSubscription} from 'src/app/models/plan.model';
import {UserBillingInfo, UserDetails} from 'src/app/models/user.model';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from 'src/app/services/data.service';
import {LoaderService} from 'src/app/services/loader.service';
import {SubscriptionPlansService} from 'src/app/services/subscription-plans.service';
import {UserDeviceService} from 'src/app/services/user-device.service';
import {UserService} from 'src/app/services/user.service';
import {CancelSubscriptionPopupComponent} from 'src/app/shared/cancel-subscription-popup/cancel-subscription-popup.component';
import {ConfirmPopupComponent} from 'src/app/shared/confirm-popup/confirm-popup.component';
import {RecurlyComponent} from 'src/app/shared/recurly/recurly.component';

import {AddonService} from '../../services/addon.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit, AfterViewInit {
  @ViewChild('tabs') tabGroup: MatTabGroup;
  title: string = 'User Account';
  deviceType: number;

  inputValidators = InputValidators;
  currentUser: UserDetails;
  userEmailForm: FormGroup;
  userDetailsForm: FormGroup;
  security: FormGroup;

  twoFactorAuth: boolean = false;
  sharesRemaining: number;

  invoiceDetails: any[];

  typeOfPlan: number = 0;
  monthlyPlans = new Array<Plan>();
  yearlyPlans = new Array<Plan>();

  addons = new Array<Addon>();

  userBillingInfo: UserBillingInfo;

  plansSubscription: Subscription;
  teamMembersStatistics: any;

  constructor(
    public authService: AuthService,
    private loaderService: LoaderService,
    private dataService: DataService,
    public subPlansService: SubscriptionPlansService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: ToastrService,
    private datePipe: DatePipe,
    private userService: UserService,
    private addonService: AddonService,
    private device: UserDeviceService,
  ) {

    this.userEmailForm = new FormGroup(
      {
        email: new FormControl(this.authService.currentUser.details.email)
      }
    );

    this.userDetailsForm = new FormGroup({
      firstName: new FormControl(this.authService.currentUser.details.firstName),
      lastName: new FormControl(this.authService.currentUser.details.lastName),
      address: new FormControl(this.authService.currentUser.details.address),
      company: new FormControl(this.authService.currentUser.details.company)
    });

    this.security = new FormGroup({
      currentPassword: new FormControl(),
      newPassword: new FormControl(),
      confirmPassword: new FormControl()
    });
  }

  ngOnInit(): void {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    this.userService.getUserStatistics(this.authService.currentUser.username).subscribe(resp => {
      if (resp) {
        this.teamMembersStatistics = resp.organizations;
      }
    });
  }

  ngAfterViewInit() {
    const activeTab = this.route.snapshot.queryParamMap.get('tab1');
    const activePlanType = this.route.snapshot.queryParamMap.get('tab2');
    const selectedPlanName = this.route.snapshot.queryParamMap.get('selected_plan');
    if (activeTab) {
      this.tabGroup.selectedIndex = Number(activeTab);
    }
    if (activePlanType) {
      this.typeOfPlan = Number(activePlanType);
    }

    if (selectedPlanName) {
      if (!activeTab) {
        this.tabGroup.selectedIndex = 1;
      }
      this.setupPlanPurchase(selectedPlanName);
    }
  }

  changeEmail(): void {
    this.loaderService.showLoading();
    if (this.userEmailForm.valid) {
      if (this.userEmailForm.value.email === this.authService.currentUser.details.email) {
        this.notificationService.error('Please update the email in order to change it');
        this.loaderService.hideLoading();
      } else {
        this.authService.updateUserEmail(this.userEmailForm.value.email).then(() => {
          this.loaderService.hideLoading();
        }, err => {
          this.notificationService.error(err.message);
          this.loaderService.hideLoading();
        });
      }
    }
  }

  updateUserDetails(): void {
    this.loaderService.showLoading();
    if (this.userDetailsForm.valid) {
      const data = this.userDetailsForm.value;
      if (data.firstName === this.authService.currentUser.details.firstName && data.lastName === this.authService.currentUser.details.lastName && data.address === this.authService.currentUser.details.address && data.company === this.authService.currentUser.details.company) {
        this.notificationService.error('Please update the fields in order to change your personal data');
        this.loaderService.hideLoading();
      } else {
        this.authService.updateUserData(data.firstName, data.lastName, data.address, data.company).then(res => {
          this.loaderService.hideLoading();
        });
      }
    } else {
      console.log(this.userDetailsForm);
      this.notificationService.error('Please provide data in all fields in order to update your user details');
      this.loaderService.hideLoading();
    }
  }

  updatePassword(): void {
    this.loaderService.showLoading();
    if (this.security.valid) {
      if (this.security.value.newPassword === this.security.value.confirmPassword) {
        this.authService.changePassword(this.security.value.currentPassword, this.security.value.newPassword)
          .then(() => {
            this.loaderService.hideLoading();
          });
      } else {
        this.notificationService.error('The passwords don\'t match');
        this.loaderService.hideLoading();
      }
    } else {
      this.notificationService.error('Please provide valid data in order to update password');
      this.loaderService.hideLoading();
    }
  }

  selectPlan(plan: Plan): void {
    const paymentPopup = this.dialog.open(RecurlyComponent, {
      width: this.deviceType === 1 ? '50%' : '90%',
      panelClass: 'popup-container',
      data: {plan: plan, billingData: this.userBillingInfo, buyingAddon: false}
    });

    paymentPopup.afterClosed().subscribe(res => {
      if (res && res.success) {
        if (!this.planActiveImmediately(this.authService.userPlan, plan)) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'popup-container',
            data: {
              header: 'Plan change successful',
              message: `Thank you for purchasing ${plan.plan_name} plan. However, your current plan '${this.authService.userPlan.plan.plan_name}' is still active and you can continue using its features untill the billing period ends. You will be switched to ${plan.plan_name} plan automatically after ${this.datePipe.transform(this.authService.userPlan.expired_at, 'MMM/dd/yyy')}`,
              noCancelButton: true,
            }
          });
        }
        this.authService.getSubscription();
      }
      if (sessionStorage.getItem('selected_plan')) {
        sessionStorage.removeItem('selected_plan');
      }
    });
  }

  setupPlanPurchase(planCode: string): void {
    this.subPlansService.getPlanByCode(planCode).subscribe(plan => {
      if (plan) {
        this.selectPlan(plan);
      }
    });
  }

  planActiveImmediately(currentPlan: UserSubscription, pendingPlan: Plan): boolean {
    if (currentPlan.plan.plan_name === 'Free') {
      return true;
    } else if (currentPlan.plan.price <= pendingPlan.price) {
      return true;
    } else if (currentPlan.plan.price > pendingPlan.price) {
      return false;
    }
  }

  onTabChanged(event: any): void {
    if (event.index === 1) {
      this.loadBillingInfo();
      if (this.yearlyPlans && this.monthlyPlans && this.yearlyPlans.length > 0 && this.monthlyPlans.length > 0) {
        // do nothing
      } else {
        this.plansSubscription = this.subPlansService.getAllPlans().subscribe(data => {
          if (data) {
            for (const d of data) {
              if (d.description.includes('yearly')) {
                this.yearlyPlans.push(d);
              } else {
                this.monthlyPlans.push(d);
              }
            }
          }
        });
      }
      if (this.addons && this.addons.length === 0) {
        this.addonService.getAllAddons().subscribe((addonList: Addon[]) => {
          if (addonList) {
            this.addons = addonList;
          }
        });
      }
    }
    if (event.index === 2) {
      if (!this.invoiceDetails) {
        this.subPlansService.getInvoicesByUserId(this.authService.currentUser.username).subscribe(data => {
          this.invoiceDetails = data;
        });
      }
    } else if (event.index === 3) {
      this.loadBillingInfo();
    }
  }

  cancelSubscription(): void {
    const confirm = this.dialog.open(CancelSubscriptionPopupComponent, {
      width: '50%',
      panelClass: 'popup-container'
    });
    confirm.afterClosed().subscribe(res => {
      if (res && res.success) {
        const body = {
          user_id: this.authService.currentUser.username,
          notes: res.reasons
        };
        this.subPlansService.cancelSubscription(this.authService.userPlan.subscription_id, body).subscribe(resp => {
          if (resp && resp.success) {
            this.authService.getSubscription();
          }
        }, err => {
          console.log(err);
        });
      }
    });
  }

  downloadInvoice(planName: string, id: string): void {
    this.subPlansService.getInvoiceById(id).subscribe(invoice => {
      if (invoice && invoice.content) {
        const linkSource = 'data:application/pdf;base64,' + invoice.content;
        const downloadLink = document.createElement('a');
        const fileName = `Credenshare_Invoice_${planName}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        this.notificationService.error('We couldn\'t download your invoice at the moment. Please try again later', 'Error');
      }
    });
  }

  deleteAccount(): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'popup-container',
      data: {header: 'Delete account', message: 'Are you sure you want to proceed?'},
      width: '50%'
    });

    confirm.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.userService.deleteUserById(this.authService.currentUser.username).subscribe(res => {
          if (res.success) {
            this.authService.logout().then(logoutRes => {
              if (logoutRes.success) {
                this.router.navigate(['/login']);
              }
            });
          }
        });
      }
    });
  }


  purchaseAddon(addonData: Addon): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '450px',
      data: {
        header: 'Purchase Secure Share Pack',
        message: `You are about to purchase ${addonData.addon_name} for $${addonData.price} through your preferred billing method. <br /> Do you wish to proceed?`,
        noCancelButton: true,
        buttonMessage: `Pay $${addonData.price}`
      },
      panelClass: 'popup-container'
    });

    confirm.afterClosed().subscribe(res => {
      if (res) {
        const body = {
          addon_name: addonData.addon_name,
          addon_code: addonData.addon_code,
          description: addonData.description,
          is_enabled: addonData.is_enabled,
          features: addonData.features,
          currency: 'USD'
        };

        if (this.userBillingInfo) {
          this.userService.purchaseAddon(this.authService.currentUser.username, body).subscribe(() => {
            this.authService.getSubscription();
          });
        } else {
          const recurlyDialog = this.dialog.open(RecurlyComponent, {
            width: '50%',
            panelClass: 'popup-container',
            data: {plan: body, billingData: this.userBillingInfo, buyingAddon: true}
          });
          recurlyDialog.afterClosed().subscribe(() => {
            this.authService.getSubscription();
          });
        }
      }
    });
  }

  changePlanType(num: number): void {
    this.typeOfPlan = num;
  }

  loadBillingInfo(): void {
    if (!this.userBillingInfo) {
      this.userService.getUserBillingInfo(this.authService.currentUser.username, false).subscribe((billingInfo: UserBillingInfo) => {
        this.userBillingInfo = billingInfo;
      }, err => {
        if (err.error.error_code !== 36) {
          this.notificationService.error(err.error.message, 'Error');
        }
      });
    }
  }

  paymentLogo(cardType: string): string {
    switch (cardType.toLowerCase()) {
      case 'visa':
        return 'visa.svg';
      case 'mastercard':
        return 'mastercard.svg';
      case 'american express':
        return 'amex.svg';
      case 'jcb':
        return 'jcb.svg';
    }
    return null;
  }

  updateBillingInfoDialog(): void {
    const paymentPopup = this.dialog.open(RecurlyComponent, {
      width: this.deviceType === 1 ? '50%' : '90%',
      panelClass: 'popup-container',
      data: {addCardOnly: true}
    });

    paymentPopup.afterClosed().subscribe(res => {
      console.log(res);
      if (res) {
        this.userBillingInfo = res;
      }
    });
  }

  async resendVerificationEmail(): Promise<void> {
    this.loaderService.showLoading();
    await this.authService.resendUpdatedEmailVerification();
    this.loaderService.hideLoading();
  }

  deleteBillingInfoDialog(): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '50%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {
        header: 'Remove Billing Info',
        message: 'Removing your billing information may prevent the renewal of your subscription for the next term? Please consider before proceeding.'
      }
    });

    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        this.userService.deleteUserBillingInfo(this.authService.currentUser.username).subscribe(res => {
          if (res.success) {
            this.userBillingInfo = null;
            this.authService.getSubscription();
          }
        });
      }
    });
  }
}
