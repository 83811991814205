<div [ngStyle]="{'width': wide ? '100%' : '50%'}" class="user-plan-container" *ngIf="planData">
  <div class="name-and-price">
    <h3>{{planData.plan.description}}</h3>
    <div class="price-container" *ngIf="!wide">
      <span class="price-element">{{planData.plan.price === 0 ? 'Free' : '$' + '' + planData.plan.price}}</span>
      <span *ngIf="planData.plan.price !== 0" class="rate-element">{{planData.plan.paymentRate}}</span>
    </div>
  </div>
  <p class="description">
    <span *ngIf="this.maxShares !== -1">
      Your current plan allows you
      {{this.maxShares === -1 ? 'unlimited number of' : 'a maximum of ' + this.maxShares}} shares {{planData.plan.price === 0 ? 'per day' : 'monthly'}} with
      {{this.maxFields === '-1' ? 'unlimited number of' :
      this.maxFields}} sharing fields.
      <br/>
    </span>
    <ng-container *ngIf="planData.descriptiveFeatures && planData.descriptiveFeatures.length > 0 && !wide">
      This subscription also features:
      <span *ngFor="let f of planData.descriptiveFeatures; let z = last; let i = index">
                <span *ngIf="planData.descriptiveFeatures.length - 1 === i && planData.descriptiveFeatures.length > 1">
                    and </span>
        {{f}}<span *ngIf="!z && planData.descriptiveFeatures.length - 2 !== i">, </span>
            </span>
    </ng-container>
  </p>
  <div class="plan-stats" *ngIf="!wide && teamMembersStatistics && teamMembersStatistics.total_confirmed_members > 0" [ngStyle]="{'flex-direction': 'row'}">
    <div class="daily-shares price-container">
      <span class="plan-text-bold">{{this.teamMembersStatistics.total_confirmed_members}} active team members</span>
      <span class="plan-text-transparent price-element">${{this.teamMembersStatistics.current_payment}}</span>
    </div>
  </div>
  <div class="plan-renewal" style="flex-direction: row;" *ngIf="planData.expired_at">
    <div class="failed-shares" *ngIf="!wide">
      <span class="plan-text-bold">{{planData.expired_at | date: 'MMM dd, yyy'}}</span>
      <span class="plan-text-transparent">Expiration date</span>
    </div>
    <!-- <div class="auto-renewal" *ngIf="!cancellingSubscription">
        <input type="checkbox" [(ngModel)]="planData.auto_renew" />
        <label>Auto Renewal</label>
        <button style="width: 150px;" class="button-default">Renew</button>
    </div> -->
  </div>
  <div class="plan-stats" [ngStyle]="{'flex-direction': cancellingSubscription ? 'row' : 'row-reverse'}">
    <div class="daily-shares">
      <span class="plan-text-bold">{{planData.plan.max_shares === -1 ? 'Unlimited' : planData.plan.max_shares}}</span>
      <span class="plan-text-transparent">{{planData.plan.price === 0 ? 'Daily' : 'Monthly'}} Shares</span>
    </div>
    <div class="remaining-shares" *ngIf="maxAddonShares && !cancellingSubscription">
      <span class="plan-text-bold">{{maxAddonShares}}</span>
      <span class="plan-text-transparent">Add-on Shares</span>
    </div>
    <div class="remaining-shares" *ngIf="!cancellingSubscription">
      <span class="plan-text-bold">{{planData.plan.max_shares === -1 ? 'Unlimited' : planData.remaining_shares}}</span>
      <span class="plan-text-transparent">Remaining Shares</span>
    </div>
  </div>
  <mat-progress-bar mode="determinate" *ngIf="!cancellingSubscription"
                    [value]="calculateShares(planData.plan.max_shares + maxAddonShares, planData.shares_created)">
  </mat-progress-bar>
  <button *ngIf="!cancellingSubscription && wide && !planData.plan.plan_code.includes('enterprise')" class="button-green wide-button-class" (click)="buyMoreShares()">Buy More</button>
</div>
