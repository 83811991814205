import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe, JsonPipe} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';

import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSidenavModule} from '@angular/material/sidenav';

import {AppComponent} from './app.component';
import {LoaderComponent} from './shared/loader/loader.component';
import {RequireAuthGuard} from './shared/require-auth.component';
import {HomeComponent} from './views/home/home.component';
import {LoginComponent} from './views/login/login.component';
import {RegisterComponent} from './views/register/register.component';
import {NotFoundComponent} from './views/not-found/not-found.component';
import {HeaderInterceptor} from './shared/header-interceptor';
import {SideBarComponent} from './shared/side-bar/side-bar.component';
import {ConfirmRegisterComponent} from './views/confirm-register/confirm-register.component';
import {ConfirmPasswordComponent} from './views/confirm-password/confirm-password.component';
import {ResetPasswordComponent} from './views/reset-password/reset-password.component';
import {SharesComponent} from './views/shares/shares.component';
import {TemplatesComponent} from './views/templates/templates.component';
import {ResetRegistrationComponent} from './views/reset-registration/reset-registration.component';
import {SuccessComponent} from './shared/success/success.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {DesktopTopBarComponent} from './shared/top-bar/desktop-top-bar.component';
import {MobileTopBarComponent} from './shared/top-bar/mobile-top-bar.component';
import {UserAccountComponent} from './views/user-account/user-account.component';
import {CreateSharePopupComponent} from './shared/create-share-popup/create-share-popup.component';
import {InputPopupComponent} from './shared/input-popup/input-popup.component';
import {ShareViewComponent} from './views/share-view/share-view.component';
import {ShareProtectionPopupComponent} from './shared/share-protection-popup/share-protection-popup.component';
import {ClipboardModule} from 'ngx-clipboard';
import {PaginationComponent} from './shared/pagination/pagination.component';
import {ConfirmPopupComponent} from './shared/confirm-popup/confirm-popup.component';
import {RecurlyComponent} from './shared/recurly/recurly.component';
import {UserPlanComponent} from './shared/user-plan/user-plan.component';
import {CancelSubscriptionPopupComponent} from './shared/cancel-subscription-popup/cancel-subscription-popup.component';
import {NotificationComponent} from './shared/notification/notification.component';
import {TermsAndServicesComponent} from './shared/terms-and-services-popup/terms-and-services-popup.component';
import {PasswordStrengthComponent} from './shared/password-strength/password-strength.component';
import {MatNativeDateModule} from '@angular/material/core';
import {QuickShareComponent} from './shared/quick-share/quick-share.component';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {environment} from 'src/environments/environment';
import {ExpirationDateTextPipe} from './pipes/expiration-date-text.pipe';
import {SlackAuthComponent} from './views/slack-auth/slack-auth.component';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import {TeamsOverviewComponent} from './views/my-teams/teams-overview/teams-overview.component';
import {AvatarModule} from 'ngx-avatar';
import {CreateTeamComponent} from './views/my-teams/dialogs/create-team/create-team.component';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AddMemberComponent} from './views/my-teams/dialogs/add-member/add-member.component';
import {ViewTeamComponent} from './views/my-teams/view-team/view-team.component';
import {EditTeamComponent} from './views/my-teams/dialogs/edit-team/edit-team.component';
import {MatMenuModule} from '@angular/material/menu';
import {TeamInviteConfirmComponent} from './views/my-teams/team-invite-confirm/team-invite-confirm.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {QmShareViewComponent} from './views/share-view/qm/share-view.component';
import {DefaultShareViewComponent} from './views/share-view/default/share-view.component';
import {QmRequestSubmissionComponent} from './views/secure-requests/request-submission/qm/request-submission.component';
import {DefaultRequestSubmissionComponent} from './views/secure-requests/request-submission/default/request-submission.component';
import {RequestsOverviewComponent} from './views/secure-requests/requests-overview/requests-overview.component';
import {ViewRequestComponent} from './views/secure-requests/view-request/view-request.component';
import {RequestSubmissionComponent} from './views/secure-requests/request-submission/request-submission.component';
import {
  SecureRequestProtectionComponent
} from './views/secure-requests/dialogs/secure-request-protection/secure-request-protection.component';
import {NewEditRequestComponent} from './views/secure-requests/dialogs/new-edit-request/new-edit-request.component';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {NotificationBadgeComponent} from './shared/notification-badge/notification-badge.component';
import {BmShareViewComponent} from './views/share-view/bm/share-view.component';
import {BmRequestSubmissionComponent} from './views/secure-requests/request-submission/bm/request-submission.component';
import {SecurePasteComponent} from './views/secure-paste/secure-paste.component';
import {MarkdownModule} from 'ngx-markdown';
import {ExpiresInComponent} from './shared/share-settings/expires-in/expires-in.component';
import {NumberOfViewComponent} from './shared/share-settings/number-of-view/number-of-view.component';
import {PasswordToViewComponent} from './shared/share-settings/password-to-view/password-to-view.component';
import {MiscComponent} from './shared/share-settings/misc/misc.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';

export const ROUTES: Routes = [
  {
    path: '',
    component: SideBarComponent,
    canActivate: [RequireAuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {animation: 'top', title: 'Dashboard - CredenShare', screenTitle: 'Dashboard'}
      },
      {
        path: 'shares',
        component: SharesComponent,
        data: {animation: 'top', title: 'SecureShares - CredenShare', screenTitle: 'Shares'}
      },
      {
        path: 'secure-requests',
        component: RequestsOverviewComponent,
        data: {animation: 'top', title: 'SecureShares - CredenShare', screenTitle: 'SecureRequest'}
      },
      {
        path: 'secure-requests/:short-code',
        component: ViewRequestComponent,
        data: {animation: 'top', title: 'SecureShares - CredenShare', screenTitle: 'View Secure Request'}
      },
      {
        path: 'my-teams',
        component: TeamsOverviewComponent,
        data: {animation: 'top', title: 'My Teams - CredenShare', screenTitle: 'My Teams'},
      },
      {
        path: 'my-teams/:team-id',
        component: ViewTeamComponent,
        data: {animation: 'top', title: 'View Team - CredenShare', screenTitle: 'View Team'},
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        data: {animation: 'top', title: 'Templates - CredenShare', screenTitle: 'Templates'}
      },
      {
        path: 'user-account',
        component: UserAccountComponent,
        data: {animation: 'top', title: 'My Account - CredenShare', screenTitle: 'User Profile'}
      },
      {
        path: 'secure-paste',
        component: SecurePasteComponent,
        data: {animation: 'top', title: 'SecurePaste - CredenShare', screenTitle: 'SecurePaste', isPublic: false}
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {animation: 'top', title: 'Login - CredenShare'}
  },
  {
    path: 'login/from-share/:short-code',
    component: LoginComponent,
    data: {animation: 'top', title: 'Login - CredenShare'}
  },
  {
    path: 'login/from-secure-request/:short-code',
    component: LoginComponent,
    data: {animation: 'top', title: 'Login - CredenShare'}
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {title: 'Register and Start Sharing Securely - CredenShare'}
  },
  {
    path: 'confirm',
    component: ConfirmRegisterComponent,
    data: {title: 'Confirm your account - CredenShare'}
  },
  {
    path: 'confirm-password',
    component: ConfirmPasswordComponent,
    data: {title: 'Confirm your password  - CredenShare'}
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {title: 'Reset your Password - CredenShare'}
  },
  {
    path: 'reset-registration',
    component: ResetRegistrationComponent,
    data: {title: 'Reset your registration - CredenShare'}
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: {title: '404 - CredenShare'}
  },
  {
    path: 'slack-auth',
    component: SlackAuthComponent,
    data: {title: 'Slack Authorization - CredenShare'}
  },
  {
    path: 'secure-paste-public',
    component: SecurePasteComponent,
    data: {title: 'Secure Paste - CredenShare', isPublic: true}
  },
  {
    path: 'team-invite-confirm',
    canActivate: [RequireAuthGuard],
    component: TeamInviteConfirmComponent,
    data: {title: 'Team invite confirmation - CredenShare'}
  },
  {
    path: 'embed/r/:short-code',
    component: RequestSubmissionComponent,
    data: {animation: 'top', title: 'SecureRequest - CredenShare', isEmbed: true}
  },
  {
    path: 'r/:short-code',
    component: RequestSubmissionComponent,
    data: {animation: 'top', title: 'SecureRequest - CredenShare'}
  },
  {
    path: 'embed/:short-code',
    component: ShareViewComponent,
    data: {animation: 'top', title: 'SecureRequest - CredenShare', isEmbed: true}
  },
  // THIS ROUTE SHOULD ALWAYS STAY AT THE BOTTOM SO THE APP IS ABLE TO COMPILE THE ROUTES PROPERLY
  {
    path: ':short-code',
    component: ShareViewComponent,
    data: {animation: 'top', title: 'SecureShare View - CredenShare'}
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    SideBarComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NotFoundComponent,
    ConfirmRegisterComponent,
    LoginComponent,
    ResetPasswordComponent,
    SharesComponent,
    TemplatesComponent,
    ResetRegistrationComponent,
    ConfirmPasswordComponent,
    SuccessComponent,
    DesktopTopBarComponent,
    MobileTopBarComponent,
    UserAccountComponent,
    CreateSharePopupComponent,
    InputPopupComponent,
    ShareViewComponent,
    QmShareViewComponent,
    DefaultShareViewComponent,
    ShareProtectionPopupComponent,
    PaginationComponent,
    ConfirmPopupComponent,
    RecurlyComponent,
    UserPlanComponent,
    CancelSubscriptionPopupComponent,
    NotificationComponent,
    TermsAndServicesComponent,
    PasswordStrengthComponent,
    QuickShareComponent,
    ExpirationDateTextPipe,
    SlackAuthComponent,
    TeamsOverviewComponent,
    CreateTeamComponent,
    AddMemberComponent,
    ViewTeamComponent,
    EditTeamComponent,
    TeamInviteConfirmComponent,
    RequestsOverviewComponent,
    ViewRequestComponent,
    NewEditRequestComponent,
    RequestSubmissionComponent,
    SecureRequestProtectionComponent,
    NotificationBadgeComponent,
    QmRequestSubmissionComponent,
    DefaultRequestSubmissionComponent,
    BmShareViewComponent,
    BmRequestSubmissionComponent,
    SecurePasteComponent,
    ExpiresInComponent,
    NumberOfViewComponent,
    PasswordToViewComponent,
    MiscComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    AmplifyUIAngularModule,
    OverlayModule,
    MatTabsModule,
    MatDialogModule,
    MatSliderModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSidenavModule,
    ClipboardModule,
    AvatarModule,
    RouterModule.forRoot(ROUTES),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
    }),
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    DragDropModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MarkdownModule.forRoot(),
    MatExpansionModule,
    CodemirrorModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    RequireAuthGuard,
    JsonPipe,
    DatePipe,
    HomeComponent,
    SharesComponent,
    QuickShareComponent,
    {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {siteKey: environment.captchaSiteKey} as RecaptchaSettings
    },
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
