import {Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {Subject} from 'rxjs';
import {TeamsService} from '../../../services/teams.service';
import {AuthService} from '../../../services/auth.service';
import {Team} from '../../../models/team.model';
import {UserDeviceService} from '../../../services/user-device.service';
import {ConfirmPopupComponent} from '../../../shared/confirm-popup/confirm-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {CreateTeamComponent} from '../dialogs/create-team/create-team.component';
import {AddMemberComponent} from '../dialogs/add-member/add-member.component';
import {debounceTime} from 'rxjs/internal/operators';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-teams-overview',
  templateUrl: './teams-overview.component.html',
  styleUrls: ['./teams-overview.component.scss']
})
export class TeamsOverviewComponent implements OnInit {

  teams: Team[] = new Array<Team>();
  searchValue = new Subject<string>();
  noSearchResults: boolean;
  deviceType: number;
  hasAccess: boolean;

  constructor(
    private dataService: DataService,
    private teamsService: TeamsService,
    private authService: AuthService,
    private device: UserDeviceService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    this.getTeams(null);
    this.searchValue.pipe(debounceTime(500)).subscribe(data => {
      const params = new HttpParams().append('filter', data);
      this.getTeams(params, true);
    });

    this.teamsService.hasAccessToTeamFeature(this.authService.currentUser.username).then(res => this.hasAccess = res);
  }

  filtering(event): void {
    this.searchValue.next(event.target.value);
  }

  getTeams(params: HttpParams, isUserSearching?: boolean): void {
    this.teamsService.getTeamsByUserId(this.authService.currentUser.username, params).subscribe(data => {
      if (data && data.length > 0) {
        this.teams = data;
        this.noSearchResults = !isUserSearching;
      } else {
        this.teams = new Array<Team>();
        this.noSearchResults = isUserSearching;
      }
    });
  }

  deleteTeam(teamId: string, index: number): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: (this.deviceType === 1) ? '70%' : '100%',
      maxWidth: (this.deviceType === 1) ? '70%' : '100%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete team', message: 'Are you sure you want to delete this team?'}
    });

    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        this.teamsService.deleteTeamById(teamId).subscribe(res => {
          if (res) {
            this.teams.splice(index, 1);
          }
        });
      }
    });
  }

  showCreateTeamDialog(): void {
    const createTeamDialogRef = this.dialog.open(CreateTeamComponent, {
      width: (this.deviceType === 1) ? '70%' : '100%',
      maxWidth: (this.deviceType === 1) ? '70%' : '100%',
      height: 'fit-content',
      panelClass: 'popup-container',
    });

    createTeamDialogRef.componentInstance.newTeamEvent.subscribe(event => {
      this.getTeams(null);
    });
  }

  showAddMemberDialog(teamIndex: number): void {
    const addMemberDialogRef = this.dialog.open(AddMemberComponent, {
      width: (this.deviceType === 1) ? '70%' : '100%',
      maxWidth: (this.deviceType === 1) ? '70%' : '100%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {
        team: this.teams[teamIndex],
        emitEventOnly: false,
      },
    });

    addMemberDialogRef.componentInstance.newMemberEvent.subscribe(resp => {
      if (resp) {
        this.getTeams(null);
      }
    });
  }

  isOwner(teamIndex: number): boolean {
    if (this.teams[teamIndex].users && this.teams[teamIndex].users.length > 0) {
      for (const u of this.teams[teamIndex].users) {
        if (u.role === 'owner' && this.authService.currentUser.username === u.user_id) {
          return true;
        }
      }
    }
    return false;
  }
}
