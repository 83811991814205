import {Component} from '@angular/core';
import {ShareViewComponent} from '../share-view.component';

@Component({
  selector: 'app-qm-share-view',
  templateUrl: './share-view.component.html',
  styleUrls: ['./share-view.component.scss']
})
export class QmShareViewComponent extends ShareViewComponent {


}
