<div class="popup-wrapper">
    <div class="logo">
      <img *ngIf="this.data.template === 'default'" src="/assets/Assets Website/Logo/Credenshare.svg" />
      <img *ngIf="this.data.template === 'qm'" src="https://quotemedia.com/assets/img/quotemedia_large_company_logo.png"/>
      <img *ngIf="this.data.template === 'bm'" src="/assets/Assets Website/Logo/bravenet.png"/>
    </div>
    <p class="title">
        <span class="title-header">Privacy Screen:</span> <br /> Make sure you are in a secure location
        <br />
        <span *ngIf="data.info.password_protected" class="smaller-text">Enter password to get the access to shared credentials</span>
        <span class="smaller-text" *ngIf="data.info.timed_view">This secure share is on a timed view.<br /> You have {{data.timed_view}} seconds to copy and save it</span>
        <span class="smaller-text" *ngIf="data.info.view_protected_message">{{data.info.view_protected_message}}</span>
    </p>
    <div class="input-and-button">
        <input *ngIf="data.info.password_protected" class="password-text" [(ngModel)]="password" placeholder="Password" />
        <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>
        <button (click)="close()" class="{{this.data.template === 'default' ?  'button-default' : 'button-default-qm'}} view-credentials">View credentials</button>
    </div>
    <p class="attempts" *ngIf="data.info.password_protected && data.info.attempts_left">
        Failed attempts tolerance before deletion
        <br />
        <span [ngStyle]="{'color': data.info.attempts_left <= 3 ? 'var(--amplify-red)' : 'initial'}">{{data.info.attempts_left}}
            {{data.info.attempts_left === 1 ? 'attempt' : 'attempts'}} left</span>
    </p>
</div>
