import {Component, EventEmitter, Output} from '@angular/core';
import {DesktopTopBarComponent} from './desktop-top-bar.component';

@Component({
  selector: 'app-mobile-top-bar',
  templateUrl: './mobile-top-bar.component.html',
  styleUrls: ['./mobile-top-bar.component.scss']
})
export class MobileTopBarComponent extends DesktopTopBarComponent {
  @Output('menuOpened') menuOpened = new EventEmitter();

  emitMenuOpened() {
    this.menuOpened.emit(true);
  }
}
