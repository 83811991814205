<section class="settings-group" *ngIf="userPlan && userPlan.plan.features[3].feature_value === 'true'">
  <div class="setting">
    <div class="label checkbox-label">
      <input type="checkbox" [formControl]="askPassword"/>
      <label> Ask password to view <br/>
        <p class="label-instructions">When you are ready to view the password and ensured that you are
          in a
          secure location, please click on the View Button</p>
      </label>
    </div>
    <div class="slider">
      <div class="share-password-input"
           [ngClass]="passwordField.errors?.required && passwordField.touched ? 'red-border' : ''">
        <input placeholder="Type a password for protection" id="password-input"
               (keyup)="checkSecurity($event)" [formControl]="passwordField" maxlength="100"
               autocomplete="new-password" [ngClass]="!showPassword ? 'password-text' : 'regular-text'"/>

        <img (click)="showPassword = !showPassword"
             [src]="!showPassword ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'"/>
      </div>
    </div>
  </div>
  <div class="setting">
    <div class="label"></div>
    <div class="slider">
      <app-password-strength [score]="generalPasswordStrength?.passwordScore"
                             [strength-data]="generalPasswordStrength?.passwordStrength"></app-password-strength>
    </div>
  </div>
  <div class="setting">
    <div class="label checkbox-label">
      <input type="checkbox"  [formControl]="failedAttemptsToleranceEnabled"/>
      <label>Failed attempts tolerance before deletion</label>
    </div>
    <div class="slider">
      <span class="slider-label">1</span>
      <mat-slider [(ngModel)]="failedAttemptsValue" [disabled]="!failedAttemptsToleranceEnabled.value"
                  min="1" max="10" step="1" thumbLabel="true"></mat-slider>
      <span class="slider-label">10</span>
    </div>
  </div>
  <div class="setting" *ngIf="askPassword.value">
    <div class="label"></div>
    <div class="slider">
      <p class="expire-note">
        This secure share will be deleted after {{failedAttemptsValue}} times of entering wrong password
      </p>
    </div>
  </div>
  <div class="setting" *ngIf="userPlan && userPlan.plan.features[4].feature_value === 'true'">
    <div class="label checkbox-label">
      <input type="checkbox" [formControl]="secureViewPromptEnabled"/>
      <label>Secure view prompt</label>
    </div>
    <div class="slider">
        <textarea placeholder="Type a custom message for the secure view prompt"
                  [formControl]="secureViewMessage"  class="secure-view-message" draggable="false"></textarea>
    </div>
  </div>
</section>
