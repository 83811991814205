import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserSubscription} from 'src/app/models/plan.model';
import {AuthService} from 'src/app/services/auth.service';
import {TeamMembersStatistics} from '../../models/user.model';
import {calculateMaxSharesAddon} from '../shares-utils';

@Component({
  selector: 'app-user-plan',
  templateUrl: './user-plan.component.html',
  styleUrls: ['./user-plan.component.scss']
})
export class UserPlanComponent implements OnChanges, OnInit {
  @Input('planData') public planData: UserSubscription;
  @Input('teamMembersStatistics') public teamMembersStatistics: TeamMembersStatistics;
  @Input('cancellingSubscription') public cancellingSubscription: boolean;
  @Input('wide') public wide: boolean;

  maxAddonShares: number = 0;
  maxFields: string = null;
  maxShares: number = null;

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.reloadPlanInfo();
  }

  ngOnChanges(): void {
    this.reloadPlanInfo();
  }

  calculateShares(total: number, used: number) {
    return 100 - Math.round((used / total) * 100);
  }

  buyMoreShares() {
    this.router.navigate(['user-account'], {queryParams: {tab1: 1, tab2: 2}});
  }

  reloadPlanInfo(): void {
    if (this.planData && this.planData.addons) {
      this.maxAddonShares = calculateMaxSharesAddon(this.planData.addons);
    }

    this.maxFields = this.planData.plan.features.find(f => f.feature_code === 'max_fields').feature_value;
    this.maxShares = this.planData.plan.max_shares;
  }

  protected readonly calculateMaxSharesAddon = calculateMaxSharesAddon;
}
