<div class="page-container">
    <div class="purple-header">
        <div class="image-container">
            <img src="/assets/Assets Website/Logo/Credenshare-white.svg" />
        </div>
        <div class="login-form" *ngIf="!passReset; else resetSuccessful">
            <p class="form-title">
                Reset Password
            </p>
            <div class="inputs-container">
                <input type="email" placeholder="Registration Email" [(ngModel)]="email" required/>
                <span class="error" *ngIf="error">{{error}}</span>
                <button class="signin-button" (click)="resetPassword()">Reset Password</button>
            </div>
        </div>
        <p class="login-copyright">&copy; {{authService.currentYear}} CredenShare. All rights reserved.</p>
    </div>
</div>

<ng-template #resetSuccessful>
    <app-success title="Password Reset" message="We have sent you the email with the instruction how to reset your password." imageUrl="/assets/Assets Website/Icn/sent email.svg"></app-success>
</ng-template>
