export class LoginUser {
  email: string;
  password: string;
  agree: boolean;
}

export class RegisterUser {
  email: string;
  password: string;
  confirmPassword: string;
  address: string;
  firstName: string;
  lastName: string;
  company: string;
}

export class UserDetails {
  username: string;
  accessToken: string;
  details: Attributes;

  constructor(username, attributes, accessToken) {
    this.username = username;
    this.accessToken = accessToken;
    this.details = new Attributes(attributes);
  }
}

export class Attributes {
  email: string;
  emailVerified: boolean;
  address: string;
  firstName: string;
  lastName: string;
  tos_agreed: boolean;

  company: string;

  constructor(attributes) {
    if (attributes.email) {
      this.email = attributes.email;
    }

    if (attributes.email_verified) {
      this.emailVerified = attributes.email_verified;
    }

    if (attributes.address) {
      if (attributes.address.formatted) {
        this.address = attributes.address.formatted;
      } else {
        this.address = attributes.address;
      }
    }

    if (attributes.given_name) {
      this.firstName = attributes.given_name;
    }

    if (attributes.family_name) {
      this.lastName = attributes.family_name;
    }

    if (attributes["custom:company"]) {
      this.company = attributes["custom:company"];
    }
  }
}

export class UserData {
  user_id: string;
  recurly_code: string;
  tos_agreed: string;
  tos_agreement_date: string;
  email: string;
  configurations: string;
}

export class UserStatistics {
  number_of_shares: number;
  expired_shares: number;
  total_viewed_shares: number;
  failed_shares: number;
  organizations: TeamMembersStatistics;
}

export class TeamMembersStatistics {
  total_confirmed_members: number;
  total_pending_members: number;
  current_payment: number;
}

export class UserBillingInfo {
  id: string;
  company: string;
  first_name: string;
  last_name: string;
  payment_method: UserPaymentMethod;
  address: UserBillingAddress;
}

export class UserPaymentMethod {
  account_type: string;
  billing_agreement_id: string;
  card_type: string;
  exp_month: number;
  exp_year: number;
  first_six: string;
  gateway_code: string;
  gateway_token: string;
  last_four: string;
  name_on_account: string;

}

export class UserBillingAddress {
  city: string;
  country: string;
  first_name: string;
  last_name: string;
  phone: string;
  postal_code: string;
  region: string;
  street1: string;
  street2: string;
}
