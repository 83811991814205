import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {AuthService} from '../../services/auth.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-terms-and-services-popup',
  templateUrl: './terms-and-services-popup.component.html',
  styleUrls: ['./terms-and-services-popup.component.scss']
})
export class TermsAndServicesComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public dialogRef: MatDialogRef<TermsAndServicesComponent>
  ) {
  }

  ngOnInit(): void {
  }

  confirm(): void {
    const body = {
      tos_agreed: true,
      tos_agreement_date: moment().toISOString(),
      email: this.authService.currentUser.details.email
    };
    this.userService.updateUserById(this.authService.currentUser.username, body).subscribe(res => {
      if (res) {
        this.dialogRef.close(true);
      }
    });
  }
}
