<div class="popup-header">
    <h3>Payment</h3>
    <div (click)="close()" class="close-icon">
        <img style="cursor: pointer;" src="/assets/Assets Website/Btn/close.svg">
    </div>
</div>

<ng-container *ngIf="data.billingData && showBillingData">
    <div class="billing-data-container">
        <div class="card-container">
            <span>Pay with {{data.billingData.payment_method.card_type}}</span>
            <span class="credit-card-name">{{data.billingData.first_name}} {{data.billingData.last_name}}</span>
            <span>{{data.billingData.payment_method.first_six}}******{{data.billingData.payment_method.last_four}}</span>
            <span>{{data.billingData.payment_method.exp_month}}/{{data.billingData.payment_method.exp_year}}</span>
            <button class="button-default" (click)="payWithCreditCard(true)">Pay ${{data.plan.price}}</button>
        </div>
        <div class="separator">- or -</div>
        <div class="new-card-container">
            <span>Select a new payment method</span>
            <button class="button-default" (click)="newCreditCard()">Credit card payment</button>
        </div>
    </div>
</ng-container>
<div class="form-container" *ngIf="creditCardPayment">
    <!-- <mat-tab-group (selectedTabChange)="onTabChanged($event);"> -->
    <!-- <mat-tab label="Credit card"> -->
    <form id="recurlyForm">

        <div class="inputs">
            <div class="name-input">
                <span>First Name</span>
                <input type="text" placeholder="Please enter..." data-recurly="first_name" required>
            </div>
            <div class="name-input">
                <span>Last Name</span>
                <input type="text" placeholder="Please enter..." data-recurly="last_name" required>
            </div>
            <div class="name-input">
                <span>Address 1</span>
                <input type="text" placeholder="Please enter..." data-recurly="address1" required>
            </div>
            <div class="name-input">
                <span>Address 2</span>
                <input type="text" placeholder="Please enter..." data-recurly="address2" required>
            </div>
            <div class="name-input">
                <span>City</span>
                <input type="text" placeholder="Please enter..." data-recurly="city" required>
            </div>
            <div class="name-input">
                <span>State</span>
                <input type="text" placeholder="Please enter..." data-recurly="state" required>
            </div>
            <div class="name-input">
                <span>Country</span>
                <select id="countries" data-recurly="country">
                  <option *ngFor="let c of countriesList" [value]="c.code">{{c.name}}</option>
                </select>
            </div>
            <div class="name-input">
                <span>Zip</span>
                <input type="text" placeholder="Please enter..." data-recurly="postal_code" required>
            </div>
        </div>

        <div id="recurly-elements">
            <!-- Recurly Elements will be attached here -->
        </div>

        <!-- Recurly.js will update this field automatically -->
        <input type="hidden" name="recurly-token" data-recurly="token">
        <div *ngIf="creditCardError" class="card-error">{{creditCardError}}</div>
    </form>
    <!-- </mat-tab>
        <mat-tab label="Pay pal">
            <div *ngIf="payPallError" style="color: red">{{payPallError}}</div>
            <button class="button-default" (click)="payWithPayPal()">Pay</button>
        </mat-tab>
    </mat-tab-group> -->
</div>
<div class="confirm-buttons">
  <button class="button-secondary" (click)="close()">Cancel</button>
  <button class="button-default" (click)="payWithCreditCard(false)">{{this.data.addCardOnly ? 'Update' : 'Checkout'}}</button>
</div>
