<div class="popup-header">
  <h3 class="popup-title">Edit Team</h3>
  <div class="templates-box">
    <div (click)="close()" class="close-icon"><img src="/assets/Assets%20Website/Btn/close.svg"></div>
  </div>
</div>
<form [formGroup]="editTeamForm" (ngSubmit)="editTeam()">
  <div class="form-container">
    <div class="input-container">
      <input type="text" placeholder="Team Name" required formControlName="teamName" />
    </div>
  </div>
  <div class="confirm-buttons">
    <button (click)="close()" type="button" class="button-secondary">Close</button>
    <button class="button-default" type="submit" [disabled]="!editTeamForm.valid">Update</button>
  </div>
</form>
