<ng-container *ngIf="!isFinished; else shareSuccess">
    <span *ngIf="isQuickShare" class="box-title">Quick Secure Share</span>
    <div class="share-content" cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngIf="!isQuickShare">
            <h4>Sharing content</h4>
        </ng-container>
        <div *ngFor="let content of shareData; let i = index" cdkDrag>
            <div class="content-row" [ngClass]="formErrors && formErrors.dataError[i] ? 'red-border' : ''">
                <div class="input-type-selector">
                    <span class="material-icons" cdkDragHandle>drag_indicator</span>
                    <span class="material-icons" (click)="content.typeMenuOpen = !content.typeMenuOpen">settings</span>
                    <div class="menu-items" *ngIf="content.typeMenuOpen">
                        <div class="item-row" *ngFor="let x of sharingInputTypes"
                            (click)="setShareInputType(x.value, i)">
                            <span title="Select a different type" class="material-icons" *ngIf="x.selected">done</span>
                            <span>{{x.value}}</span>
                        </div>
                    </div>
                </div>
                <input [id]="isQuickShare ? 'qs-input-' + i : 'item-input-' + i" type="text" placeholder="Please enter" [readOnly]="content.readonly"
                    [(ngModel)]="content.item" />
                <span>:</span>
                <div [ngClass]="content.typeOfField === 'password' ? 'pass-input-container' : 'input-container'">
                    <input *ngIf="content.typeOfField !== 'date' && content.typeOfField !== 'multiline'" autocomplete="new-password"
                        (change)="resetError(2, i)" [id]="!isQuickShare ? 'user-input-' + i : 'user-input-popup-' + i"
                        (keydown)="checkPassword($event, content.typeOfField, i)"
                        [ngClass]="content.typeOfField === 'password' ? 'password-text' : 'regular-text'"
                        placeholder="Please enter" [(ngModel)]="content.value" />
                    <textarea *ngIf="content.typeOfField === 'multiline'" class="regular-text" placeholder="Please enter" [(ngModel)]="content.value">
                    </textarea>
                    <mat-form-field *ngIf="content.typeOfField === 'date'" appearance="outline" class="w-full">
                        <input [id]="'user-input-' + i" [(ngModel)]="content.value" readonly placeholder="Choose a date"
                            [ngxMatDatetimePicker]="picker" matInput class="!border-none">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                    </mat-form-field>
                    <div class="eye-icon">
                        <img class="eye-icon" *ngIf="content.typeOfField === 'password'"
                            (click)="dataInputTypeService.resetInputType(i, isQuickShare)"
                            [src]="dataInputTypeService.getInputType(i, isQuickShare) === 'password' ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'" />
                    </div>
                </div>
                <div class="delete-icon" *ngIf="!isQuickShare">
                    <img (click)="removeField(i)" [ngClass]="content.canDelete ? '' : 'delete-hidden'"
                        src="/assets/Assets Website/Btn/trash.svg" />
                </div>
                <app-password-strength [score]="content.strengthData?.passwordScore"
                    [strength-data]="content.strengthData?.passwordStrength" *ngIf="content.typeOfField === 'password'">
                </app-password-strength>
            </div>
        </div>
        <button *ngIf="!isQuickShare" (click)="addField()"
            [disabled]="this.maxFields && Number(this.maxFields) == shareData.length && this.maxFields !== '-1'"
            class="button-default add-field">+ Add field</button>
    </div>

    <ng-container *ngIf="isQuickShare">
        <button class="button-default" (click)="quickShare()">Create Secure Share</button>
        <a (click)="extendedSettings()">Extended Settings</a>
    </ng-container>
</ng-container>

<ng-template #shareSuccess>
    <span class="box-title">Secure Share Created!</span>
    <div class="sharing-link">
        <span>Secure Share Link</span>
        <div class="input-box">
            <input [ngModel]="shareLink" readonly />
            <img (click)="copy()" src="/assets/Assets Website/Btn/bx-copy.svg" />
        </div>
    </div>
    <button class="button-default" (click)="reset()">Create Another</button>
</ng-template>
