import {DatePipe} from '@angular/common';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {ShareCallError, ShareContent} from 'src/app/models/share.model';
import {AuthService} from 'src/app/services/auth.service';
import {DataInputTypeService} from 'src/app/services/data-input-type.service';
import {DataService} from 'src/app/services/data.service';
import {PasswordMeterService} from 'src/app/services/password-meter.service';
import {SharesService} from 'src/app/services/shares.service';
import {ShareProtectionPopupComponent} from 'src/app/shared/share-protection-popup/share-protection-popup.component';
import {LoaderService} from '../../services/loader.service';
import {CaptchaService} from '../../services/captcha.service';
import {ConfirmPopupComponent} from '../../shared/confirm-popup/confirm-popup.component';
import {UserService} from 'src/app/services/user.service';
import {RecaptchaComponent} from 'ng-recaptcha';
import {TeamsService} from '../../services/teams.service';
import {Title} from '@angular/platform-browser';
import {AngularFaviconService} from 'angular-favicon';
import {IsUUID} from '../../shared/shares-utils';
import {SecureRequestsService} from '../../services/secure-requests.service';

@Component({
  selector: 'app-share-view',
  templateUrl: './share-view.component.html',
  styleUrls: ['./share-view.component.scss']
})
export class ShareViewComponent implements OnInit, OnDestroy, AfterViewInit {
  shortCode: string;
  shareInfo: any;
  shareData: any[];
  share: any;
  template: string;

  timerValue: number;

  isLoggedIn: boolean;

  shareBox: boolean = true;
  shareExpired: boolean = false;
  failedCaptcha = false;
  componentMessage: string;
  showPassword: boolean;
  isEmbedMode: boolean = false;

  @ViewChild('captchaRef') recaptchaComponent: RecaptchaComponent;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      shortCode: string,
      dialogMode: boolean,
      hasDeletedShare: boolean,
    },
    private route: ActivatedRoute,
    private router: Router,
    private shareService: SharesService,
    public dialog: MatDialog,
    private clipboard: ClipboardService,
    private toastrService: ToastrService,
    public authService: AuthService,
    public dataInputType: DataInputTypeService,
    public loaderService: LoaderService,
    private passwordMeter: PasswordMeterService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private notificationsService: ToastrService,
    private captchaService: CaptchaService,
    private userService: UserService,
    private teamService: TeamsService,
    private titleService: Title,
    private faviconService: AngularFaviconService,
    private secureRequestService: SecureRequestsService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.isEmbedMode = this.route.snapshot.data.isEmbed;
    this.shortCode = this.route.snapshot.params['short-code'] || this.data.shortCode;
    const shareInfoResp = await this.shareService.getShareInfo(this.shortCode).toPromise().catch(err => {
      return err.error;
    });

    if (shareInfoResp && shareInfoResp.error_code) {
      this.shareBox = false;
      this.componentMessage = shareInfoResp.message;
      if (shareInfoResp.error_code === 13 || shareInfoResp.error_code === 1) {
        this.shareExpired = true;
        const expShare = await this.shareService.getExpiredShare(this.shortCode).toPromise();
        const teamId = (expShare.organization_id) ? expShare.organization_id : null;
        await this.resolveTemplate(expShare.created_by, teamId);
      } else {
        this.template = 'default';
      }
      this.loaderService.hideLoading();
    } else if (shareInfoResp && shareInfoResp.short_code) {
      this.shareInfo = shareInfoResp;
      const teamId = (this.shareInfo.organization_id) ? this.shareInfo.organization_id : null;
      await this.resolveTemplate(this.shareInfo.created_by, teamId);
    } else {
      this.template = 'default';
    }
  }

  async showShare(): Promise<void> {
    if (!this.shareInfo) {
      return;
    }

    if (!this.shareInfo.success && this.shareInfo.message) {
      this.shareBox = false;
      this.componentMessage = this.shareInfo.message;
      if (this.shareInfo.error_code === 1) {
        this.router.navigate(['404']);
      }
    } else {
      let body = {};
      let httpHeaders: any;
      this.authService.isUserAuthenticated(false, false).then(async loggedIn => {
        this.isLoggedIn = loggedIn;
        if (this.isLoggedIn) {
          // Secure request submitted share only open for request owner and team
          // User is not same team or not request owner should be redirected to 404
          if (this.shareInfo.secure_request_short_code) {
            const sr = await this.secureRequestService.getSecureRequestInfo(this.shareInfo.secure_request_short_code).toPromise();
            if (sr.organization_id !== this.authService.getCurrentTeamId() && sr.created_by !== this.authService.currentUser.username) {
              this.router.navigate(['404']);
            }
          }

          if (this.shareInfo.requires_login) {
            httpHeaders = {item: 'accessToken', value: this.authService.currentUser.accessToken};
          }
        } else {
          // Secure request submitted share only open for request owner and team
          // Un-authenticated user should be redirected to 404
          if (this.shareInfo.secure_request_short_code) {
            this.router.navigate(['404']);
          }

          if (this.shareInfo.requires_login) {
            this.loaderService.hideLoading();
            this.shareBox = false;
            this.componentMessage = 'In order to view this secure share you need to login first';
            return;
          }
        }
        if (this.shareInfo.view_protected || this.shareInfo.password_protected) {
          const protectionPopup = this.dialog.open(ShareProtectionPopupComponent, {
            width: '460px',
            height: '471px',
            panelClass: 'popup-container',
            backdropClass: 'backdrop-blur',
            data: {info: this.shareInfo, headers: httpHeaders, template: this.template},
            disableClose: true,
          });

          protectionPopup.afterClosed().subscribe((res: { success: boolean, share: any, error?: string }) => {
            if (res && res.success) {
              this.setUpShareData(res.share);
            } else {
              this.shareBox = false;
              this.componentMessage = res.error;
            }
            this.loaderService.hideLoading();
          });
        } else {
          this.getShareData(body, httpHeaders);
        }
      });
    }
  }

  getShareData(body: any, headers: any) {
    this.shareService.getShare(this.shortCode, body, headers).subscribe(share => {
      if (share) {
        this.setUpShareData(share);
      }
    }, (err: ShareCallError) => {
      this.shareBox = false;
      this.componentMessage = err.error.message;
      if (err.error.error_code === 13) {
        this.shareExpired = true;
      }
    }, () => {
      this.loaderService.hideLoading();
    });
  }

  setUpShareData(share: any) {
    this.share = share;
    this.shareData = JSON.parse(this.share.data);
    this.shareData.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
    });

    this.shareData.forEach(x => {
      if (x.typeOfField === 'date') {
        x.value = this.datePipe.transform(x.value, 'M/d/yyy');
      }
    });
    this.checkPasswordStrength(this.shareData);
    this.setupTimer(this.share);
  }

  goToLogin() {
    this.router.navigateByUrl('/login/from-share/' + this.shortCode);
  }

  copy(data: string) {
    this.clipboard.copy(data);
    this.toastrService.success('Data copied to clipboard');
  }

  deleteShare(): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '50%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete share', message: 'Are you sure you want to delete this share?'}
    });

    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        const username = (this.authService.currentUser) ? this.authService.currentUser.username : null;
        this.shareService.deleteShare(this.shortCode, username).subscribe(resp => {
          if (resp) {
            if (this.data && this.data.dialogMode) {
              this.data.hasDeletedShare = true;
              this.dialog.closeAll();
            } else if (this.template === 'qm') {
              window.location.href = 'https://quotemedia.com';
            } else if (this.template === 'bm') {
              window.location.href = 'https://bravenetmarketing.com';
            } else {
              this.router.navigate(['/']);
            }
          }
        });
      }
    });
  }

  setupTimer(share) {
    if (share.timed_view) {
      this.timerValue = share.timed_view;
      const timer = setInterval(() => {
        this.timerValue--;
        if (this.timerValue === 0) {
          clearInterval(timer);
          this.shareBox = false;
        }
      }, 1000);
    } else {
      return;
    }
  }

  copyAll() {
    if (this.shareData) {
      let allData: string = '';
      for (let input of this.shareData) {
        allData += (input.item + ': ' + input.value + ' | ');
      }
      this.copy(allData);
    }
  }

  exportToCSV() {
    let csvName = `${this.share.title}_credentials`;
    let fieldNames: string = '';
    let fieldValues: string = '';
    if (this.shareData) {
      for (let i = 0; i < this.shareData.length; i++) {
        if (i == this.shareData.length - 1) {
          fieldNames += this.shareData[i].item;
          fieldValues += this.shareData[i].value;
        } else {
          fieldNames += this.shareData[i].item + ',';
          fieldValues += this.shareData[i].value + ',';
        }
      }
    }

    let csvData = fieldNames + '\n' + fieldValues;
    this.dataService.downloadCSV(csvData, csvName);
  }

  checkPasswordStrength(data: ShareContent[]) {
    for (let x of data) {
      if (x.typeOfField === 'password') {
        x.strengthData = this.passwordMeter.checkSyncSecurity(x.value);
      }
    }
    this.shareData = data;
  }

  confirmCaptcha(captchaRes: string | null): void {
    if (captchaRes) {
      this.captchaService.verifyCaptcha(captchaRes, false).subscribe(res => {
        if (res) {
          this.showShare();
        } else {
          this.failedCaptcha = true;
          this.notificationsService.error('Captcha resolve failed. Please refresh the page');
        }
      }, error => {
        this.failedCaptcha = true;
      });
    } else {
      this.failedCaptcha = true;
      this.notificationsService.error('Captcha resolve failed. Please refresh the page');
    }
  }

  ngAfterViewInit(): void {
    this.loaderService.showLoading();
    if (this.recaptchaComponent) {
      this.recaptchaComponent.execute();
    }
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  async resolveTemplate(userId: string, teamId: string): Promise<void> {
    try {
      let configurations;
      if (teamId) {
        const team = await this.teamService.getTeamById(teamId).toPromise();
        if (team) {
          if (team.configurations) {
            configurations = (team && team.configurations) ? JSON.parse(team.configurations) : null;
          } else {
            const owner = this.teamService.getTeamOwner(team);
            configurations = (owner && owner.configurations) ? JSON.parse(owner.configurations) : null;
          }
        }
      } else if (IsUUID(userId)) {
        const createdBy = await this.userService.getUserById(userId).toPromise();
        configurations = (createdBy && createdBy.configurations) ? JSON.parse(createdBy.configurations) : null;
      }

      if (configurations) {
        this.template = (configurations.template.name === 'default') ? 'default' : configurations.template.name;
        if (!this.data || !this.data.dialogMode) {
          if (this.template === 'qm') {
            this.titleService.setTitle('SecureShare View - QuoteMedia');
            this.faviconService.setFavicon('https://quotemedia.com/favicon.png');
          } else if (this.template === 'bm') {
            this.titleService.setTitle('SecureShare View - Bravenet Marketing');
            this.faviconService.setFavicon('https://bravenetmarketing.com/favicon/16x16.ico');
          }
        }
      } else {
        this.template = 'default';
      }
    } catch (e) {
      console.error(e);
      this.template = 'default';
    }
  }

  isMarkdownOrSourceCode(shareData): boolean {
    return shareData.typeOfField === 'markdown' || shareData.typeOfField === 'source_code';
  }
}
