export const FeatureTypes = {
  ViewProtection: 'view_protection',
  SecureViewPrompt: 'secure_view_prompt',
  MaxDaysAccessLogs: 'max_days_access_logs',
  MaxShares: 'max_shares',
  MaxFields: 'max_fields',
  MultiUser: 'multi_user',
  IpAccessRestriction: 'ip_access_restriction',
  DownloadableCredential: 'downloadable_credential',
  DomainAccessRestriction: 'domain_access_restriction',
  TotalShares: 'total_shares',
  FailedAttemptTolerance: 'failed_attemp_tolerance',
  SecureRequests: 'secure_requests',
};

export class Feature {
  feature_id: string;
  feature_code: string;
  feature_name: string;
  feature_value: any;
};
