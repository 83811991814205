import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {ConfirmPopupComponent} from '../shared/confirm-popup/confirm-popup.component';
import {HttpCaller} from './http-caller.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  pageTitle = new BehaviorSubject<string>('');

  constructor(
    private http: HttpCaller,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.router.events.subscribe(data => {
      if (data instanceof ActivationEnd) {
        if (data.snapshot.data.screenTitle) {
          if (this.pageTitle.getValue() === '') {
            this.pageTitle = new BehaviorSubject<string>(data.snapshot.data.screenTitle);
          } else {
            this.pageTitle.next(data.snapshot.data.screenTitle);
          }
        }
      }
    });
  }

  createShare(body: any, showBuyMorePopup = true): Promise<any> {
    return new Promise(resolve => {
      this.http.post('shares', 'Secure Share successfully created', body).subscribe(finished => {
        if (finished) {
          return resolve({success: true, data: finished});
        }
      }, err => {
        if (err && err.error.error_code === 61 && showBuyMorePopup) {
          const moreSharesPopup = this.dialog.open(ConfirmPopupComponent, {
            width: '450px',
            data: {
              header: 'No Secure Share Credits Available',
              message: `You've run out of secure share credits! Don't worry, you can add more Shares to your account and keep sharing credentials securely.`,
              noCancelButton: false,
              buttonMessage: 'Buy More Secure Shares'
            },
            panelClass: 'popup-container'
          });
          moreSharesPopup.afterClosed().subscribe(confirmation => {
            if (confirmation) {
              this.router.navigate(['user-account'], {queryParams: {tab1: 1, tab2: 2}});
            }
          });
        }
        return resolve({success: false, error: err});
      });
    });
  }

  downloadCSV(csvData, filename) {
    var csvFile;
    var downloadLink;

    console.log([csvData]);
    csvFile = new Blob([csvData], {type: 'text/csv;encoding:utf-8'});

    downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
