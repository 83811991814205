import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {TeamsService} from '../../../services/teams.service';
import {LoaderService} from '../../../services/loader.service';
import {Team, TeamInviteConfirmParams} from '../../../models/team.model';
import {GoogleAnalyticsService} from '../../../services/google-analytics.service';

@Component({
  selector: 'app-team-invite-confirm',
  templateUrl: './team-invite-confirm.component.html',
  styleUrls: ['./team-invite-confirm.component.scss']
})
export class TeamInviteConfirmComponent implements OnInit {

  /** 0 for not set. 1 for success. -1 for error */
  teamInviteConfirm: 0 | 1 | -1 = 0;
  teamInviteConfirmParams: TeamInviteConfirmParams;
  teamName: string;

  constructor(
    public authService: AuthService,
    private teamService: TeamsService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private gaService: GoogleAnalyticsService,
  ) {
    this.loaderService.showLoading();
    this.route.queryParams.subscribe(params => {
      this.teamInviteConfirmParams = params as TeamInviteConfirmParams;
      this.confirmInvitationToken();
      this.loaderService.hideLoading();
    });
  }

  ngOnInit(): void {
    this.teamService.getTeamById(this.teamInviteConfirmParams.teamId).subscribe(res => {
      if (res) {
        const team = res as Team;
        this.teamName = team.name;
      }
    });
  }

  confirmInvitationToken(): void {
    this.gaService.getClientId().then(clientId => {
      this.teamService.confirmToken(
        this.teamInviteConfirmParams.teamId,
        this.authService.currentUser.details.email,
        this.teamInviteConfirmParams.token,
        clientId)
        .subscribe(res => {
          this.authService.switchTeam(this.teamInviteConfirmParams.teamId, false);
          this.teamInviteConfirm = 1;
        }, err => {
          this.teamInviteConfirm = -1;
          console.log(err.error);
        });
    }).catch(err => {
      console.log(err);
    });
  }
}
