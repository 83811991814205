<div class="page-container">
  <div class="left-side">
    <re-captcha #captchaRef size="invisible" (resolved)="$event && confirmCaptcha($event)"></re-captcha>
    <ng-container class="elements-wrapper">
      <div class="app-logo" *ngIf="!this.data || !this.data.dialogMode">
        <img src="/assets/Assets Website/Logo/bravenet.png"/>
      </div>
      <div class="credentials-box" *ngIf="shareBox; else expiredBox">
        <h3>{{this.data && this.data.dialogMode ? 'SecureRequest Shared Submission Below' : 'Access Your Secure Share Information Below'}}</h3>
        <p *ngIf="failedCaptcha" class="failed-captcha">Captcha verification failed. Please refresh the page.</p>
        <p class="timed-view" *ngIf="share && share.timed_view">
          Timed View
          <br/>
          <span> {{timerValue}} seconds left</span>
        </p>
        <ng-container *ngIf="shareData">
          <div class="input-wrapper" *ngFor="let input of shareData; let i = index">
            <span>{{input.item}}</span>
            <input *ngIf="input.typeOfField !== 'multiline'" [id]="'user-input-' + i" [ngModel]="input.value"
                   [ngClass]="input.typeOfField === 'password' ? 'password-text' : 'regular-text'" readonly/>
            <textarea *ngIf="input.typeOfField === 'multiline'" class="regular-text" placeholder="Please enter"
                      [(ngModel)]="input.value" readonly>
                    </textarea>
            <div class="eye-container">
              <img class="eye-icon" *ngIf="input.typeOfField === 'password'"
                   (click)="dataInputType.resetInputType(i, false); showPassword = !showPassword"
                   [src]="showPassword ? '/assets/Assets Website/Btn/view-on-bm.svg' : '/assets/Assets Website/Btn/view-off-bm.svg'"
                   alt="show/hide password"/>
            </div>
            <div class="copy-container">
              <img (click)="copy(input.value)" src="/assets/Assets Website/Btn/bx-copy-bm.svg" class="icon-copy" alt="copy"/>
            </div>
          </div>
          <div class="action-buttons">
            <button class="btn-default" (click)="copyAll()"><img
              src="/assets/Assets Website/Btn/bx-copy-bm.svg" class="icon-copy"/>Copy all
            </button>
            <button class="btn-default" (click)="exportToCSV()"><img
              src="/assets/Assets Website/Icn/arrow-right.svg"/>Export to CSV
            </button>
          </div>
          <div class="action-buttons" *ngIf="!share.only_owner_can_delete">
            <button class="btn-danger" (click)="deleteShare()">Delete the Secure Share Forever</button>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #expiredBox>
      <app-success imageUrl="/assets/Assets Website/Icn/shared expired.svg"
                   [showDashboardLink]="false"
                   [title]="componentMessage ? componentMessage : 'Shared Credentials Expired'"
                   [message]="shareExpired ? 'The shared credentials have been scrubbed from our database and are no longer accessible.' : null"></app-success>
    </ng-template>
  </div>
  <div class="right-side" *ngIf="!this.data || !this.data.dialogMode">
    <div class="right-side-inner">
      <div class="app-logo">
        <img src="/assets/Assets Website/Logo/bravenet.png"/>
      </div>
      <div class="description-wrapper">
        <p class="description">
          The information in this page may include non-public, proprietary, confidential or intellectual property of
          Bravenet Marketing. and its affiliates and is only directed to its intended recipient. <br /> <br />
         Any dissemination, distribution or copying of the information contained in or transmitted with this link is
            unauthorized and strictly prohibited. <br /> <br />
         If you have received this link in error, please immediately <a href="https://bravenetmarketing.com/contact">notify us</a> and permanently
            delete this information by clicking the Red <span style="color:red; font-style: italic;">"Delete the Secure Share Forever"</span>&nbsp;&nbsp;button at the bottom of the form, and any
            copies of it immediately. You should not retain, copy or use this link or any information contained herein for
            any purpose, nor disclose all or any part of the contents to any other person, entity or system.
        </p>
      </div>

      <div class="logout-button">
        <label>
          Copyright © 2023 Bravenet Marketing. All Rights Reserved <br />
          <a href="https://bravenetmarketing.com" target="_blank">Home</a>&nbsp;|&nbsp;<a href="https://bravenetmarketing.com/about" target="_blank">About us</a>&nbsp;|&nbsp;<a href="https://bravenetmarketing.com/contact" target="_blank">Contact</a>
        </label>
      </div>
    </div>
  </div>
  <mat-icon color="primary" class="share-view-dialog-close" (click)="this.dialog.closeAll()" *ngIf="this.data && this.data.dialogMode">close</mat-icon>
</div>
