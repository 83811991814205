<div class="page-container">
    <div class="purple-header">
        <div class="image-container">
            <img src="/assets/Assets Website/Logo/Credenshare-white.svg" />
        </div>
        <div *ngIf="!didRegister; else registered" class="login-form">
            <p class="form-title">Sign up</p>
            <form [formGroup]="registerForm" class="inputs-container" required>
                <input type="email" formControlName="email" placeholder="Email" [pattern]="inputValidators.emailRegex" required/>
                <input type="text" formControlName="firstName" placeholder="First name" [pattern]="inputValidators.textRegex" required/>
                <input type="text" formControlName="lastName" placeholder="Last name" [pattern]="inputValidators.textRegex" required/>
                <input type="text" formControlName="company" placeholder="Company name" />
                <input type="password" formControlName="password" placeholder="Password" required (keyup)="checkPassword($event)" />
                <input type="password" formControlName="confirmPassword" placeholder="Repeat Password"/>
                <app-password-strength [score]="strengthData?.passwordScore" [strength-data]="strengthData?.passwordStrength"></app-password-strength>
                <re-captcha #captchaRef size="invisible" formControlName="recaptchaReactive"
                    (resolved)="$event && register($event)">
                </re-captcha>
                <button (click)="captchaRef.execute()" class="signin-button" type="submit">Sign Up</button>
            </form>
            <div class="agree-checkbox">
                <input [(ngModel)]="agree" type="checkbox" />
                <label>
                    I agree to the <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of service</a>
                    and <a href="https://credenshare.io/privacy-policy" target="_blank">Privacy Policy</a>
                </label>
            </div>
            <div class="register-link">
                <button class="back-to-login signup-button" [routerLink]="['/login']">Back to Login</button>
            </div>
        </div>
        <ng-template #registered>
            <app-success title="You have successfully registered!" message="We have sent you the email with the instruction how to activate your account." imageUrl="./assets/Assets Website/Icn/share created.svg"></app-success>
        </ng-template>
        <p class="login-copyright">&copy; {{authService.currentYear}} CredenShare. All rights reserved.</p>
    </div>
</div>
