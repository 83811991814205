import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {Team} from '../../../../models/team.model';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../../services/auth.service';
import {TeamsService} from '../../../../services/teams.service';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit {

  editTeamForm: FormGroup;
  @Output('editTeamEvent') editTeamEvent: EventEmitter<Team> = new EventEmitter<Team>();

  constructor(
    private dialogRef: MatDialogRef<EditTeamComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private teamsService: TeamsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit(): void {
    this.editTeamForm = new FormGroup({
      teamName: new FormControl(this.data.team.name),
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  editTeam(): void {
    this.teamsService.editTeam(this.data.team.id, this.editTeamForm.value.teamName).subscribe(resp => {
      if (resp) {
        this.editTeamEvent.emit(resp);
        this.close();
      }
    });
  }
}
