<div class="component-container">
    <p>Password Strength:</p>
    <div class="score" [ngStyle]="{'background-color': score <= 1 ? 'var(--amplify-red)' : score === 2 ? 'var(--text-accent)' : score === 3 ? 'var(--green-low-opacity)' : score === 4 ? 'var(--button-green-normal)' : 'var(--text-white)'}"></div>
    <div class="score" [ngStyle]="{'background-color': score === 2 ? 'var(--text-accent)' : score === 3 ? 'var(--green-low-opacity)' : score === 4 ? 'var(--button-green-normal)' : 'var(--text-white)'}"></div>
    <div class="score" [ngStyle]="{'background-color': score === 3 ? 'var(--green-low-opacity)' : score === 4 ? 'var(--button-green-normal)' : 'var(--text-white)'}"></div>
    <div class="score" [ngStyle]="{'background-color': score === 4 ? 'var(--button-green-normal)' : 'var(--text-white)'}"></div>
</div>
<p class="password-suggestion" *ngIf="strengthData">
    Your password is 
    <span *ngIf="score <=1">not</span> 
    <span *ngIf="score === 2">not very</span> 
    <span *ngIf="score === 4">very</span> secure. 
    <br />
    <span *ngIf="score >= 3">However, it</span> 
    <span *ngIf="score < 3">It</span> 
    would take <strong>{{strengthData.crackTimesDisplay.offlineFastHashing1e10PerSecond}}</strong> to crack it. 
    <br />
    <span *ngIf="strengthData.feedback.warning">{{strengthData.feedback.warning}}</span> 
    <br />
    <span *ngIf="strengthData.feedback.suggestions && strengthData.feedback.suggestions.length > 0">{{strengthData.feedback.suggestions[0]}}</span>
</p>
