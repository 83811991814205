<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav #sidenav mode="side" [opened]="deviceType === 1 ? true : false" class="sidebar-container"
               [ngStyle]="{'width': deviceType === 0 ? '100%' : '226px'}">
    <div class="sidebar-top">
      <div class="logo-container" *ngIf="deviceType === 1">
        <img [routerLink]="['/']" src="/assets/Assets Website/Logo/Credenshare-white.svg"/>
      </div>
      <div class="mobile-logo-wrapper" *ngIf="deviceType === 0">
        <div class="logo-image-wrapper">
          <img [routerLink]="['/']" src="/assets/Assets Website/Logo/Credenshare-white.svg"/>
        </div>
        <div class="actions-wrapper">
                    <span class="material-icons" (click)="closeSidenav()">
                        close
                    </span>
          <div class="profile-picture-container">
            <img class="profile-image" src="/assets/avatar.jpg" [routerLink]="['/user-account']"/>
          </div>
        </div>
      </div>
      <div class="sidebar-links">
        <div (click)="closeSidenav()" [routerLink]="['/']"
             [ngClass]="activeRoute === 0 ? 'active link' : 'link'">
          <img src="/assets/Assets Website/Sidebar/icn-dashboard.svg"/>
          <span>Dashboard</span>
          <div class="active-bar" *ngIf="activeRoute === 0">
          </div>
        </div>
        <div (click)="closeSidenav()" [routerLink]="['shares']"
             [ngClass]="activeRoute === 1 ? 'active link' : 'link'">
          <img src="/assets/Assets Website/Sidebar/icn-shares.svg"/>
          <span>Shares</span>
          <div class="active-bar" *ngIf="activeRoute === 1">
          </div>
        </div>
        <div (click)="closeSidenav()" [routerLink]="['secure-paste']"
             [ngClass]="activeRoute === 2 ? 'active link' : 'link'">
                <span class="material-icons">
                    integration_instructions
                </span>
          <span>Paste</span>
          <div class="active-bar" *ngIf="activeRoute === 2">
          </div>
        </div>
        <div *ngIf="hasSecureRequestsFeature" (click)="closeSidenav()" [routerLink]="['secure-requests']"
             [ngClass]="activeRoute === 3 ? 'active link' : 'link'">
                <span class="material-icons">
                    assignment
                </span>
          <span>Requests</span>
          <div class="active-bar" *ngIf="activeRoute === 3">
          </div>
        </div>
        <div *ngIf="hasTeamFeature" (click)="closeSidenav()" [routerLink]="['my-teams']"
             [ngClass]="activeRoute === 4 ? 'active link' : 'link'">
          <img src="/assets/Assets Website/Sidebar/icn-myteams.svg"/>
          <span>My Teams</span>
          <div class="active-bar" *ngIf="activeRoute === 4">
          </div>
        </div>
        <!-- <div id="disabled-route" [ngClass]="activeRoute === 2 ? 'active link' : 'link'">
            <img src="/assets/Assets Website/Sidebar/icn-templates.svg" />
            <span>Templates</span>
            <div class="active-bar" *ngIf="activeRoute === 2">
            </div>
        </div> -->
      </div>
    </div>
    <div class="sidebar-bottom">
      <div class="logout-button" (click)="logout()">Logout</div>
      <div class="logout-button">
        <a href="https://credenshare.io/support" target="_blank">Support</a>
      </div>
      <div class="logout-button">
        <a href="https://credenshare.io/privacy-policy" target="_blank">Privacy policy</a>
      </div>
      <div class="logout-button">
        <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of service</a>
      </div>

      <p class="login-copyright">&copy; {{auth.currentYear}} CredenShare.<br/> All rights reserved.</p>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-desktop-top-bar *ngIf="deviceType === 1"></app-desktop-top-bar>
    <app-mobile-top-bar *ngIf="deviceType === 0" (menuOpened)="openSidenav($event)"></app-mobile-top-bar>
    <router-outlet></router-outlet>
    <img *ngIf="!router.url.includes('user-account')" class="create-share-icon" (click)="createShare()"
         src="/assets/Assets Website/Btn/add.svg"
         tooltip="Create Secure Share"/>
  </mat-sidenav-content>
</mat-sidenav-container>
