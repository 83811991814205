import {Injectable} from '@angular/core';
import {HttpCaller} from './http-caller.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {Team, TeamEventPayload, TeamServiceEvent, TeamUser} from '../models/team.model';
import {HttpParams} from '@angular/common/http';
import {SubscriptionPlansService} from './subscription-plans.service';
import {FeatureTypes} from '../models/feature.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  teamServiceEvent = new Subject<TeamEventPayload>();

  constructor(
    private httpCaller: HttpCaller,
    private subscriptionPlanService: SubscriptionPlansService
  ) {
  }

  getTeamById(teamId: string): Observable<Team> {
    return this.httpCaller.get(`organizations/${teamId}`).pipe(map((response: Team) => {
      return response as Team;
    }));
  }

  getTeamsByUserId(userId: string, params?: HttpParams): Observable<Team[]> {
    return this.httpCaller.get(`users/${userId}/organizations`, true, params).pipe(map((response: Team[]) => {
      return response;
    }));
  }
  getTeamStatisticsById(teamId: string): Observable<any> {
    return this.httpCaller.get(`organizations/${teamId}/statistics`).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  deleteTeamById(teamId: string): Observable<any> {
    return this.httpCaller.delete(`organizations/${teamId}`, 'Team successfully deleted').pipe(map((response: Response) => {
      if (response) {
        const payload = new TeamEventPayload();
        payload.type = TeamServiceEvent.Delete;
        payload.data = teamId;
        this.teamServiceEvent.next(payload);
      }
      return response as any;
    }));
  }

  addNewTeam(owner: string, teamName: string): Observable<any> {
    return this.httpCaller.post(`organizations`, 'Team created successful', {
      name: teamName,
      created_by: owner,
    }).pipe(map((response: Response) => {
      if (response) {
        const payload = new TeamEventPayload();
        payload.type = TeamServiceEvent.Add;
        this.teamServiceEvent.next(payload);
      }
      return response as any;
    }));
  }

  editTeam(teamId: string, teamName: string): Observable<any> {
    return this.httpCaller.put(`organizations/${teamId}`, 'Team updated successful', {
      name: teamName,
    }).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  inviteMember(teamId: string, email: string): Observable<any> {
    return this.httpCaller.post(`organizations/${teamId}/invite_user`, 'Member invited', {
      email,
    }).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  inviteMemberPreview(ownerId: string, emails: Array<string>): Observable<any> {
    return this.httpCaller.post(`organizations/invite_user_preview`, null, {
      owner_id: ownerId,
      emails,
    }).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  confirmToken(teamId: string, email: string, token: string, gaClientId: string): Observable<any> {
    return this.httpCaller.post(`organizations/${teamId}/confirm_user`, 'Member confirmed', {
      email,
      confirmation_token: token,
      ga_client_id: gaClientId,
    }).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  resendInvitation(teamId: string, email: string): Observable<any> {
    return this.httpCaller.post(`organizations/${teamId}/resend_confirmation_token`, 'Invite sent successfully', {
      email,
    }).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  removeMember(teamId: string, memberId: string): Observable<any> {
    return this.httpCaller.delete(`organizations/${teamId}/users/${memberId}`, 'Member removed').pipe(map((response: Response) => {
      return response as any;
    }));
  }

  async hasAccessToTeamFeature(userId: string): Promise<boolean> {
    let hasAccess;

    hasAccess = await this.subscriptionPlanService
      .hasAccessTo(userId, FeatureTypes.MultiUser);

    if (!hasAccess) {
      const teamInfo = await this.getTeamsByUserId(userId).toPromise();
      if (teamInfo) {
        for (const team of teamInfo) {
          for (const user of team.users) {
            if (user.user_id === userId && user.confirmed) {
              hasAccess = true;
              break;
            }
          }

          if (hasAccess) {
            break;
          }
        }
      }
    }

    return hasAccess;
  }

  async isOwner(teamId: string, userId: string): Promise<boolean> {
    const teamInfo = await this.getTeamById(teamId).toPromise();
    if (teamInfo) {
      for (const user of teamInfo.users) {
        if (user.user_id === userId && user.role === 'owner') {
          return true;
        }
      }
    }
    return false;
  }

  getTeamOwner(team: Team): TeamUser {
    for (const user of team.users) {
      if (user.role === 'owner') {
        return user;
      }
    }
    return null;
  }
}
