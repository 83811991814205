import {Component} from '@angular/core';
import {RequestSubmissionComponent} from '../request-submission.component';

@Component({
  selector: 'app-qm-request-submission',
  templateUrl: './request-submission.component.html',
  styleUrls: ['./request-submission.component.scss']
})
export class QmRequestSubmissionComponent extends RequestSubmissionComponent {

}
