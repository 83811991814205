import {Component, OnInit} from '@angular/core';
import {NotificationsService} from '../../services/notifications.service';
import {AuthService} from '../../services/auth.service';
import {Notification, Notifications} from '../../models/notification.model';
import {PageEvent} from '@angular/material/paginator';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.scss']
})
export class NotificationBadgeComponent implements OnInit {

  notifications = new Notifications();
  pageIndex = 0;

  constructor(private notificationService: NotificationsService,
              private authService: AuthService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.notifications = await this.notificationService.getByUserId(this.authService.currentUser.username).toPromise();
    setInterval(() => {
      if (this.pageIndex === 0) {
        this.notificationService.getByUserId(this.authService.currentUser.username).subscribe(notifications => {
          if (notifications) {
            this.notifications = notifications;
          }
        });
      }
    }, 30000);
  }

  async goToSecureRequest(n: Notification): Promise<void> {
    await this.notificationService.markNotificationAsRead(n).toPromise();
    window.location.href = n.link;
  }

  async handlePageEvent($event: PageEvent): Promise<void> {
    this.pageIndex = $event.pageIndex;
    const params = new HttpParams().append('page', String(this.pageIndex + 1));
    this.notifications = await this.notificationService.getByUserId(this.authService.currentUser.username, params, true).toPromise();
  }

  async handleMenuClosed(evt: any): Promise<void> {
    this.pageIndex = 0;
    this.notifications = await this.notificationService.getByUserId(this.authService.currentUser.username).toPromise();
  }
}
