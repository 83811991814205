<div class="box">
    <ng-container *ngIf="showBeta">
        <div *ngIf="deviceType === 1 && !hasSideMenu" class="ribbon ribbon-top-left">
            <span>BETA</span>
        </div>
        <div *ngIf="deviceType === 0" class="banner">
            <div>BETA</div>
        </div>
    </ng-container>
    <router-outlet></router-outlet>
</div>

