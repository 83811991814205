import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class RequireAuthGuard implements CanActivate {

    constructor(
        private authService: AuthService
    ) { }

    canActivate(): Promise<boolean> {
        return this.authService.isUserAuthenticated(true);
    }
}
