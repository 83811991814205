<div class="popup-header">
  <h3 class="popup-title">{{this.data.editMode ? 'Edit' : 'Create'}} Secure Request</h3>
  <div class="templates-box">
    <div (click)="close()" class="close-icon"><img src="/assets/Assets Website/Btn/close.svg"></div>
  </div>
</div>
<div class="form-container" *ngIf="!isSecureRequestCreated else successScreen">
  <app-notification *ngIf="this.maxFields !== '-1'"
    [message]="'Your subscription allows ' + this.maxFields + ' fields in each request. <strong>Change your plan</strong> to add more'">
  </app-notification>
  <div class="share-name-container">
    <input id="name-input"
           [ngClass]="formErrors && formErrors.nameError ? 'red-border' : ''" class="name-input" type="text"
           [(ngModel)]="requestForm.name" placeholder="Enter secure request's name..." maxlength="255" />
  </div>
  <div class="share-name-container">
    <input class="name-input" type="text" [(ngModel)]="requestForm.description"
           placeholder="Enter secure request's description..." />
  </div>
  <div class="secure-request-fields" cdkDropList (cdkDropListDropped)="drop($event)">
    <h4>Request fields</h4>
    <div *ngFor="let f of requestForm.secureShareSettings.fields; let i = index" cdkDrag>
      <div class="content-row" [ngClass]="formErrors && formErrors.dataError[i] ? 'red-border' : ''">
        <div class="input-type-selector">
          <span class="material-icons" cdkDragHandle>drag_indicator</span>
          <span class="material-icons" title="Field Type" (click)="f.typeMenuOpen = !f.typeMenuOpen">{{dataInputTypeService.getInputTypeIcon(f.typeOfField)}}</span>
          <div class="menu-items" *ngIf="f.typeMenuOpen">
            <div class="item-row" *ngFor="let x of sharingInputTypes"
                 (click)="setShareInputType(x.value, i)">
              <span title="Select a different type" class="material-icons" *ngIf="x.selected">done</span>
              <span>{{x.value}}</span>
            </div>
          </div>
        </div>
        <input class="mg-left-10 mg-right-10 field-title" [id]="'item-input-' + i" type="text" placeholder="Please enter field title" [readOnly]="f.readonly"
               [(ngModel)]="f.item" required />
        <div class="delete-icon">
          <img (click)="removeField(i)" [ngClass]="f.canDelete ? '' : 'delete-hidden'"
               src="/assets/Assets Website/Btn/trash.svg" />
        </div>
      </div>
    </div>
    <button (click)="addField()"
            [disabled]="maxFields && requestForm.secureShareSettings.fields && Number(maxFields) === requestForm.secureShareSettings.fields.length"
            class="button-default add-field">+ Add field</button>
  </div>
  <mat-tab-group>
    <mat-tab label="Request Settings">
      <div class="secure-request-settings">
        <section class="settings-group">
          <div class="setting">
            <div class="label checkbox-label">
              <input type="checkbox" [(ngModel)]="requestForm.enableMaxSubmission" />
              <label>Maximum Submissions</label>
            </div>
            <div class="slider">
              <div class="max-submissions-input" [ngClass]="formErrors && formErrors.maxSubmissionError ? 'red-border' : ''">
                <input placeholder="Type a number of maximum submissions" id="max-submissions-input"
                       [(ngModel)]="requestForm.maxSubmission"
                       [disabled]="!requestForm.enableMaxSubmission"
                       class="regular-text" type="number" min="1" />
              </div>
            </div>
          </div>
        </section>
        <section class="settings-group">
          <div class="setting">
            <div class="label checkbox-label">Expires at</div>
            <mat-form-field class="datepicker" appearance="outline" >
              <input [(ngModel)]="requestForm.expiresAt" readonly placeholder="Choose a date" [ngxMatDatetimePicker]="picker" class="regular-text" matInput />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </section>

        <section class="settings-group" *ngIf="userPlan.plan.features[3].feature_value === 'true'">
          <div class="setting">
            <div class="label checkbox-label">
              <input type="checkbox" [(ngModel)]="requestForm.askPassword"
                     (ngModelChange)="passwordSelected('request_settings', $event)" />
              <label> {{this.data.editMode ? 'Update' : 'Ask'}} password to view <br />
                <p class="label-instructions">When you are ready to view the password and ensured that you are
                  in a
                  secure location, please click on the View Button</p>
              </label>
            </div>
            <div class="slider">
              <div class="share-password-input" [ngClass]="formErrors && formErrors.passwordError ? 'red-border' : ''">
                <input placeholder="Type a password for protection" id="password-input"
                       (keyup)="checkSecurity($event)" [(ngModel)]="requestForm.passwordValue"
                       [disabled]="!requestForm.askPassword" maxlength="100"
                       autocomplete="new-password" [ngClass]="!showPassword ? 'password-text' : 'regular-text'" />

                <img (click)="showPassword = !showPassword"
                     [src]="!showPassword ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'" />
              </div>
            </div>
          </div>
          <div class="setting">
            <div class="label"></div>
            <div class="slider">
              <app-password-strength [score]="generalPasswordStrength?.passwordScore"
                                     [strength-data]="generalPasswordStrength?.passwordStrength"></app-password-strength>
            </div>
          </div>
        </section>

        <div class="setting" *ngIf="userPlan.plan.features[4].feature_value === 'true'">
          <div class="label checkbox-label">
            <input type="checkbox" [(ngModel)]="requestForm.secureView" />
            <label>Secure view prompt</label>
          </div>
          <div class="slider">
                <textarea placeholder="Type a custom message for the secure view prompt"
                          [(ngModel)]="requestForm.secureViewMessage" [disabled]="!requestForm.secureView"
                          class="secure-view-message" draggable="false"></textarea>
          </div>
        </div>
        <div class="setting">
          <div class="label checkbox-label">
            <input type="checkbox" [(ngModel)]="requestForm.requireLogin" />
            <label>Require Login</label>
          </div>
        </div>
        <div class="setting">
          <div class="label checkbox-label">
            <input type="checkbox" [(ngModel)]="requestForm.emailNotification" />
            <label>Send email when new request is filled and submitted</label>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Share Settings">
      <div class="secure-request-settings">
        <section class="settings-group">
          <div class="setting">
            <p class="label">Expires in</p>
            <div class="toggles">
              <button type="button" (click)="updateExpirationType(0, 24)"
                      [ngClass]="requestForm.secureShareSettings.expirationType === 0 ? 'button-default' : 'button-secondary'">Hours</button>
              <button type="button" (click)="updateExpirationType(1, 31)"
                      [ngClass]="requestForm.secureShareSettings.expirationType === 1 ? 'button-default' : 'button-secondary'">Days</button>
              <button type="button" (click)="updateExpirationType(2, 3)"
                      [ngClass]="requestForm.secureShareSettings.expirationType === 2 ? 'button-default' : 'button-secondary'">Months</button>
            </div>
          </div>
          <div class="setting">
            <div class="label"></div>
            <div class="slider">
              <span class="slider-label">1</span>
              <mat-slider [(ngModel)]="requestForm.secureShareSettings.expirationDate" min="1"
                          [max]="requestForm.secureShareSettings.expirationType === 0 ? '24' : requestForm.secureShareSettings.expirationType === 1 ? '31' : '3'"
                          step="1" thumbLabel="true">
              </mat-slider>
              <span class="slider-label">{{requestForm.secureShareSettings.expirationType === 0 ? '24' : requestForm.secureShareSettings.expirationType === 1
                ?
                '31' : '3'}}</span>
            </div>
          </div>
          <div class="setting">
            <div class="label"></div>
            <div class="slider">
              <p class="expire-note">
                This secure share expires in
                {{requestForm.secureShareSettings.expirationDate}} {{requestForm.secureShareSettings.expirationType | expirationDateText : requestForm.secureShareSettings.expirationDate}}
              </p>
            </div>
          </div>
          <div class="setting">
            <div class="label"></div>
            <div class="toggles">
              <button type="button" class="expire-from" (click)="requestForm.secureShareSettings.expireFrom = 0"
                      [ngClass]="requestForm.secureShareSettings.expireFrom === 0 ? 'button-default' : 'button-secondary'">From
                Creation</button>
              <button type="button" class="expire-from" (click)="requestForm.secureShareSettings.expireFrom = 1"
                      [ngClass]="requestForm.secureShareSettings.expireFrom === 1 ? 'button-default' : 'button-secondary'">From First
                View</button>
            </div>
          </div>
        </section>

        <section class="settings-group" *ngIf="userPlan.plan.features[3].feature_value === 'true'">
          <div class="setting">
            <div class="label checkbox-label">
              <input type="checkbox" [(ngModel)]="requestForm.secureShareSettings.askPassword"
                     (ngModelChange)="passwordSelected('share_settings', $event)" />
              <label> {{this.data.editMode ? 'Update' : 'Ask'}} password to view <br />
                <p class="label-instructions">When you are ready to view the password and ensured that you are
                  in a
                  secure location, please click on the View Button</p>
              </label>
            </div>
            <div class="slider">
              <div class="share-password-input" [ngClass]="formErrors && formErrors.passwordError ? 'red-border' : ''">
                <input placeholder="Type a password for protection" id="share-password-input"
                       (keyup)="checkSecurity($event)" [(ngModel)]="requestForm.secureShareSettings.passwordValue"
                       [disabled]="!requestForm.secureShareSettings.askPassword" maxlength="100"
                       autocomplete="new-password" [ngClass]="!showPassword ? 'password-text' : 'regular-text'" />

                <img (click)="showPassword = !showPassword"
                     [src]="!showPassword ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'" />
              </div>
            </div>
          </div>
          <div class="setting">
            <div class="label"></div>
            <div class="slider">
              <app-password-strength [score]="generalPasswordStrength?.passwordScore"
                                     [strength-data]="generalPasswordStrength?.passwordStrength"></app-password-strength>
            </div>
          </div>
        </section>

        <div class="setting" *ngIf="userPlan.plan.features[4].feature_value === 'true'">
          <div class="label checkbox-label">
            <input type="checkbox" [(ngModel)]="requestForm.secureShareSettings.secureView" />
            <label>Secure view prompt</label>
          </div>
          <div class="slider">
                <textarea placeholder="Type a custom message for the secure view prompt"
                          [(ngModel)]="requestForm.secureShareSettings.secureViewMessage" [disabled]="!requestForm.secureShareSettings.secureView"
                          class="secure-view-message" draggable="false"></textarea>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<hr />

<div class="form-buttons" *ngIf="!isSecureRequestCreated else successButtons">
  <div class="buttons-container">
    <button (click)="close()" class="button-secondary">Cancel</button>
    <button class="button-default" (click)="createOrUpdateSecureRequest()">{{this.data.editMode ? 'Edit' : 'Create'}} Secure Request</button>
  </div>
</div>

<ng-template #successScreen>
  <div class="success-container">
    <img class="success-image" src="/assets/Assets Website/Icn/share created.svg" />
    <h1 class="success-title">Secure Request Created</h1>
    <div class="share-fields">
      <div *ngIf="createdSecureRequest.shareLink" class="sharing-link">
        <span>Link to secure request</span>
        <div class="input-box">
          <input [ngModel]="createdSecureRequest.shareLink" readonly />
          <img (click)="copy(createdSecureRequest.short_code, 0)" src="/assets/Assets Website/Btn/bx-copy.svg" />
        </div>
      </div>
      <div *ngIf="createdSecureRequest.password_protected" class="sharing-password">
        <span>Password</span>
        <div class="input-box">
          <input readonly [ngClass]="!showSecureRequestPassword ? 'password-text' : 'regular-text'"
                 [ngModel]="this.requestForm.passwordValue" />
          <img class="eye-icon" (click)="showSecureRequestPassword = !showSecureRequestPassword"
               [src]="!showSecureRequestPassword ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'" />
          <img (click)="copy(this.requestForm.passwordValue, 1)" src="/assets/Assets Website/Btn/bx-copy.svg" />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successButtons>
  <div class="success-buttons-container">
    <button class="button-default create-another" (click)="resetScreen()">Create another secure request</button>
    <button (click)="close()" class="button-secondary">Close</button>
  </div>
</ng-template>
