import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {StrengthData} from 'src/app/models/password-strength-data.model';
import {ShareContentType, ShareFormErrors} from 'src/app/models/share.model';
import {AuthService} from 'src/app/services/auth.service';
import {DataInputTypeService} from 'src/app/services/data-input-type.service';
import {ClipboardService} from 'ngx-clipboard';
import {PasswordMeterService} from 'src/app/services/password-meter.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import {UserSubscription} from 'src/app/models/plan.model';
import {DataService} from 'src/app/services/data.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {getShareUrl} from '../shares-utils';

@Component({
  selector: 'app-quick-share',
  templateUrl: './quick-share.component.html',
  styleUrls: ['./quick-share.component.scss']
})
export class QuickShareComponent implements OnInit, OnDestroy {
  @Input('is-quick-share') public isQuickShare: boolean;
  @Output('shareCreated') public shareCreated: EventEmitter<any> = new EventEmitter();
  @Output('fullSettings') public fullSettings: EventEmitter<any> = new EventEmitter();

  currentIndexForPassCheck: number;
  formErrors: ShareFormErrors = new ShareFormErrors();
  sharingInputTypes: ShareContentType[];
  isFinished: boolean;
  passStrengthData: any;

  shareLink: string;
  shareCode: string;

  userPlan: UserSubscription;
  maxFields: string;

  shareData;

  constructor(
    public dataInputTypeService: DataInputTypeService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private clipboard: ClipboardService,
    private passwordMeter: PasswordMeterService,
    private dataService: DataService
  ) {
    this.userPlan = authService.userPlan;
    if (this.userPlan) {
      this.maxFields = this.userPlan.plan.features.find(f => f.feature_code === 'max_fields').feature_value;
    }
  }

  ngOnInit(): void {
    this.sharingInputTypes = this.dataInputTypeService.shareDataInputTypes;
    this.dataInputTypeService.shareData.subscribe(values => {
      this.shareData = values;
    });
  }

  quickShare() {
    let errors = false;
    for (let c of this.shareData) {
      if (!c.item || !c.value) {
        let index = this.shareData.findIndex(x => x === c);
        this.formErrors.dataError[index] = true;
        errors = true;
      }
    }
    if (errors) {
      this.toastrService.error('Sharing content can\'t be empty');
      return;
    } else {
      const body = {
        title: 'QS' + '_' + moment().format('MMM/DD/yyyy_hh:mm'),
        data: JSON.stringify(this.shareData),
        created_by: this.authService.currentUser.username,
        encryption_type: 'aes256',
        access_counts_left: null,
        attempts_left: null,
        passcode: null,
        view_protected: false,
        timed_view: null,
        requires_login: false,
        requires_mfa: false,
        expired_at: moment().add(3, 'days').toISOString(),
        is_public: false
      };

      const currentTeam = this.authService.getCurrentTeamId();
      if (currentTeam) {
        body['organization_id'] = currentTeam;
      }

      this.dataService.createShare(body).then(result => {
        if (result.success) {
          this.shareCode = result.data.short_code;
          this.shareLink = getShareUrl() + this.shareCode;
          this.shareCreated.emit(result.data);
          this.isFinished = true;
          this.passStrengthData = null;
        }
      });
    }
  }

  checkPassword(event: any, type: string, index: number) {
    this.currentIndexForPassCheck = index;
    if (type === 'password') {
      this.passwordMeter.checkSecurity(event.target.value).then((data: StrengthData) => {
        this.shareData[this.currentIndexForPassCheck].strengthData = _.cloneDeep(data);
      });
    }
  }

  copy() {
    this.clipboard.copy(this.shareLink);
    this.toastrService.success('Link copied to clipboard!');
  }

  reset() {
    this.isFinished = false;
    this.dataInputTypeService.reset();
  }

  resetError(type: number, index?: number) {
    /*
    Type is to determine which error to reset
    0- name
    1- password
    2- data

    Index is optional, only in the case type equals to 2 to determine which input error to reset
    */
    if (this.formErrors) {
      if (type === 0) {
        this.formErrors.nameError = false;
      } else if (type === 1) {
        this.formErrors.passwordError = false;
      } else if (type === 2) {
        this.formErrors.dataError[index] = false;
      }
    }
  }


  setShareInputType(selectedItem: string, contentIndex: number) {
    const inputElement = this.isQuickShare ? document.getElementById(`qs-input-${contentIndex}`) as HTMLInputElement : document.getElementById(`item-input-${contentIndex}`) as HTMLInputElement;
    this.shareData[contentIndex].typeOfField = selectedItem;
    inputElement.focus();
    inputElement.select();
    this.shareData[contentIndex].typeMenuOpen = false;
  }

  extendedSettings() {
    this.fullSettings.emit(true);
    this.dataInputTypeService.shareData.next(this.shareData);
  }

  addField() {
    if (this.shareData.length < Number(this.maxFields) || this.maxFields === '-1') {
      this.shareData.push({
        item: '',
        value: '',
        readonly: false,
        canDelete: true,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: 'text',
        order: this.shareData.length,
      });
    }
  }

  removeField(index: number) {
    if (this.shareData && this.shareData.length > 1) {
      this.shareData.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this.dataInputTypeService.reset();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.shareData, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.shareData.length; i++) {
      this.shareData[i].order = i;
    }
  }

  protected readonly Number = Number;
}
