import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  tab1: number;
  tab2: number;

  constructor() { }

  calculateTabs(planCode: string) {
    if (planCode.includes('yearly')) {
      this.tab2 = 0;
    } else if (planCode.includes('monthly')) {
      this.tab2 = 1;
    }
  }
}
