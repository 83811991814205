import {ShareContent} from './share.model';
import * as moment from 'moment';

export class SecureRequestForm {
  name: string;
  description: string;
  askPassword: boolean;
  passwordValue: string;
  secureView: boolean;
  requireLogin: boolean;
  secureViewMessage: string;
  maxSubmission: number;
  enableMaxSubmission: boolean;
  emailNotification: boolean;
  secureShareSettings: SecureRequestShareSettings;
  expiresAt: string;
  shortCode: string;

  constructor() {
    this.secureView = false;
    this.requireLogin = false;
    this.askPassword = false;
    this.secureViewMessage = '';
    this.maxSubmission = 3;
    this.enableMaxSubmission = false;
    this.emailNotification = false;
    this.secureShareSettings = new SecureRequestShareSettings();
    this.expiresAt = moment().add(1, 'days').toISOString();
  }
}

export class SecureRequestFormErrors {
  nameError: boolean;
  passwordError: boolean;
  maxSubmissionError: boolean;
  dataError: boolean[];

  constructor() {
    this.dataError = new Array<boolean>();
  }
}

export class SecureRequestCallError {
  error: {
    success: boolean;
    message: string;
    error_code: number;
    additional_data?: any;
  };
}

export class SecureRequestShareSettings {
  fields: ShareContent[];
  askPassword: boolean;
  passwordValue: string;
  secureView: boolean;
  requireLogin: boolean;
  expirationDate: number;
  expirationType: number;
  expireFrom: number;
  secureViewMessage: string;

  constructor() {
    this.secureView = false;
    this.requireLogin = false;
    this.askPassword = false;
    this.expirationDate = 2;
    this.expirationType = 0;
    this.expireFrom = 0;
    this.secureViewMessage = '';
    this.fields = [];
  }
}
