import {environment} from '../../environments/environment';

export function IsUUID(uuid: string, isNullable: boolean = false): boolean {
  return isNullable
    ? /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid)
    : /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(uuid);
}

export function getShareUrl(): string {
    if (window.location.origin.includes('credenshare.io')) {
      return environment.URL;
    }
    return window.location.origin + '/';
}

export function calculateMaxSharesAddon(addons: any[]): number {
  let maxShares = 0;
  addons.forEach(addon => {
      maxShares += addon.max_shares;
  });
  return maxShares;
}
