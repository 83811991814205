import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionPlansService } from 'src/app/services/subscription-plans.service';

@Component({
  selector: 'app-cancel-subscription-popup',
  templateUrl: './cancel-subscription-popup.component.html',
  styleUrls: ['./cancel-subscription-popup.component.scss']
})
export class CancelSubscriptionPopupComponent implements OnInit {
  reasons = [
    {value: 'not_satisfied', description: 'I am not satisfied with product functionality', selected: false},
    {value: 'too_many_bugs', description: 'Too many bugs', selected: false},
    {value: 'too_expensive', description: 'Price is too damn high', selected: false},
    {value: 'not_interested', description: 'Not interested in such service anymore', selected: false},
    
  ];

  otherReason = {value: 'other', description: 'Other reason (please specify)', selected: false, reason:''};

  cancelReasons: string[];
  constructor(
    private dialogRef: MatDialogRef<CancelSubscriptionPopupComponent>,
    public authService: AuthService,
    private notificationService: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  validateReasons(): boolean {
    this.cancelReasons = new Array<string>();
    let selected = false;
    for (let r of this.reasons) {
      if (r.selected) {
        this.cancelReasons.push(r.description);
        selected = true;
      }
    }
    if (this.otherReason.selected && this.otherReason.reason.length > 0) {
      this.cancelReasons.push(this.otherReason.reason);
    }
    if (selected || this.otherReason.selected && this.otherReason.reason.length > 0) {
      return true;
    } else {
      if (this.otherReason.selected && this.otherReason.reason.length === 0) {
        this.notificationService.error('Please tell us why you want to cancel your subscription');
        return false;
      } else {
        this.notificationService.error('Please select at least one reason for cancelling');
        return false;
      }
    }
  }

  cancel() {
    if (this.validateReasons()) {
      this.dialogRef.close({success: true, reasons: this.cancelReasons});
    }
  }

}
