export class StrengthData {
    passwordScore: number; 
    passwordStrength: any;
}

// export class PasswordStrength {
//     calcTime: number;
//     crackTimesDisplay : {
//         offlineFastHashing1e10PerSecond: string,
//         offlineSlowHashing1e4PerSecond: string,
//         onlineNoThrottling10PerSecond: string,
//         onlineThrottling100PerHour: string,
//     };
//     crackTimesSeconds: {
//         offlineFastHashing1e10PerSecond: number,
//         offlineSlowHashing1e4PerSecond: number,
//         onlineNoThrottling10PerSecond: number,
//         onlineThrottling100PerHour: number,
//     };
//     feedback: {
//         suggestions: Array<string>,
//         warning: string,
//     }
//     guesses: number;
//     guessesLog10: number;
//     password: string;
//     score: number;
//     sequence: Array<any>;
// }