import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { HttpCaller } from './http-caller.service';

@Injectable({
  providedIn: 'root'
})
export class SlackService {

  constructor(
    private httpCaller: HttpCaller
  ) { }

  getBindingInfo(serviceType: string, extuid: string): Observable<any> {
    let params = new HttpParams().append('serviceType', serviceType).append('extuid', extuid);
    return this.httpCaller.get(`binding`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  generateBindingToken(serviceType: string, extuid: string) {
    let params = new HttpParams().append('serviceType', serviceType).append('extuid', extuid);
    return this.httpCaller.get(`binding/token`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  confirmTokenAfterLogin(serviceUserId: string, serviceType: string, userId: string, bindingToken: string) {
    const body = {
      ext_service_user_id: serviceUserId,
      service_type: serviceType,
      user_id: userId,
      binding_token: bindingToken
    };
    return this.httpCaller.post(`binding/confirm`, 'Slack is successfully connected', body).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  confirmSlackOAuthCode(code: string) {
    const params = new HttpParams().append('code', code);
    return this.httpCaller.get(`slack/authorization`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }
}
