import {Component, OnInit} from '@angular/core';
import {SharesService} from '../../../services/shares.service';
import {TeamsService} from '../../../services/teams.service';
import {AuthService} from '../../../services/auth.service';
import {DataService} from '../../../services/data.service';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {UserDeviceService} from '../../../services/user-device.service';
import {HttpParams} from '@angular/common/http';
import {debounceTime} from 'rxjs/internal/operators';
import {BaseSharesComponent} from '../../../shared/shares/base-shares.component';
import {ActivatedRoute} from '@angular/router';
import {SecureRequestsService} from '../../../services/secure-requests.service';
import {NewEditRequestComponent} from '../dialogs/new-edit-request/new-edit-request.component';
import {ShareViewComponent} from '../../share-view/share-view.component';
import {getShareUrl} from '../../../shared/shares-utils';

@Component({
  selector: 'app-view-request',
  templateUrl: './view-request.component.html',
  styleUrls: ['./view-request.component.scss']
})
export class ViewRequestComponent extends BaseSharesComponent implements OnInit {
  deviceType: number;

  isExpired: boolean;
  shortUrl: string;

  constructor(
    protected sharesService: SharesService,
    protected teamService: TeamsService,
    protected authService: AuthService,
    protected dataService: DataService,
    protected clipboard: ClipboardService,
    protected toastrService: ToastrService,
    protected dialog: MatDialog,
    protected device: UserDeviceService,
    protected route: ActivatedRoute,
    protected secureRequestsService: SecureRequestsService,
  ) {
    super(sharesService, teamService, authService, dataService, clipboard, toastrService, dialog);
    this.device = device;
    this.route = route;
    this.shortUrl = getShareUrl();
  }

  async ngOnInit(): Promise<void> {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    const params = new HttpParams().append('sort', this.sort);
    this.getActiveShares(params);
    this.searchValue.pipe(debounceTime(500)).subscribe(data => {
      const p = new HttpParams().append('filter', data);
      this.activeTab === 0 ? this.getActiveShares(p, true) : this.getPastShares(p, true);
    });

    const requestInfoResp = await this.secureRequestsService.getSecureRequestInfo(this.route.snapshot.params['short-code']).toPromise().catch(err => {
      return err.error;
    });

    if (requestInfoResp && requestInfoResp.error_code) {
      if (requestInfoResp.error_code === 71 || requestInfoResp.error_code === 70) {
        this.isExpired = true;
      }
    }

  }

  getActiveShares(params: HttpParams, isUserSearching?: boolean): void {
    const handleResp = (data) => {
      if (data && data.shares && data.shares.length > 0) {
        this.activeShares = data.shares.map(x => ({...x, protectionCounter: this.calculateProtection(x)}));
        this.pagination = data.pagination;
        this.noSearchResults = !isUserSearching;
      } else {
        this.activeShares = new Array<any>();
        this.pagination = null;
        this.noSearchResults = isUserSearching;
      }
    };

    this.sharesService.getSharesBySecureRequestShortCode(this.route.snapshot.params['short-code'], params).subscribe(data => handleResp(data));
  }

  getPastShares(params: HttpParams, isUserSearching?: boolean): void {
    const handleResp = (data) => {
      if (data && data.shares && data.shares.length > 0) {
        this.expiredShares = data.shares.map(x => ({...x, protectionCounter: this.calculateProtection(x)}));
        this.expiredPagination = data.pagination;
        this.noSearchResults = !isUserSearching;
      } else {
        this.expiredShares = new Array<any>();
        this.expiredPagination = null;
        this.noSearchResults = isUserSearching;
      }
    };

    this.sharesService.getExpiredSharesBySecureRequestShortCode(this.route.snapshot.params['short-code'], params).subscribe(data => handleResp(data));

  }

  showCreateEditSecureRequestDialog(): void {
    this.dialog.open(NewEditRequestComponent, {
      width: (this.deviceType === 1) ? '70%' : '100%',
      maxWidth: (this.deviceType === 1) ? '70%' : '100%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {shortCode: this.route.snapshot.params['short-code'], editMode: true}
    });
  }

  openShareDialog(shareShortCode: string, index: number): void {
    const shareViewDialog = this.dialog.open(ShareViewComponent, {
      minWidth: 'auto',
      minHeight: 'auto',
      maxHeight: '90%',
      panelClass: 'popup-container',
      data: {shortCode: shareShortCode, dialogMode: true}
    });

    shareViewDialog.afterClosed().subscribe(() => {
      if (shareViewDialog.componentInstance.data.hasDeletedShare) {
        this.activeShares.splice(index, 1);
      }
    });
  }
}
