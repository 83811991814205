import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';
import {HttpCaller} from './http-caller.service';

@Injectable({
  providedIn: 'any'
})
export class SharesService {

  constructor(
    private httpCaller: HttpCaller
  ) {
  }

  getShares(userId: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`users/${userId}/shares`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getSharesBySecureRequestShortCode(shortCode: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`secure_requests/${shortCode}/shares`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getExpiredShares(userId: string, params: HttpParams): Observable<any> {
    return this.httpCaller.get(`users/${userId}/expired_shares`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getExpiredSharesBySecureRequestShortCode(shortCode: string, params: HttpParams): Observable<any> {
    return this.httpCaller.get(`secure_requests/${shortCode}/expired_shares`, true, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getExpiredShare(shortCode: string, userId?: string, teamId?: string): Observable<any> {
    const body = {
      user_id: userId,
      org_id: teamId,
    };

    return this.httpCaller.post(`expired_shares/${shortCode}`, null, body, false).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getTeamShares(orgId: string, userId?: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`organizations/${orgId}/shares`, true, this.appendUserIdParam(userId, params)).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getTeamExpiredShares(orgId: string, userId?: string, params?: HttpParams): Observable<any> {
    return this.httpCaller.get(`organizations/${orgId}/expired_shares`, true, this.appendUserIdParam(userId, params)).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getShareInfo(shortCode: string): Observable<any> {
    return this.httpCaller.get(`shares/${shortCode}/info`, false).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  getShare(shortCode: string, body: any, httpHeaders?: any): Observable<any> {
    return this.httpCaller.post(`shares/${shortCode}`, null, body, null, httpHeaders).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  deleteShare(shortCode: string, userId?: string): Observable<any> {
    let params = null;
    if (userId) {
      params = new HttpParams();
      params = this.appendUserIdParam(userId, params);
    }

    return this.httpCaller.delete(`shares/${shortCode}`, 'Secure share successfully deleted', null, params).pipe(map((response: Response) => {
      return response as any;
    }));
  }

  deleteExpiredShare(id: string): Observable<any> {
    return this.httpCaller.delete(`expired_shares/${id}`, 'Secure share successfully deleted').pipe(map((response: Response) => {
      return response as any;
    }));
  }

  private appendUserIdParam(userId: string, params: HttpParams): HttpParams {
    if (userId) {
      if (!params) {
        params = new HttpParams();
      }
      return params.append('userId', userId);
    }

    return params;
  }
}
