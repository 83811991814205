<div class="crs-table-wrapper" *ngIf="hasAccess">
  <div class="filters-container">
    <div class="search-container">
        <span class="material-icons">
            search
        </span>
      <input (keyup)="filtering($event)" type="text" placeholder="Search..."/>
    </div>
    <button class="button-default mg-left-10" (click)="showCreateTeamDialog()">Create Team</button>
  </div>
  <div *ngIf="deviceType === 1; else activeOnMobile">
    <table *ngIf="teams && teams.length > 0; else noTeams">
      <tr class="table-header">
        <th>Name</th>
        <th>Members</th>
        <th>Actions</th>
      </tr>
      <tr class="table-data" *ngFor="let team of teams; let i = index">
        <td>{{team.name}}</td>
        <td class="team-members-column">
          <div *ngFor="let user of team.users; let j = index">
            <ngx-avatar size="35" name="{{user.email}}" *ngIf="j < 4" class="avatar-wrapper"></ngx-avatar>
          </div>
          <div *ngIf="team.users.length > 4">
            <ngx-avatar size="35" value="{{team.users.length - 4}}" bgColor="#1888DA"
                        class="avatar-wrapper"></ngx-avatar>
          </div>
        </td>
        <td class="actions-column">
          <div class="actions-container">
            <div class="delete" (click)="deleteTeam(team.id, i)" title="Delete team" *ngIf="isOwner(i) === true">
              <img src="/assets/Assets%20Website/Btn/trash.svg"/>
            </div>
            <div class="add" (click)="showAddMemberDialog(i)" title="Add member" *ngIf="isOwner(i) === true">
              <img src="/assets/Assets%20Website/Btn/add-2.svg"/>
            </div>
            <a routerLink="{{team.id}}" class="view" title="View team">
              <img src="/assets/Assets%20Website/Btn/arrow-right.svg"/>
            </a>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <ng-template #activeOnMobile>
    <div class="shares-grid">
      <div *ngFor="let team of teams; let i = index" class="share-card">
        <div class="share-title">
          <span class="title-text">{{team.name}}</span>
          <div class="actions-container">
            <span class="delete share-icon" title="Delete share">
                <img (click)="deleteTeam(team.id, i)" src="/assets/Assets%20Website/Btn/trash.svg"
                     *ngIf="isOwner(i) === true"/>
            </span>
            <span class="add share-icon" (click)="showAddMemberDialog(i)" title="Add member" *ngIf="isOwner(i) === true">
              <img src="/assets/Assets%20Website/Btn/add-2.svg"/>
            </span>
            <a routerLink="{{team.id}}" class="view share-icon" title="View team">
              <img src="/assets/Assets%20Website/Btn/arrow-right.svg"/>
            </a>
          </div>
        </div>

        <hr/>
        <div class="share-data">
          <div *ngFor="let user of team.users; let j = index">
            <ngx-avatar size="35" name="{{user.email}}" *ngIf="j < 4" class="avatar-wrapper"></ngx-avatar>
          </div>
          <div *ngIf="team.users.length > 4">
            <ngx-avatar size="35" value="{{team.users.length - 4}}" bgColor="#1888DA"
                        class="avatar-wrapper"></ngx-avatar>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #noTeams>
  <p
    class="no-shares">{{noSearchResults ? 'There are no results matching your search criteria' : 'There are currently no team' }}</p>
</ng-template>
