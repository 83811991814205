import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {getShareUrl} from '../../shared/shares-utils';
import {DataService} from '../../services/data.service';
import * as moment from 'moment/moment';
import {StrengthData} from '../../models/password-strength-data.model';
import {ExpiresInComponent} from '../../shared/share-settings/expires-in/expires-in.component';
import {PasswordToViewComponent} from '../../shared/share-settings/password-to-view/password-to-view.component';
import {NumberOfViewComponent} from '../../shared/share-settings/number-of-view/number-of-view.component';
import {FormControl, Validators} from '@angular/forms';
import {MiscComponent} from '../../shared/share-settings/misc/misc.component';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/meta';
import {CodemirrorComponent} from '@ctrl/ngx-codemirror';
import {MatTabGroup} from '@angular/material/tabs';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {SupportedLanguage} from '../../models/supported-language.model';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'app-secure-paste',
  templateUrl: './secure-paste.component.html',
  styleUrls: ['./secure-paste.component.scss']
})
export class SecurePasteComponent implements OnInit, AfterViewInit, OnDestroy {

  selectedFormat = 'markdown';
  public selectedLanguageCtrl: FormControl = new FormControl(null);
  public selectedLanguageFilterCtrl: FormControl = new FormControl('');
  shareLink: string;

  content: FormControl = new FormControl('', [Validators.required]);
  isPublicVersion = false;
  viewInitialized = false;
  public supportedLanguages: SupportedLanguage[] = [];

  @ViewChild(ExpiresInComponent) expiresComponent: ExpiresInComponent;
  @ViewChild(PasswordToViewComponent) passwordComponent: PasswordToViewComponent;
  @ViewChild(NumberOfViewComponent) numberOfViewComponent: NumberOfViewComponent;
  @ViewChild(MiscComponent) miscShareSettingsComponent: MiscComponent;
  @ViewChild('codeMirror') codeMirrorComponent: CodemirrorComponent;
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
  @ViewChild('selectLanguage') selectLanguageComponent: MatSelect;
  @ViewChild('securePasteSettings') securePasteSettingsComponent: MatSidenav;

  public filteredLanguages: ReplaySubject<SupportedLanguage[]> = new ReplaySubject<SupportedLanguage[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor(private dataService: DataService,
              private clipboard: ClipboardService,
              private toastrService: ToastrService,
              private route: ActivatedRoute,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.isPublicVersion = this.route.snapshot.data.isPublic;
    if (!this.isPublicVersion && !this.authService.userPlan && this.authService.currentUser) {
      this.authService.getSubscription(true);
    }

    CodeMirror.modeInfo.forEach(info => {
      this.supportedLanguages.push({
        name: info.name,
        mode: info.mode
      });
    });

    this.selectedLanguageCtrl.setValue(this.supportedLanguages[0]);
    this.filteredLanguages.next(this.supportedLanguages.slice());
    this.selectedLanguageFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLanguages();
      });
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue(): void {
    this.filteredLanguages
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.selectLanguageComponent.compareWith = (a: SupportedLanguage, b: SupportedLanguage) => a && b && a.name === b.name;
      });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterLanguages(): void {
    if (!this.supportedLanguages) {
      return;
    }

    // get the search keyword
    let search = this.selectedLanguageFilterCtrl.value;
    if (!search) {
      this.filteredLanguages.next(this.supportedLanguages.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the languages
    this.filteredLanguages.next(
      this.supportedLanguages.filter((lang: SupportedLanguage) => {
        return lang.name.toLowerCase().indexOf(search) > -1;
      })
    );

  }

  ngAfterViewInit(): void {
    this.setInitialValue();
    // Avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      // Your code to update the property
      this.viewInitialized = true;
    }, 0);
  }

  createShare() {
    this.securePasteSettingsComponent.close();
    if (!this.hasValidationErrors()) {
      const body = {
        title: 'Paste' + '_' + moment().format('MMM/DD/yyyy_hh:mm'),
        data: JSON.stringify([{
          item: '',
          value: this.content.value,
          readonly: false,
          canDelete: true,
          strengthData: new StrengthData(),
          typeMenuOpen: false,
          typeOfField: this.selectedFormat,
          selectedProgrammingLanguage: (this.selectedFormat === 'source_code') ? this.selectedLanguageCtrl.value.mode : null,
          order: 0,
        }]),
        created_by: (!this.isPublicVersion) ? this.authService.currentUser.username : null,
        description: '',
        encryption_type: 'aes256',
        is_public: this.isPublicVersion,
        expired_at: this.expiresComponent.calculateExpiration(),
        expired_in_seconds_after_opened: this.expiresComponent.calculateSeconds(),
        access_counts_left: this.numberOfViewComponent && this.numberOfViewComponent.viewingTimes && !this.numberOfViewComponent.unlimitedViewTime ? this.numberOfViewComponent.viewingTimesValue : null,
        attempts_left: this.passwordComponent && this.passwordComponent.failedAttemptsToleranceEnabled.value === true ? this.passwordComponent.failedAttemptsValue : null,
        passcode: this.passwordComponent && this.passwordComponent.askPassword.value === true ? this.passwordComponent.passwordField.value : null,
        view_protected: this.passwordComponent && this.passwordComponent.secureViewPromptEnabled.value,
        timed_view: this.miscShareSettingsComponent && this.miscShareSettingsComponent.timedViewEnabled.value === true ? this.miscShareSettingsComponent.timedViewValue : null,
        requires_login: this.miscShareSettingsComponent && this.miscShareSettingsComponent.requireLoginEnabled.value,
        requires_mfa: false,
        view_protected_message: this.passwordComponent && this.passwordComponent.secureViewMessage.value,
        only_owner_can_delete: this.miscShareSettingsComponent && this.miscShareSettingsComponent.onlyOwnerCanDeleteEnabled.value,
      };

      const currentTeam = this.authService.getCurrentTeamId();
      if (currentTeam) {
        body['organization_id'] = currentTeam;
      }

      this.dataService.createShare(body).then(result => {
        if (result.success) {
          this.shareLink = getShareUrl() + result.data.short_code;
        }
      });
    } else {
      this.content.markAsTouched();
      if (this.passwordComponent.askPassword.value === true) {
        this.passwordComponent.passwordField.markAsTouched();
      }
    }
  }

  changeFormat(format: string) {
    this.selectedFormat = format;
    if (this.matTabGroup.selectedIndex !== 0) {
      this.matTabGroup.selectedIndex = 0;
    }
  }

  changeLanguage(event: MatSelectChange) {
    this.codeMirrorComponent.codeMirror.setOption('mode', event.value.mode);
  }

  hasValidationErrors(): boolean {
    if (this.content.invalid) {
      this.toastrService.error('Content is required');
      return true;
    }

    if (this.passwordComponent && this.passwordComponent.hasValidationErrors()) {
      this.toastrService.error('Password is required if password protection is enabled');
      this.securePasteSettingsComponent.open();
      return true;
    }

    return false;
  }

  copy(url: string): void {
    this.clipboard.copy(url);
    this.toastrService.success('Link copied to clipboard!');
  }

  reset(): void {
    this.shareLink = null;
    this.content.reset();
    this.content.setValue('');
    this.expiresComponent.reset();
    this.passwordComponent.reset();
    this.numberOfViewComponent.reset();
    this.miscShareSettingsComponent.reset();
    this.selectedLanguageCtrl.setValue(this.supportedLanguages[0]);
    this.selectedLanguageFilterCtrl.setValue('');
    this.selectedFormat = 'markdown';
    this.codeMirrorComponent.codeMirror.setOption('mode', 'markdown');
  }
}
