<div class="mobile-top-bar">
  <div class="logo" [routerLink]="['/']">
    <img src="/assets/Assets Website/Logo/Credenshare.svg"/>
  </div>
  <div class="menu-picture-container">
    <div (click)="emitMenuOpened()">
      <img src="/assets/Assets Website/Icn/mobile-menu.svg"/>
    </div>
    <app-notification-badge></app-notification-badge>
    <div class="profile-picture-container">
      <button mat-button [matMenuTriggerFor]="userProfile">
        <img class="profile-image" src="/assets/avatar.jpg"/>
      </button>
      <mat-menu #userProfile="matMenu">
        <button mat-menu-item [routerLink]="['/user-account']">My Profile</button>
        <button mat-menu-item [matMenuTriggerFor]="teamsMenu">Switch Team</button>
      </mat-menu>

      <mat-menu #teamsMenu="matMenu">
        <button mat-menu-item (click)="this.authService.switchTeam(null)">
          <mat-icon *ngIf="!this.authService.getCurrentTeamId()">check</mat-icon>
          <span>No Team</span>
        </button>
        <div *ngFor="let t of teams">
          <button mat-menu-item (click)="this.authService.switchTeam(t.id)">
            <mat-icon *ngIf="this.authService.getCurrentTeamId() === t.id">check</mat-icon>
            <span>{{t.name}}</span>
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
