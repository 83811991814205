import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { Addon } from '../models/addon-model';
import { HttpCaller } from './http-caller.service';

@Injectable({
  providedIn: 'root'
})
export class AddonService {

  constructor(private http: HttpCaller) { }

  getAllAddons(): Observable<Addon[]> {
    return this.http.get('addons/all').pipe(map((response: Response) => {
      return response as any;
    }));
  }
}
