<div class="filters-container" *ngIf="hasAccess">
  <select [(ngModel)]="sort" (change)="fetchSorted()">
    <option value="expired_at">Sort by Expiration Date</option>
    <option value="created_at">Sort by Creation Date</option>
    <option value="title">Sort by Name</option>
  </select>
  <div class="search-container">
        <span class="material-icons">
            search
        </span>
    <input (keyup)="filtering($event)" type="text" placeholder="Search..."/>
  </div>
  <button class="button-default mg-left-10" (click)="showCreateEditSecureRequestDialog()">Create New Request</button>
</div>
<mat-tab-group (selectedTabChange)="tabSwitched($event)" *ngIf="hasAccess">
  <mat-tab label="Active">
    <div *ngIf="deviceType === 1; else activeOnMobile">
      <app-pagination (numberClicked)="pageChanged($event, 0)"
                      *ngIf="pagination && activeSecureRequests && pagination && pagination.totalPage > 1"
                      [pageData]="pagination"></app-pagination>
      <table *ngIf="activeSecureRequests && activeSecureRequests.length > 0; else noActiveShares">
        <tr class="table-header">
          <th>Name</th>
          <th>Created at</th>
          <th>Submission</th>
          <th>Requested by</th>
          <th>Expired on</th>
          <th>Actions</th>
        </tr>
        <tr class="table-data" *ngFor="let secureRequest of activeSecureRequests; let i = index">
          <td>{{secureRequest.title}}</td>
          <td>{{secureRequest.created_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</td>
          <td>{{secureRequest.total_submissions || 0}} / {{secureRequest.max_submission || 'Unlimited'}}</td>
          <td>{{secureRequest.created_by_email}}</td>
          <td>{{secureRequest.expired_at ? (secureRequest.expired_at | date: 'MMM dd, yyyy, hh:mm:ss') : 'No'}}</td>
          <td class="actions-column">
            <div class="actions-container">
              <div class="delete" (click)="deleteSecureRequest(secureRequest.short_code, i)" title="Delete request">
                <img src="/assets/Assets Website/Btn/trash.svg"/>
              </div>
              <div class="copy" title="Copy to clipboard">
                <img (click)="copy(secureRequest.short_code)" src="/assets/Assets Website/Btn/bx-copy.svg"/>
              </div>
              <a routerLink="{{secureRequest.short_code}}" class="view share-icon" title="View submissions">
                <img src="/assets/Assets Website/Btn/arrow-right.svg"/>
              </a>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <ng-template #activeOnMobile>
      <div class="secureRequests-grid">
        <div *ngFor="let secureRequest of activeSecureRequests; let i = index" class="share-card">
          <p class="share-title">
            <span class="title-text">{{secureRequest.title}}</span>
            <span class="copy" title="Copy to clipboard">
                            <img (click)="copy(secureRequest.short_code)" src="/assets/Assets Website/Btn/bx-copy.svg"/>
                        </span>
            <span class="delete" title="Delete secureRequest">
                            <img (click)="deleteSecureRequest(secureRequest.short_code, i)"
                                 src="/assets/Assets Website/Btn/trash.svg"/>
                        </span>
            <span routerLink="{{secureRequest.short_code}}" class="view share-icon" title="View submissions">
              <img src="/assets/Assets Website/Btn/arrow-right.svg"/>
            </span>
          </p>
          <hr/>
          <div class="share-data">
            <div class="share-date">
              <span class="date-title">Created On</span>
              <span class="share-creation">{{secureRequest.created_at | date: 'MMM dd, yyyy'}}</span>
            </div>
            <div class="protections">
              <span class="date-title">Submission</span>
              <span class="share-protection">{{secureRequest.total_submissions || 0}} / {{secureRequest.max_submission || 'Unlimited'}}</span>
            </div>
            <div class="protections">
              <span class="date-title">Requested by</span>
              <span class="share-protection">{{secureRequest.created_by_email}}</span>
            </div>
            <div class="expiration-date">
              <ng-container>
                <span class="date-title">Expires on</span>
                <span
                  class="share-expiration">{{secureRequest.expired_at ? (secureRequest.expired_at | date: 'MMM dd, yyyy, hh:mm:ss') : 'No'}}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Expired">
    <div *ngIf="deviceType === 1; else expiredOnMobile">
      <app-pagination (numberClicked)="pageChanged($event, 1)"
                      *ngIf="expiredPagination && expiredSecureRequests && expiredPagination && expiredPagination.totalPage > 1"
                      [pageData]="expiredPagination"></app-pagination>
      <table *ngIf="expiredSecureRequests && expiredSecureRequests.length > 0; else noExpiredShares">
        <tr class="table-header">
          <th>Name</th>
          <th>Created at</th>
          <th>Submission</th>
          <th>Requested by</th>
          <th>Expired on</th>
          <th>Actions</th>
        </tr>
        <tr class="table-data" *ngFor="let expiredSecureRequest of expiredSecureRequests; let i = index">
          <td>{{expiredSecureRequest.title}}</td>
          <td>{{expiredSecureRequest.created_at | date: 'MMM dd, yyyy, hh:mm:ss'}}</td>
          <td>{{expiredSecureRequest.total_submissions || 0}} / {{expiredSecureRequest.max_submission || 'Unlimited'}}</td>
          <td>{{expiredSecureRequest.created_by_email}}</td>
          <td>{{expiredSecureRequest.expired_at ? (expiredSecureRequest.expired_at | date: 'MMM dd, yyyy, hh:mm:ss') : 'No'}}</td>
          <td class="actions-column">
            <div class="actions-container">
              <a routerLink="{{expiredSecureRequest.short_code}}" class="view share-icon" title="View Submissions">
                <img src="/assets/Assets Website/Btn/arrow-right.svg"/>
              </a>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <ng-template #expiredOnMobile>
      <div class="secureRequests-grid">
        <div *ngFor="let expiredSecureRequest of expiredSecureRequests; let i = index" class="share-card">
          <p class="share-title">
            <span class="title-text">{{expiredSecureRequest.title}}</span>
            <span routerLink="{{expiredSecureRequest.short_code}}" class="view share-icon" title="View submissions">
              <img src="/assets/Assets Website/Btn/arrow-right.svg"/>
            </span>
          </p>
          <hr/>
          <div class="share-data">
            <div class="share-date">
              <span class="date-title">Created On</span>
              <span class="share-creation">{{expiredSecureRequest.created_at | date: 'MMM dd, yyyy'}}</span>
            </div>
            <div class="share-date">
              <span class="date-title">Total Submissions</span>
              <span class="share-creation">{{expiredSecureRequest.total_submissions || 0}} / {{expiredSecureRequest.max_submission || 'Unlimited'}}</span>
            </div>
            <div class="share-date">
              <span class="date-title">Requested By</span>
              <span class="share-creation">{{expiredSecureRequest.created_by_email}}</span>
            </div>
            <div class="expiration-date">
              <ng-container>
                <span class="date-title">Expires on</span>
                <span
                  class="share-expiration">{{expiredSecureRequest.expired_at ? (expiredSecureRequest.expired_at | date: 'MMM dd, yyyy, hh:mm:ss') : 'No'}}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ng-template #noExpiredShares>
  <p
    class="no-shares">{{noSearchResults ? 'There are no results matching your search criteria' : 'There are currently no expired requests'}}</p>
</ng-template>
<ng-template #noActiveShares>
  <p
    class="no-shares">{{noSearchResults ? 'There are no results matching your search criteria' : 'There are currently no active requests' }}</p>
</ng-template>
