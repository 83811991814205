<div class="popup-wrapper">
  <div class="logo">
    <img *ngIf="this.data.template === 'default'" src="/assets/Assets Website/Logo/Credenshare.svg" />
    <img *ngIf="this.data.template === 'qm'" src="https://quotemedia.com/assets/img/quotemedia_large_company_logo.png"/>
    <img *ngIf="this.data.template === 'bm'" src="/assets/Assets Website/Logo/bravenet.png"/>
  </div>
  <p class="title">
    <span class="title-header">Privacy Screen:</span> <br /> Make sure you are in a secure location
    <br />
    <span *ngIf="data.info.password_protected" class="smaller-text">Enter password to get the access to secured request form</span>
    <span class="smaller-text" *ngIf="data.info.view_protected_message">{{data.info.view_protected_message}}</span>
  </p>
  <div class="input-and-button">
    <input *ngIf="data.info.password_protected" class="password-text" [(ngModel)]="password" placeholder="Password" />
    <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>
    <button (click)="close()" class="{{this.data.template === 'default' ?  'button-default' : 'button-default-qm'}} view-credentials">Open secure request</button>
  </div>
</div>
