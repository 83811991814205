<section class="settings-group">
  <div class="setting">
    <div class="label checkbox-label">
      <input type="checkbox" [formControl]="timedViewEnabled" />
      <label>Timed View</label>
    </div>
    <div class="slider">
      <span class="slider-label">15s</span>
      <mat-slider [(ngModel)]="timedViewValue" [disabled]="!timedViewEnabled.value" min="15" max="180"
                  step="5" thumbLabel="true"></mat-slider>
      <span class="slider-label">180s</span>
    </div>
  </div>
  <div class="setting" *ngIf="timedViewEnabled.value">
    <div class="label"></div>
    <div class="slider">
      <p class="expire-note">
        This secure share will be expired in {{timedViewValue}} seconds after open.
      </p>
    </div>
  </div>
  <div class="setting">
    <div class="label checkbox-label">
      <input type="checkbox" [formControl]="requireLoginEnabled" />
      <label>Require Login</label>
    </div>
  </div>
  <div class="setting">
    <div class="label checkbox-label">
      <input type="checkbox" [formControl]="onlyOwnerCanDeleteEnabled" />
      <label>Only Owner Can Delete</label>
    </div>
  </div>
</section>
