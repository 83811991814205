import {Component, Inject, OnInit} from '@angular/core';
import {ShareContentType} from '../../../../models/share.model';
import {StrengthData} from '../../../../models/password-strength-data.model';
import {UserSubscription} from '../../../../models/plan.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../../../services/auth.service';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {DataInputTypeService} from '../../../../services/data-input-type.service';
import {PasswordMeterService} from '../../../../services/password-meter.service';
import * as moment from 'moment/moment';
import {SecureRequestForm, SecureRequestFormErrors} from '../../../../models/secure-request.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SecureRequestsService} from '../../../../services/secure-requests.service';
import {getShareUrl} from '../../../../shared/shares-utils';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-edit-request.component.html',
  styleUrls: ['./new-edit-request.component.scss']
})
export class NewEditRequestComponent implements OnInit {

  showPassword: boolean = false;
  showSecureRequestPassword: boolean = false;
  requestForm = new SecureRequestForm();

  sharingInputTypes: ShareContentType[];

  formErrors: SecureRequestFormErrors;

  generalPasswordStrength: StrengthData = new StrengthData();

  isSecureRequestCreated: boolean = false;
  createdSecureRequest: any;
  allSecureRequests: any[] = new Array<any>();
  userPlan: UserSubscription;
  maxFields: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      shortCode: string,
      editMode: boolean
    },
    private dialogRef: MatDialogRef<NewEditRequestComponent>,
    private authService: AuthService,
    private clipboard: ClipboardService,
    private toastrService: ToastrService,
    public dataInputTypeService: DataInputTypeService,
    private passwordMeter: PasswordMeterService,
    private secureRequestService: SecureRequestsService,
  ) {
    this.userPlan = authService.userPlan;
    if (this.userPlan) {
      this.maxFields = this.userPlan.plan.features.find(f => f.feature_code === 'max_fields').feature_value;
    }
    this.sharingInputTypes = this.dataInputTypeService.shareDataInputTypes;
  }

  async ngOnInit(): Promise<void> {
    this.sharingInputTypes = this.dataInputTypeService.shareDataInputTypes;
    if (!this.data.editMode) {
      this.addField();
    } else {
      await this.fillDataForEdit();
    }
  }

  checkSecurity(event): void {
    event.stopPropagation();
    this.passwordMeter.checkSecurity(event.target.value).then((res: StrengthData) => {
      if (res) {
        this.generalPasswordStrength = res;
      }
    });
  }

  close(): void {
    this.dialogRef.close(this.isSecureRequestCreated ? this.allSecureRequests : null);
  }

  copy(str: string, type: number): void {
    if (type === 0) {
      this.clipboard.copy(`${getShareUrl()}/r/${str}`);
      this.toastrService.success('Link copied to clipboard!');
    } else if (type === 1) {
      this.clipboard.copy(str);
      this.toastrService.success('Password copied to clipboard!');
    }
  }

  resetScreen(): void {
    this.isSecureRequestCreated = false;
    this.requestForm = new SecureRequestForm();
    this.addField();
  }

  inputsValid(): boolean {
    this.formErrors = new SecureRequestFormErrors();
    if (!this.requestForm.name) {
      this.formErrors.nameError = true;
      this.toastrService.error('Secure request\'s title required');
      document.getElementById('name-input').focus();
      return false;
    }
    if (this.requestForm.askPassword && !this.requestForm.passwordValue && !this.data.editMode) {
      this.formErrors.passwordError = true;
      this.toastrService.error('Password is required if password protection is enabled');
      document.getElementById('password-input').focus();
      return false;
    }

    if (this.requestForm.secureShareSettings.askPassword && !this.requestForm.secureShareSettings.passwordValue) {
      this.formErrors.passwordError = true;
      this.toastrService.error('Password is required if password protection is enabled');
      document.getElementById('share-password-input').focus();
      return false;
    }

    if (this.requestForm.enableMaxSubmission && this.requestForm.maxSubmission <= 0) {
      this.formErrors.maxSubmissionError = true;
      this.toastrService.error('Max submission must be greater than 0');
      document.getElementById('max-submissions-input').focus();
      return false;
    }

    for (const f of this.requestForm.secureShareSettings.fields) {
      if (f.item.trim() === '') {
        this.toastrService.error('Please input the name for all fields');
        return false;
      }
    }

    return true;
  }

  passwordSelected(source, event): void {
    if (source === 'request_settings') {
      this.requestForm.secureView = event;
    } else {
      this.requestForm.secureShareSettings.secureView = event;
    }
  }

  createOrUpdateSecureRequest(): void {
    if (this.inputsValid()) {
      const body = {
        title: this.requestForm.name,
        email_notification: this.requestForm.emailNotification,
        metadata: {
          fields: this.requestForm.secureShareSettings.fields,
          view_protected: this.requestForm.secureShareSettings.secureView,
          view_protected_message: this.requestForm.secureShareSettings.secureViewMessage,
          requires_login: this.requestForm.secureShareSettings.requireLogin,
          passcode: this.requestForm.secureShareSettings.askPassword === true ? this.requestForm.secureShareSettings.passwordValue : null,
          expire_from: this.requestForm.secureShareSettings.expireFrom,
          expiration_type: this.requestForm.secureShareSettings.expirationType,
          expiration_date: this.requestForm.secureShareSettings.expirationDate
        },
        description: this.requestForm.description,
        created_by: this.authService.currentUser.username,
        access_counts_left: null,
        attempts_left: null,
        passcode: this.requestForm.askPassword === true ? this.requestForm.passwordValue : (this.data.editMode) ? 'N/A' : null, // N/A to indicate that we shouldn't update passcode on backend.
        view_protected: this.requestForm.secureView,
        timed_view: null,
        requires_login: this.requestForm.requireLogin,
        requires_mfa: false,
        expired_at: moment(this.requestForm.expiresAt).toISOString(),
        max_submission: this.requestForm.enableMaxSubmission ? this.requestForm.maxSubmission : null,
        view_protected_message: this.requestForm.secureViewMessage,
      };

      const currentTeam = this.authService.getCurrentTeamId();
      if (currentTeam) {
        body['organization_id'] = currentTeam;
        body['metadata']['organization_id'] = currentTeam;
      }

      if (!this.data.editMode) {
        this.secureRequestService.createSecureRequest(body).subscribe(result => {
          if (result.success) {
            this.allSecureRequests.push(result.data);
            this.isSecureRequestCreated = true;
            this.createdSecureRequest = result.data;
            this.createdSecureRequest['shareLink'] = getShareUrl() + 'r/' + result.data.short_code;
          }
        });
      } else {
        this.secureRequestService.editSecureRequest(this.data.shortCode, body).subscribe(result => {
          if (result.success) {
            this.close();
          }
        });
      }
    }
  }

  updateExpirationType(type: number, maxValue: number): void {
    this.requestForm.secureShareSettings.expirationType = type;
    if (this.requestForm.secureShareSettings.expirationDate > maxValue) {
      this.requestForm.secureShareSettings.expirationDate = maxValue;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.requestForm.secureShareSettings.fields, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.requestForm.secureShareSettings.fields.length; i++) {
      this.requestForm.secureShareSettings.fields[i].order = i;
    }
  }

  setShareInputType(selectedItem: string, contentIndex: number): void {
    const inputElement = document.getElementById(`item-input-${contentIndex}`) as HTMLInputElement;
    this.requestForm.secureShareSettings.fields[contentIndex].typeOfField = selectedItem;
    inputElement.focus();
    inputElement.select();
    this.requestForm.secureShareSettings.fields[contentIndex].typeMenuOpen = false;
  }

  addField(): void {
    if (this.requestForm.secureShareSettings.fields.length < Number(this.maxFields) || this.maxFields === '-1') {
      this.requestForm.secureShareSettings.fields.push({
        item: '',
        value: '',
        readonly: false,
        canDelete: (this.requestForm.secureShareSettings.fields.length !== 0),
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: 'text',
        order: this.requestForm.secureShareSettings.fields.length,
      });
    }
  }

  removeField(index: number): void {
    if (this.requestForm.secureShareSettings.fields && this.requestForm.secureShareSettings.fields.length > 1) {
      this.requestForm.secureShareSettings.fields.splice(index, 1);
    }
  }

  reset(): void {
    this.requestForm.secureShareSettings.fields = [];
  }

  async fillDataForEdit(): Promise<void> {
    const s = await this.secureRequestService.getSecureRequest(this.data.shortCode, {action: 'edit'}).toPromise();
    s.metadata = JSON.parse(s.metadata);

    const fields = [];
    for (const f of s.metadata.fields) {
      fields.push({
        item: f.item,
        value: '',
        readonly: false,
        canDelete: true,
        strengthData: new StrengthData(),
        typeMenuOpen: false,
        typeOfField: f.typeOfField,
        order: f.order,
      });
    }

    this.requestForm = {
      name: s.title,
      description: s.description,
      shortCode: s.short_code,
      askPassword: false,
      secureView: (s.view_protected),
      secureViewMessage: s.view_protected_message,
      requireLogin: s.requires_login,
      expiresAt: moment(s.expired_at).toISOString(),
      enableMaxSubmission: (s.max_submission),
      maxSubmission: s.max_submission,
      emailNotification: s.email_notification,
      passwordValue: null,
      secureShareSettings: {
        fields,
        secureView: (s.metadata.view_protected),
        secureViewMessage: s.metadata.view_protected_message,
        requireLogin: s.metadata.requires_login,
        askPassword: (s.metadata.passcode),
        passwordValue: s.metadata.passcode,
        expireFrom: s.metadata.expire_from,
        expirationType: s.metadata.expiration_type,
        expirationDate: s.metadata.expiration_date,
      }
    };
  }

  protected readonly Number = Number;
}
