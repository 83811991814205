import {HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {debounceTime} from 'rxjs/internal/operators';
import {UserDeviceService} from 'src/app/services/user-device.service';
import {CreateSharePopupComponent} from 'src/app/shared/create-share-popup/create-share-popup.component';
import {BaseSharesComponent} from '../../shared/shares/base-shares.component';
import {SharesService} from '../../services/shares.service';
import {TeamsService} from '../../services/teams.service';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-shares',
  templateUrl: './shares.component.html',
  styleUrls: ['./shares.component.scss']
})
export class SharesComponent extends BaseSharesComponent implements OnInit {

  deviceType: number;

  constructor(
    protected sharesService: SharesService,
    protected teamService: TeamsService,
    protected authService: AuthService,
    protected dataService: DataService,
    protected clipboard: ClipboardService,
    protected toastrService: ToastrService,
    protected dialog: MatDialog,
    protected device: UserDeviceService
  ) {
    super(sharesService, teamService, authService, dataService, clipboard, toastrService, dialog);
    this.device = device;
  }

  ngOnInit(): void {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    const params = new HttpParams().append('sort', this.sort);
    this.getActiveShares(params);
    this.searchValue.pipe(debounceTime(500)).subscribe(data => {
      const p = new HttpParams().append('filter', data);
      this.activeTab === 0 ? this.getActiveShares(p, true) : this.getPastShares(p, true);
    });
  }

  getActiveShares(params: HttpParams, isUserSearching?: boolean): void {
    const currentTeam = this.authService.getCurrentTeamId();
    const handleResp = (data) => {
      if (data && data.shares && data.shares.length > 0) {
        this.activeShares = data.shares.map(x => ({...x, protectionCounter: this.calculateProtection(x)}));
        this.pagination = data.pagination;
        this.noSearchResults = !isUserSearching;
      } else {
        this.activeShares = new Array<any>();
        this.pagination = null;
        this.noSearchResults = isUserSearching;
      }
    };

    if (currentTeam) {
      const isOwner = this.teamService.isOwner(currentTeam, this.authService.currentUser.username);
      const userId = isOwner ? null : this.authService.currentUser.username;
      this.sharesService.getTeamShares(currentTeam, userId, params).subscribe(data => handleResp(data));
    } else {
      this.sharesService.getShares(this.authService.currentUser.username, params).subscribe(data => handleResp(data));
    }
  }

  getPastShares(params: HttpParams, isUserSearching?: boolean): void {
    const currentTeam = this.authService.getCurrentTeamId();
    const handleResp = (data) => {
      if (data && data.shares && data.shares.length > 0) {
        this.expiredShares = data.shares.map(x => ({...x, protectionCounter: this.calculateProtection(x)}));
        this.expiredPagination = data.pagination;
        this.noSearchResults = !isUserSearching;
      } else {
        this.expiredShares = new Array<any>();
        this.expiredPagination = null;
        this.noSearchResults = isUserSearching;
      }
    };

    if (currentTeam) {
      const isOwner = this.teamService.isOwner(currentTeam, this.authService.currentUser.username);
      const userId = isOwner ? null : this.authService.currentUser.username;
      this.sharesService.getTeamExpiredShares(currentTeam, userId, params);
    } else {
      this.sharesService.getExpiredShares(this.authService.currentUser.username, params).subscribe(data => handleResp(data));
    }
  }

  createShare(): void {
    const popup = this.dialog.open(CreateSharePopupComponent, {
      width: '95%',
      height: 'fit-content',
      maxWidth: '1100px',
      panelClass: 'popup-container',
    });
    popup.afterClosed().subscribe((created: any[]) => {
      if (created && created.length > 1) {
        this.activeShares.unshift(created);
      } else if (created && created.length === 1) {
        this.activeShares.unshift(created[0]);
      }
      if (this.activeShares.length === 10) {
        this.activeShares.pop();
      }
    });
  }
}


