<div class="page-container">
  <div class="purple-header">
    <div class="image-container">
      <img src="/assets/Assets Website/Logo/Credenshare-white.svg" />
    </div>
    <div class="login-form">
      <app-success *ngIf="slackOAuthVerifySuccess === 0"
                   title="Authorizing Slack..."
                   message="Please wait while we authorize your request..."
      ></app-success>
      <app-success *ngIf="slackOAuthVerifySuccess === 1"
                   title="Authorizing Slack..."
                   message="Your request has been authorized!"
                   imageUrl="/assets/Assets Website/Icn/share created.svg"
      ></app-success>
      <app-success *ngIf="slackOAuthVerifySuccess === -1"
                   title="Authorizing Slack..."
                   message="Oops... Authorize failed or cancelled, please try again."
                   imageUrl="/assets/Assets Website/Icn/shared expired.svg"
      ></app-success>
    </div>
    <p class="login-copyright">&copy; {{authService.currentYear}} CredenShare. All rights reserved.</p>
  </div>
</div>
