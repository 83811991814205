<div class="page-container">
    <div class="purple-header">
        <div class="image-container">
            <img src="/assets/Assets Website/Logo/Credenshare-white.svg" />
        </div>
        <div *ngIf="showLoginForm;else noLoginForm" class="login-form">
            <!-- <p class="social-media-title">Sign in via</p>
            <div class="social-media-buttons">
                <button class="google-button">
                    <img src="/assets/Assets Website/Social Icons/g.svg" />
                    Login with Google
                </button>
                <button class="facebook-button">
                    <img src="/assets/Assets Website/Social Icons/f.svg" />
                    Login with Facebook
                </button>
                <button class="twitter-button">
                    <img src="/assets/Assets Website/Social Icons/tw.svg" />
                    Login with Twitter
                </button>
            </div> -->
            <p class="form-title">Sign in</p>
            <form [formGroup]="loginForm" class="inputs-container" (submit)="login()">
                <input type="email" formControlName="email" placeholder="Email" [pattern]="validators.emailRegex" required/>
                <div class="password-field">
                    <input type="password" formControlName="password" placeholder="Password" required maxlength="40" />
                    <a class="forgot-pass-link" [routerLink]="['/reset-password']">Forgot?</a>
                </div>
                <button class="signin-button" type="submit">Login</button>
                <a class="resend-link" [routerLink]="['/reset-registration']">Resend confirmation link</a>
            </form>
            <div class="register-link">
                <p class="signup-title">Don't have an account?</p>
                <button class="signup-button" [routerLink]="['/register']">Sign Up</button>
            </div>
        </div>
        <ng-template #noLoginForm>
            <app-success *ngIf="slackBindSuccess === 0"
                title="Connecting slack"
                message="Please wait while we connect your slack account..."
            ></app-success>
            <app-success *ngIf="slackBindSuccess === 1"
                title="Connecting slack"
                message="Slack connecting was successful! Please try to create a secure share again."
                imageUrl="/assets/Assets Website/Icn/share created.svg"
            ></app-success>
            <app-success *ngIf="slackBindSuccess === -1"
                title="Connecting slack"
                message="Oops... We coudn't connect your slack account, please try again."
                imageUrl="/assets/Assets Website/Icn/shared expired.svg"
            ></app-success>
        </ng-template>
        <label class="resend-link tos-link">
            <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of service</a>
            and <a href="https://credenshare.io/privacy-policy" target="_blank">Privacy Policy</a>
        </label>
        <p class="login-copyright">&copy; {{authService.currentYear}} CredenShare. All rights reserved.</p>
    </div>
</div>
