import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InputValidators} from 'src/app/models/input-validators';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm-register',
  templateUrl: './confirm-register.component.html',
  styleUrls: ['./confirm-register.component.scss']
})
export class ConfirmRegisterComponent {
  validators = InputValidators;

  error: string;

  /** 0 for not set. 1 for success. -1 for error */
  verifySuccess: 0 | 1 | -1 = 0;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.checkCode();
  }

  checkCode(): void {
    this.authService.confirmUserAccount(decodeURIComponent(this.route.snapshot.queryParams['email']), this.route.snapshot.queryParams['code'], this.route.snapshot.queryParams['type']).then(res => {
      if (res && res.success) {
        this.verifySuccess = 1;
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3500);
      } else if (res && res.error) {
        this.verifySuccess = -1;
        this.error = res.error.message;
      }
    });
  }
}
