import {Injectable} from '@angular/core';
import {HttpCaller} from './http-caller.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  constructor(
    private httpCaller: HttpCaller,
  ) {
  }

  verifyCaptcha(captchaToken: string, showLoading: boolean = true): Observable<any> {
    return this.httpCaller.post('captcha/verify', null, {challenge: captchaToken}, showLoading).pipe(map((response: Response) => {
      return response as any;
    }));
  }
}
