import {Component, OnInit} from '@angular/core';
import {ActivationStart, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './services/auth.service';
import {UserDeviceService} from './services/user-device.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {TeamsService} from './services/teams.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CredenShare';
  hasSideMenu: boolean;
  showBeta: boolean = true;
  deviceType: number;

  constructor(
    private authService: AuthService,
    private teamService: TeamsService,
    private router: Router,
    private device: UserDeviceService,
    private titleService: Title,
  ) {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    // @TODO: only for beta
    if (this.router.config) {
      let routesWithSideMenu = this.router.config[0].children;
      router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.includes('widget-share')) {
          this.hasSideMenu = false;
          this.showBeta = false;
        } else {
          let url = event.urlAfterRedirects.slice(1, event.urlAfterRedirects.length);
          if (url.includes('?')) {
            url = url.split('?')[0];
          }
          const foundRouteIndex = routesWithSideMenu.findIndex(x => {
            return url.startsWith(x.path);
          });
          if (foundRouteIndex !== -1) {
            this.hasSideMenu = true;
          } else {
            this.hasSideMenu = false;
          }
        }
      });
    }

    // Inject gtag.js dynamically
    if (environment.gaMeasurementId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaMeasurementId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${environment.gaMeasurementId}');
    `;
      document.head.appendChild(gaScript);
    }
  }

  ngOnInit(): void {
    const subdomain = this.extractSubdomain(window.location.href);
    if (subdomain && subdomain.includes('paste')) {
      this.router.navigate(['/secure-paste-public'], {
        skipLocationChange: true,
      });
    }

    this.router.events.subscribe(async data => {
      if (data instanceof ActivationStart) {
        if (data.snapshot.data.title) {
          this.titleService.setTitle(data.snapshot.data.title);
        }
      }

      // This will check if the user is still part of the team
      if (data instanceof NavigationEnd) {
        const teamId = this.authService.getCurrentTeamId();
        if (teamId) {
          const userId = this.authService.currentUser.username;
          const teams = await this.teamService.getTeamsByUserId(userId).toPromise();
          let teamFound = false;
          if (teams) {
            for (const team of teams) {
              if (team.id === teamId) {
                teamFound = true;
                break;
              }
            }
          }

          if (!teamFound) {
            this.authService.switchTeam(null, true);
          }
        }
      }
    });
  }

  extractSubdomain(url: string): string {
    // Remove the protocol (http, https) from the URL if it exists.
    const hostname = url.replace(/(https?:\/\/)?(www\.)?/, '');

    // Split the hostname into parts.
    const parts = hostname.split('.');

    // Check if there is a subdomain present.
    if (parts.length >= 3) {
      // Assuming the URL structure is [subdomain].[domain].[TLD]
      const subdomain = parts[0];
      // Exclude 'www' as it's not considered a subdomain for your use-case
      return subdomain === 'www' ? null : subdomain;
    }

    return null;
  }
}
