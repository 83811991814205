import {Pagination} from './pagination.model';

export class Notification {
  ID: string;
  user_id: string;
  is_read: boolean;
  content: string;
  created_at: string;
  updated_at: string;
  link: string;
}

export class Notifications {
  notifications: Notification[];
  total_unread: number;
  pagination: Pagination;

  constructor() {
    this.pagination = new Pagination();
  }
}
