<div *ngIf="!isEmbedMode">
  <ng-container *ngTemplateOutlet="shareViewFullComponent"></ng-container>
</div>

<div *ngIf="isEmbedMode" class="h-full">
  <ng-container *ngTemplateOutlet="shareViewEmbedComponent"></ng-container>
</div>

<ng-template #shareViewEmbedComponent>
  <div class="flex flex-col h-full w-full">
    <div class="w-full bg-[#773eb1] h-14 flex items-center px-4">
      <a href="https://credenshare.io"><img src="/assets/Assets Website/Logo/Credenshare-white.svg" class="h-9"/></a>
    </div>
    <re-captcha #captchaRef size="invisible" (resolved)="$event && confirmCaptcha($event)"></re-captcha>
    <ng-container class="elements-wrapper">
      <div class="flex-1">
        <div class="credentials-box !m-0 !w-full !max-w-full !rounded-none p-5" *ngIf="shareBox; else expiredBox">
          <h3 class="!m-0">{{this.data && this.data.dialogMode ? 'SecureRequest Shared Submission Below' : 'Access Your Secure Share Information Below'}}</h3>
          <div class="spinner-wrapper" *ngIf="!share && !failedCaptcha"><div class="spinner"></div></div>
          <p *ngIf="failedCaptcha" class="failed-captcha">Captcha verification failed. Please refresh the page.</p>
          <p class="timed-view" *ngIf="share && share.timed_view">
            Timed View
            <br/>
            <span> {{timerValue}} seconds left</span>
          </p>
          <ng-container *ngIf="shareData">
            <div class="input-wrapper !w-full" *ngFor="let input of shareData; let i = index" [ngClass]="isMarkdownOrSourceCode(input) ? 'overflow-auto !p-0 !rounded-none' : ''">
              <span *ngIf="!isMarkdownOrSourceCode(input)">{{input.item}}</span>
              <input *ngIf="input.typeOfField !== 'multiline' && !isMarkdownOrSourceCode(input)" [id]="'user-input-' + i" [ngModel]="input.value"
                     [ngClass]="input.typeOfField === 'password' ? 'password-text' : 'regular-text'" readonly class="flex-1"/>
              <textarea *ngIf="input.typeOfField === 'multiline'" class="regular-text flex-1" placeholder="Please enter" [(ngModel)]="input.value" readonly>
                  </textarea>
              <div class="eye-container">
                <img class="eye-icon" *ngIf="input.typeOfField === 'password'"
                     (click)="dataInputType.resetInputType(i, false); showPassword = !showPassword"
                     [src]="showPassword ? '/assets/Assets Website/Btn/view-on.svg' : '/assets/Assets Website/Btn/view-off.svg'"
                     alt="show/hide password"/>
              </div>
              <div markdown [data]="input.value" class="markdown-body w-full" *ngIf="input.typeOfField === 'markdown'"></div>
              <ngx-codemirror *ngIf="input.typeOfField === 'source_code'" class="w-full" [(ngModel)]="input.value" [options]="{ theme: 'material', mode: input.selectedProgrammingLanguage, readOnly: true }"></ngx-codemirror>
              <div class="copy-container" *ngIf="!isMarkdownOrSourceCode(input)">
                <img (click)="copy(input.value)" src="/assets/Assets Website/Btn/bx-copy.svg" alt="copy"/>
              </div>
            </div>
            <div class="action-buttons !w-full gap-x-4">
              <button class="btn-default flex-1" (click)="copy(this.shareData[0].value)" *ngIf="isMarkdownOrSourceCode(this.shareData[0])"><img
                src="/assets/Assets Website/Btn/bx-copy.svg"/>Copy
              </button>
              <button class="btn-default flex-1" (click)="copyAll()" *ngIf="!isMarkdownOrSourceCode(this.shareData[0])"><img
                src="/assets/Assets Website/Btn/bx-copy.svg"/>Copy all
              </button>
              <button class="btn-default flex-1" (click)="exportToCSV()"><img
                src="/assets/Assets Website/Icn/arrow-right.svg"/>Export to CSV
              </button>
            </div>
          </ng-container>
        </div>

      </div>
    </ng-container>
    <ng-template #expiredBox>
      <app-success imageUrl="/assets/Assets Website/Icn/shared expired.svg"
                   [showDashboardLink]="false"
                   [title]="componentMessage ? componentMessage : 'Shared Credentials Expired'"
                   [message]="shareExpired ? 'The shared credentials have been scrubbed from our database and are no longer accessible.' : null"></app-success>
    </ng-template>

    <div class="w-full bg-[#773eb1] h-14 px-4 mt-2 text-center flex items-center justify-center">
      <div>
        <a href="https://credenshare.io/privacy-policy" class="text-white text-sm">Privacy Policy</a>
        <span class="text-white text-sm mx-2">|</span>
        <a href="https://credenshare.io/terms-of-service" class="text-white text-sm">Terms of Service</a>
        <div class="flex-1 text-white text-sm">© CredenShare Inc. 2024 - All Rights Reserved</div>
      </div>
    </div>
  </div>
  <mat-icon color="primary" class="share-view-dialog-close" (click)="this.dialog.closeAll()" *ngIf="this.data && this.data.dialogMode">close</mat-icon>
</ng-template>
<ng-template #shareViewFullComponent>
  <div class="page-container">
    <div class="left-side">
      <re-captcha #captchaRef size="invisible" (resolved)="$event && confirmCaptcha($event)"></re-captcha>
      <ng-container class="elements-wrapper">
        <div class="app-logo" *ngIf="!this.data || !this.data.dialogMode">
          <img src="/assets/Assets Website/Logo/Credenshare.svg"/>
        </div>
        <div class="credentials-box" *ngIf="shareBox; else expiredBox">
          <h3>{{this.data && this.data.dialogMode ? 'SecureRequest Shared Submission Below' : 'Access Your Secure Share Information Below'}}</h3>
          <div class="spinner-wrapper" *ngIf="!share && !failedCaptcha"><div class="spinner"></div></div>
          <p *ngIf="failedCaptcha" class="failed-captcha">Captcha verification failed. Please refresh the page.</p>
          <p class="timed-view" *ngIf="share && share.timed_view">
            Timed View
            <br/>
            <span> {{timerValue}} seconds left</span>
          </p>
          <ng-container *ngIf="shareData">
            <div class="input-wrapper" *ngFor="let input of shareData; let i = index" [ngClass]="isMarkdownOrSourceCode(input) ? 'overflow-auto !p-0 !rounded-none' : ''">
              <span *ngIf="!isMarkdownOrSourceCode(input)">{{input.item}}</span>
              <input *ngIf="input.typeOfField !== 'multiline' && !isMarkdownOrSourceCode(input)" [id]="'user-input-' + i" [ngModel]="input.value"
                     [ngClass]="input.typeOfField === 'password' ? 'password-text' : 'regular-text'" readonly/>
              <textarea *ngIf="input.typeOfField === 'multiline'" class="regular-text" placeholder="Please enter" [(ngModel)]="input.value" readonly>
                    </textarea>
              <div class="eye-container">
                <img class="eye-icon" *ngIf="input.typeOfField === 'password'"
                     (click)="dataInputType.resetInputType(i, false); showPassword = !showPassword"
                     [src]="showPassword ? '/assets/Assets Website/Btn/view-on.svg' : '/assets/Assets Website/Btn/view-off.svg'"
                     alt="show/hide password"/>
              </div>
              <div markdown [data]="input.value" class="markdown-body w-full" *ngIf="input.typeOfField === 'markdown'"></div>
              <ngx-codemirror *ngIf="input.typeOfField === 'source_code'" class="w-full" [(ngModel)]="input.value" [options]="{ theme: 'material', mode: input.selectedProgrammingLanguage, readOnly: true }"></ngx-codemirror>
              <div class="copy-container" *ngIf="!isMarkdownOrSourceCode(input)">
                <img (click)="copy(input.value)" src="/assets/Assets Website/Btn/bx-copy.svg" alt="copy"/>
              </div>
            </div>
            <div class="action-buttons">
              <button class="btn-default" (click)="copy(this.shareData[0].value)" *ngIf="isMarkdownOrSourceCode(this.shareData[0])"><img
                src="/assets/Assets Website/Btn/bx-copy.svg"/>Copy
              </button>
              <button class="btn-default" (click)="copyAll()" *ngIf="!isMarkdownOrSourceCode(this.shareData[0])"><img
                src="/assets/Assets Website/Btn/bx-copy.svg"/>Copy all
              </button>
              <button class="btn-default" (click)="exportToCSV()"><img
                src="/assets/Assets Website/Icn/arrow-right.svg"/>Export to CSV
              </button>
            </div>
            <div class="action-buttons" *ngIf="!share.only_owner_can_delete">
              <button class="btn-danger" (click)="deleteShare()">Delete the Secure Share Forever</button>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #expiredBox>
        <app-success imageUrl="/assets/Assets Website/Icn/shared expired.svg"
                     [showDashboardLink]="false"
                     [title]="componentMessage ? componentMessage : 'Shared Credentials Expired'"
                     [message]="shareExpired ? 'The shared credentials have been scrubbed from our database and are no longer accessible.' : null"></app-success>
      </ng-template>
    </div>
    <div class="right-side" *ngIf="!this.data || !this.data.dialogMode">
      <div class="right-side-inner">
        <div class="app-logo">
          <img src="/assets/Assets Website/Logo/Credenshare-white.svg"/>
        </div>
        <div class="description-wrapper">
          <p class="description">Did you know that one of the leading causes for data breaches can be traced back to
            password compromise?

            With CredenShare, Share your sensitive credentials securely without risk of compromise. Whether you send them
            over email, text message or restaurant napkin, rest assured that we keep them safe until you're done with
            sharing.</p>
          <div class="buttons-box">
            <button *ngIf="!isLoggedIn" (click)="goToLogin()" class="login">Login</button>
            <button *ngIf="!isLoggedIn" [routerLink]="['/register']" class="new-acc">Create free account</button>
            <button *ngIf="isLoggedIn" [routerLink]="['/']" class="new-acc">Back to dashboard</button>
            <button class="more-info">More Info</button>
          </div>
        </div>

        <div class="logout-button">
          <label>
            <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of service</a>
            and <a href="https://credenshare.io/privacy-policy" target="_blank">Privacy Policy</a>
          </label>
        </div>
      </div>
    </div>
    <mat-icon color="primary" class="share-view-dialog-close" (click)="this.dialog.closeAll()" *ngIf="this.data && this.data.dialogMode">close</mat-icon>
  </div>
</ng-template>

