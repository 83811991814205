<mat-icon color="primary" class="notification-icon" [matBadgeHidden]="!notifications || notifications.total_unread <= 0" [matBadge]="notifications ? notifications.total_unread : ''" matBadgeColor="warn" [matMenuTriggerFor]="menu">
  notifications
</mat-icon>
<mat-menu #menu="matMenu" xPosition="before" class="notification-menu" (closed)="handleMenuClosed($event)">
  <div class="notification-content no-notifications" *ngIf="!notifications || !notifications.notifications || notifications.notifications.length === 0">
    <span class="content">No notifications</span>
  </div>
  <button mat-menu-item *ngFor="let n of this.notifications.notifications; let i = index" (click)="goToSecureRequest(n)" class="notification-item">
    <mat-icon color="primary" class="notification-unread-icon" [style]="n.is_read ? 'visibility: hidden' : ''">circle</mat-icon>
    <div class="notification-content">
      <span class="content">{{n.content}}</span>
      <span class="sub-content">{{n.created_at | date: 'MMM dd, yyyy, hh:mm:ss' }}</span>
    </div>
  </button>
  <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"  *ngIf="notifications && notifications.notifications && notifications.notifications.length > 0">
    <mat-paginator [length]="notifications ? notifications.pagination.totalRecords : 0" [hidePageSize]="true" [pageSize]="10" [pageIndex]="pageIndex" aria-label="Select page" (page)="handlePageEvent($event)" >
    </mat-paginator>
  </div>
</mat-menu>

