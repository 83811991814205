<div class="top-bar">
  <div class="ribbon ribbon-top-left">
    <span>BETA</span>
  </div>
  <h1 *ngIf="title" class="page-title">{{title}}</h1>
  <div class="profile-and-share">
    <app-notification-badge></app-notification-badge>
    <div [ngClass]="isInAccount ? 'profile-picture-container-account' : 'profile-picture-container'">
      <span *ngIf="!isInAccount">
        <button mat-button [matMenuTriggerFor]="userProfile">
          <img class="profile-image" src="/assets/avatar.jpg"/>
        </button>
          <mat-menu #userProfile="matMenu">
          <button mat-menu-item [routerLink]="['/user-account']">My Profile</button>
          <button mat-menu-item [matMenuTriggerFor]="teamsMenu">Switch Team</button>
        </mat-menu>

        <mat-menu #teamsMenu="matMenu">
          <button mat-menu-item (click)="this.authService.switchTeam(null)">
            <mat-icon *ngIf="!this.authService.getCurrentTeamId()">check</mat-icon>
            <span>No Team</span>
          </button>
          <div *ngFor="let t of teams">
            <button mat-menu-item (click)="this.authService.switchTeam(t.id)">
              <mat-icon *ngIf="this.authService.getCurrentTeamId() === t.id">check</mat-icon>
              <span>{{t.name}}</span>
            </button>
          </div>
        </mat-menu>
      </span>

      <img class="profile-image" src="/assets/avatar.jpg" *ngIf="isInAccount"/>
      <div *ngIf="isInAccount" class="mask">
        <!-- fileUpload.click() -->
        <img class="upload-handler" src="/assets/Assets Website/Btn/settings-photo.svg"/>
        <span>Change Photo</span>
      </div>
    </div>
  </div>
</div>
