import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from '../shared/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private overlayRef: OverlayRef;
  private showingLoader: boolean;

  constructor(public overlay: Overlay) { }

  showLoading(): void {
    if (!this.showingLoader) {
      const config = new OverlayConfig({
          hasBackdrop: true,
          backdropClass: 'overlay-dark-background',
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
      });

      this.overlayRef = this.overlay.create(config);
      this.overlayRef.attach(new ComponentPortal(LoaderComponent));
      this.showingLoader = true;
    }
  }

  hideLoading(): void {
    if (this.overlayRef && this.showingLoader) {
        this.showingLoader = false;
        this.overlayRef.detach();
    }
  }
}
