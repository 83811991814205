import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {InputValidators} from '../../models/input-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  passReset:boolean = false;
  validators = InputValidators;
  error: string;
  success: boolean;

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  resetPassword(): void {
    if (!this.validators.emailRegex.test(this.email) || !this.email) {
      this.error = 'Please provide a valid email';
      return;
    } else {
      this.authService.forgotPasswordEmail(this.email).then(res => {
        if (res && res.success) {
          this.passReset = true;
        } else if (res && res.error) {
          this.error = res.error.message;
        }
      });
    }
  }

}
