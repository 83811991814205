<div class="page-container">
  <div class="purple-header">
    <div class="image-container">
      <img src="/assets/Assets Website/Logo/Credenshare-white.svg"/>
    </div>
    <app-success *ngIf="verifySuccess === 0"
                 title="Verifying your email..."
                 message="Please wait while we verify your email..."
    ></app-success>
    <app-success *ngIf="verifySuccess === 1"
                 title="Your email is confirmed"
                 message="We will now redirect you to the login page"
                 imageUrl="/assets/Assets Website/Icn/share created.svg"
    ></app-success>
    <app-success *ngIf="verifySuccess === -1"
                 title="Verification failed"
                 message="We are unable to verify your email, please try again."
                 imageUrl="/assets/Assets Website/Icn/shared expired.svg"
    ></app-success>
    <p class="login-copyright">&copy; {{authService.currentYear}} CredenShare. All rights reserved.</p>
  </div>
</div>
