<div class="popup-header">
  <h3 class="popup-title">Add Member</h3>
  <div class="templates-box">
    <div (click)="close()" class="close-icon"><img src="/assets/Assets%20Website/Btn/close.svg"></div>
  </div>
</div>
<form [formGroup]="newMemberForm" (ngSubmit)="(this.data.emitEventOnly) ? addNewMemberToList() : addNewMember()">
  <div class="form-container">
    <div class="input-container">
      <input type="text" placeholder="Enter email..." required formControlName="email" [pattern]="validators.emailRegex"/>
    </div>
  </div>
  <div class="confirm-buttons">
    <button (click)="close()" type="button" class="button-secondary">Close</button>
    <button class="button-default" type="submit" [disabled]="!newMemberForm.valid">Add</button>
  </div>
</form>

