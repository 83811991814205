export const CURRENT_SELECTED_TEAM = 'currentTeam';

export enum TeamServiceEvent {
  Add,
  Delete,
}

export class TeamEventPayload {
  type: TeamServiceEvent;
  data: string;
}

export class Team {
  id: string;
  name: string;
  domain: string;
  configurations: string;
  users: TeamUser[];
}

export class TeamUser {
  user_id: string;
  email: string;
  full_name: string;
  role: string;
  confirmed: boolean;
  expired_at?: any;
  note?: any;
  configurations?: string;
}

export class TeamInviteConfirmParams {
  teamId: string;
  token: string;
}
