import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceService {
  deviceType = new BehaviorSubject<number>(null);

  constructor() {
    this.checkDeviceType(window.innerWidth);
    window.addEventListener("resize", this.catchResizeEvent)
  }

  private catchResizeEvent = (event) => {
    this.checkDeviceType(event.target.innerWidth);
  }

  private checkDeviceType(width: number) {
    //setting up the device type
    //0 is for mobile, 1 is for desktop
    let typeValue;
    if (width <= 750) {
      typeValue = 0;
    } else {
      typeValue = 1;
    }

    if (this.deviceType.value !== typeValue) {
      this.deviceType.next(typeValue);
    }
  }
}
