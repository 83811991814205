<div class="popup-header">
  <h3 class="popup-title">Create Secure Share</h3>
  <div class="templates-box">
    <!-- <select>
        <option>Select template</option>
    </select> -->
    <div (click)="close()" class="close-icon"><img src="/assets/Assets Website/Btn/close.svg"></div>
  </div>
</div>
<div class="form-container" *ngIf="!isShareCreated else successScreen">
    <app-notification *ngIf="this.maxFields !== '-1'"
      [message]="'Your subscription allows ' + this.maxFields + ' fields to share. <strong>Change your plan</strong> to add more'">
    </app-notification>

  <div class="share-name-container">
    <input id="name-input"
           [ngClass]="formErrors && formErrors.nameError ? 'red-border' : ''" class="name-input" type="text"
           [(ngModel)]="shareForm.name" placeholder="Enter secure share's name..." maxlength="255"/>
  </div>
  <div class="share-name-container">
    <input class="name-input" type="text" [(ngModel)]="shareForm.description"
           placeholder="Enter secure share's description..."/>
  </div>
  <app-quick-share [is-quick-share]="false"></app-quick-share>
  <div class="share-settings">
    <h4>Settings</h4>
    <section class="settings-group">
      <div class="setting">
        <p class="label">Expires in</p>
        <div class="toggles">
          <button type="button" (click)="updateExpirationType(0, 24)"
                  [ngClass]="shareForm.expirationType === 0 ? 'button-default' : 'button-secondary'">Hours
          </button>
          <button type="button" (click)="updateExpirationType(1, 31)"
                  [ngClass]="shareForm.expirationType === 1 ? 'button-default' : 'button-secondary'">Days
          </button>
          <button type="button" (click)="updateExpirationType(2, 3)"
                  [ngClass]="shareForm.expirationType === 2 ? 'button-default' : 'button-secondary'">Months
          </button>
        </div>
      </div>
      <div class="setting">
        <div class="label"></div>
        <div class="slider">
          <span class="slider-label">1</span>
          <mat-slider [(ngModel)]="shareForm.expirationDate" min="1"
                      [max]="shareForm.expirationType === 0 ? '24' : shareForm.expirationType === 1 ? '31' : '3'"
                      step="1" thumbLabel="true">
          </mat-slider>
          <span class="slider-label">{{
              shareForm.expirationType === 0 ? '24' : shareForm.expirationType === 1
                ?
                '31' : '3'
            }}</span>
        </div>
      </div>
      <div class="setting">
        <div class="label"></div>
        <div class="slider">
          <p class="expire-note">
            This secure share expires in
            {{ shareForm.expirationDate }} {{ shareForm.expirationType | expirationDateText : shareForm.expirationDate }}
          </p>
        </div>
      </div>
      <div class="setting">
        <div class="label"></div>
        <div class="toggles">
          <button type="button" class="expire-from" (click)="shareForm.expireFrom = 0"
                  [ngClass]="shareForm.expireFrom === 0 ? 'button-default' : 'button-secondary'">From
            Creation
          </button>
          <button type="button" class="expire-from" (click)="shareForm.expireFrom = 1"
                  [ngClass]="shareForm.expireFrom === 1 ? 'button-default' : 'button-secondary'">From First
            View
          </button>
        </div>
      </div>
    </section>

    <section class="settings-group">
      <div class="setting">
        <div class="label checkbox-label">
          <input type="checkbox" [(ngModel)]="shareForm.viewingTimes"/>
          <label>Number of viewing times before deletion</label>
        </div>
        <div class="toggles limitation">
          <button [disabled]="!shareForm.viewingTimes" type="button"
                  [ngClass]="!shareForm.unlimitedViewTime ? 'button-default' : 'button-secondary'"
                  (click)="shareForm.unlimitedViewTime = false">Limited
          </button>
          <button [disabled]="!shareForm.viewingTimes" type="button"
                  [ngClass]="shareForm.unlimitedViewTime ? 'button-default' : 'button-secondary'"
                  (click)="shareForm.unlimitedViewTime = true">Unlimited
          </button>
        </div>
      </div>
      <div class="setting">
        <div class="label"></div>
        <div class="slider">
          <span class="slider-label">1</span>
          <mat-slider [(ngModel)]="shareForm.viewingTimesValue"
                      [disabled]="!shareForm.viewingTimes || shareForm.unlimitedViewTime" min="1" max="10" step="1"
                      thumbLabel="true">
          </mat-slider>
          <span class="slider-label">10</span>
        </div>
      </div>
      <div class="setting">
        <div class="label"></div>
        <div class="slider">
          <p class="expire-note">
            This secure share can be
            viewed {{ !shareForm.viewingTimes || shareForm.unlimitedViewTime ? 'unlimited' : shareForm.viewingTimesValue }}
            times
          </p>
        </div>
      </div>
    </section>

    <!-- <div class="setting">
        <p class="label">Encryption Type</p>
        <div class="slider">
            <select id="encryption-group" [(ngModel)]="shareForm.encryptionType">
                <option value="aes128">aes128</option>
                <option value="aes256">aes256</option>
            </select>
        </div>
    </div> -->

    <section class="settings-group" *ngIf="userPlan.plan.features[3].feature_value === 'true'">
      <div class="setting">
        <div class="label checkbox-label">
          <input type="checkbox" [(ngModel)]="shareForm.askPassword"
                 (ngModelChange)="passwordSelected($event)"/>
          <label> Ask password to view <br/>
            <p class="label-instructions">When you are ready to view the password and ensured that you are
              in a
              secure location, please click on the View Button</p>
          </label>
        </div>
        <div class="slider">
          <div class="share-password-input" [ngClass]="formErrors && formErrors.passwordError ? 'red-border' : ''">
            <input placeholder="Type a password for protection" id="password-input"
                   (keyup)="checkSecurity($event)" [(ngModel)]="shareForm.passwordValue"
                   [disabled]="!shareForm.askPassword" maxlength="100"
                   autocomplete="new-password" [ngClass]="!showPassword ? 'password-text' : 'regular-text'"/>

            <img (click)="showPassword = !showPassword"
                 [src]="!showPassword ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'"/>
          </div>
        </div>
      </div>
      <div class="setting">
        <div class="label"></div>
        <div class="slider">
          <app-password-strength [score]="generalPasswordStrength?.passwordScore"
                                 [strength-data]="generalPasswordStrength?.passwordStrength"></app-password-strength>
        </div>
      </div>
    </section>

    <div class="setting" *ngIf="userPlan.plan.features[3].feature_value === 'true'">
      <div class="label checkbox-label">
        <input type="checkbox" [disabled]="!shareForm.askPassword"
               [(ngModel)]="shareForm.failedAttemptsTolerance"/>
        <label>Failed attempts tolerance before deletion</label>
      </div>
      <div class="slider">
        <span class="slider-label">1</span>
        <mat-slider [(ngModel)]="shareForm.failedAttemptsValue" [disabled]="!shareForm.failedAttemptsTolerance"
                    min="1" max="10" step="1" thumbLabel="true"></mat-slider>
        <span class="slider-label">10</span>
      </div>
    </div>
    <div class="setting" *ngIf="userPlan.plan.features[3].feature_value === 'true' && shareForm.askPassword">
      <div class="label"></div>
      <div class="slider">
        <p class="expire-note">
          This secure share will be deleted after {{ shareForm.failedAttemptsValue }} times of entering wrong password
        </p>
      </div>
    </div>
    <div class="setting">
      <div class="label checkbox-label">
        <input type="checkbox" [(ngModel)]="shareForm.timedView"/>
        <label>Timed View</label>
      </div>
      <div class="slider">
        <span class="slider-label">15s</span>
        <mat-slider [(ngModel)]="shareForm.timedViewValue" [disabled]="!shareForm.timedView" min="15" max="180"
                    step="5" thumbLabel="true"></mat-slider>
        <span class="slider-label">180s</span>
      </div>
    </div>
    <div class="setting" *ngIf="shareForm.timedView">
      <div class="label"></div>
      <div class="slider">
        <p class="expire-note">
          This secure share will be expired in {{ shareForm.timedViewValue }} seconds after open.
        </p>
      </div>
    </div>
    <!-- <div class="setting">
        <div class="label checkbox-label">
            <input type="checkbox" [(ngModel)]="shareForm.directSave" />
            <label>Direct Save to Password Manager</label>
        </div>
    </div> -->
    <div class="setting" *ngIf="userPlan.plan.features[4].feature_value === 'true'">
      <div class="label checkbox-label">
        <input type="checkbox" [(ngModel)]="shareForm.secureView"/>
        <label>Secure view prompt</label>
      </div>
      <div class="slider">
                <textarea placeholder="Type a custom message for the secure view prompt"
                          [(ngModel)]="shareForm.secureViewMessage" [disabled]="!shareForm.secureView"
                          class="secure-view-message" draggable="false"></textarea>
      </div>
    </div>
    <div class="setting">
      <div class="label checkbox-label">
        <input type="checkbox" [(ngModel)]="shareForm.requireLogin"/>
        <label>Require Login</label>
      </div>
    </div>
    <div class="setting">
      <div class="label checkbox-label">
        <input type="checkbox" [(ngModel)]="shareForm.onlyOwnerCanDelete"/>
        <label>Only Owner Can Delete</label>
      </div>
    </div>
    <!-- <div class="setting">
        <div class="label checkbox-label">
            <input type="checkbox" [(ngModel)]="shareForm.requireMfa" />
            <label>Require MFA</label>
        </div>
    </div>
    <div class="setting restriction-settings" *ngIf="userPlan.plan.features[5].feature_value === 'true'">
        <div class="ip-restrictions">
            <label>IP Whitelist <br />
                <p *ngIf="shareForm.ipWhitelist && shareForm.ipWhitelist.length === 0" class="label-instructions">
                    Not defined</p>
                <ul class="whitelist-items" *ngIf="shareForm.ipWhitelist && shareForm.ipWhitelist.length > 0">
                    <li *ngFor="let ip of shareForm.ipWhitelist">{{ip}}</li>
                </ul>
            </label>
            <button class="button-secondary" (click)="defineWhitelist(0)">Define</button>
        </div>
        <div class="domain-restrictions">
            <label>Domain Whitelist <br />
                <p *ngIf="shareForm.domainWhitelist && shareForm.domainWhitelist.length === 0"
                    class="label-instructions">Not defined</p>
                <ul class="whitelist-items"
                    *ngIf="shareForm.domainWhitelist && shareForm.domainWhitelist.length > 0">
                    <li *ngFor="let domain of shareForm.domainWhitelist">{{domain}}</li>
                </ul>
            </label>
            <button class="button-secondary" (click)="defineWhitelist(1)">Define</button>
        </div>
    </div> -->
  </div>
</div>

<hr/>

<div class="form-buttons" *ngIf="!isShareCreated else successButtons">
  <div class="buttons-container">
    <button (click)="close()" class="button-secondary">Cancel</button>
    <button class="button-default" (click)="createShare()">Create</button>
  </div>
</div>

<ng-template #successScreen>
  <div class="success-container">
    <img class="success-image" src="/assets/Assets Website/Icn/share created.svg"/>
    <h1 class="success-title">Secure Share Created</h1>
    <div class="share-fields">
      <div *ngIf="createdShare.shareLink" class="sharing-link">
        <span>Link to secure share</span>
        <div class="input-box">
          <input [ngModel]="createdShare.shareLink" readonly/>
          <img (click)="copy(createdShare.short_code, 0)" src="/assets/Assets Website/Btn/bx-copy.svg"/>
        </div>
      </div>
      <div *ngIf="createdShare.password_protected" class="sharing-password">
        <span>Password</span>
        <div class="input-box">
          <input readonly [ngClass]="!showSharePassword ? 'password-text' : 'regular-text'"
                 [ngModel]="shareForm.passwordValue"/>
          <img class="eye-icon" (click)="showSharePassword = !showSharePassword"
               [src]="!showSharePassword ? '/assets/Assets Website/Btn/view-off.svg' : '/assets/Assets Website/Btn/view-on.svg'"/>
          <img (click)="copy(shareForm.passwordValue, 1)" src="/assets/Assets Website/Btn/bx-copy.svg"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successButtons>
  <div class="success-buttons-container">
    <button class="button-default create-another" (click)="resetScreen()">Create another secure share</button>
    <button (click)="close()" class="button-secondary">Close</button>
  </div>
</ng-template>
