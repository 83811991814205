import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/internal/operators';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {TeamsService} from '../../services/teams.service';
import {Team, TeamServiceEvent} from '../../models/team.model';

@Component({
  selector: 'app-desktop-top-bar',
  templateUrl: './desktop-top-bar.component.html',
  styleUrls: ['./desktop-top-bar.component.scss']
})
export class DesktopTopBarComponent implements OnInit {
  isInAccount: boolean = false;
  title: string;
  teams: Team[] = new Array<Team>();

  constructor(
    private router: Router,
    private dataService: DataService,
    private dialog: MatDialog,
    public authService: AuthService,
    private teamsService: TeamsService,
  ) {
  }

  ngOnInit(): void {
    this.checkIfInAccount().subscribe(bool => {
      this.isInAccount = bool;
    });

    this.dataService.pageTitle.subscribe(res => {
      setTimeout(() => {
        this.title = res;
      }, 0);
    }, err => {
      console.log('error getting title', err);
    });

    this.pullTeams();
    this.teamsService.teamServiceEvent.subscribe((eventPayload) => {
      this.pullTeams();
      if (eventPayload.type === TeamServiceEvent.Delete && this.authService.getCurrentTeamId() === eventPayload.data) {
        this.authService.switchTeam(null);
      }
    });
  }

  uploadPhoto(event) {
    console.log(event);
  }

  checkIfInAccount(): Observable<any> {
    return this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(map(({urlAfterRedirects}: NavigationEnd) => {
      if (urlAfterRedirects === '/user-account') {
        return true;
      }
    }));
  }

  private pullTeams(): void {
    this.teamsService.getTeamsByUserId(this.authService.currentUser.username).subscribe(res => {
      if (res) {
        this.teams = new Array<Team>();
        const teamRes = res as Team[];
        teamRes.forEach(t => {
          t.users.forEach(u => {
            if (u.user_id === this.authService.currentUser.username && u.confirmed) {
              this.teams.push(t);
            }
          });
        });
      }
    }, err => {
      this.authService.switchTeam(null, true);
    });
  }
}
