import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TeamUser} from '../../../../models/team.model';
import {FormControl, FormGroup} from '@angular/forms';
import {InputValidators} from '../../../../models/input-validators';
import {TeamsService} from '../../../../services/teams.service';
import {ConfirmPopupComponent} from '../../../../shared/confirm-popup/confirm-popup.component';
import {AuthService} from '../../../../services/auth.service';
import {SubscriptionPlansService} from '../../../../services/subscription-plans.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  @Output('newMemberEvent') newMemberEvent: EventEmitter<TeamUser> = new EventEmitter<TeamUser>();
  newMemberForm: FormGroup;
  validators = InputValidators;

  constructor(
    private dialogRef: MatDialogRef<AddMemberComponent>,
    private dialog: MatDialog,
    private teamsService: TeamsService,
    private authService: AuthService,
    private subscriptionPlansService: SubscriptionPlansService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.newMemberForm = new FormGroup({
      email: new FormControl(''),
    });
  }

  ngOnInit(): void {

  }

  close(): void {
    this.dialogRef.close();
  }

  /**
   * Add new member to list, it won't call any Rest endpoint
   */
  addNewMemberToList(): void {
    const teamUser = new TeamUser();
    teamUser.email = this.newMemberForm.value.email;
    this.newMemberEvent.emit(teamUser);
    this.close();
  }

  addNewMember(): void {
    (async () => {
      const previewResp = await this.teamsService.inviteMemberPreview(this.authService.currentUser.username, [this.newMemberForm.value.email]).toPromise();
      const userPlan = this.authService.userPlan;
      const billingPeriod = (userPlan.plan.plan_code.includes('yearly')) ? 'year' : 'month';
      let userAddonPrice = 0;
      userPlan.plan.addons.forEach(a => {
        if (a.addon_code === 'addon_user') {
          userAddonPrice = a.price;
        }
      });

      const message = `
        You are adding <strong>${previewResp.total_new_members}</strong> new member to your team and your team currently has <strong>${previewResp.total_pending_members}</strong> pending invitation. <br />
        You will be charged total of <strong>$${userAddonPrice}/user/${billingPeriod}</strong> for each member that accepts your invitation. <br />
        The estimated pro-rated amount once all your member invitations are confirmed is <strong>$${previewResp.estimated_payment}</strong>. <br />
        To confirm, click the "Confirm" button below.`;

      const confirm = this.dialog.open(ConfirmPopupComponent, {
        width: '70%',
        height: 'fit-content',
        panelClass: 'popup-container',
        data: {header: 'Confirm', message, noCancelButton: true}
      });

      confirm.afterClosed().subscribe(confirmedClose => {
        if (confirmedClose) {
          this.teamsService.inviteMember(this.data.team.id, this.newMemberForm.value.email).subscribe(resp => {
            if (resp) {
              this.addNewMemberToList();
            }
          });
        }
      });
    })();
  }

}
