export class Pagination {
  limit: number;
  nextPage: number;
  offset: number;
  page: number;
  prevPage: number;
  totalPage: number;
  totalRecords: number;

  constructor() {
    this.totalRecords = 0;
  }
}
