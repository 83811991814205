import {Component, OnInit} from '@angular/core';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-expires-in',
  templateUrl: './expires-in.component.html',
  styleUrls: ['./expires-in.component.scss']
})
export class ExpiresInComponent implements OnInit {

  expirationType: number = 0;
  expirationDate: number = 2;
  expireFrom: number = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  updateExpirationType(type: number, maxValue: number): void {
    this.expirationType = type;
    if (this.expirationDate > maxValue) {
      this.expirationDate = maxValue;
    }
  }

  calculateExpiration(): string {
    if (this.expireFrom === 0) {
      /**
       * expirationType(0) = HOURS
       * expirationType(1) = DAYS
       * expirationType(2) = MONTHS
       */
      switch (this.expirationType) {
        case 0: {
          return moment().add(this.expirationDate, 'hours').toISOString();
        }
        case 1: {
          return moment().add(this.expirationDate, 'days').toISOString();
        }
        case 2: {
          return moment().add(this.expirationDate, 'months').toISOString();
        }
      }
    } else {
      return null;
    }
  }

  calculateSeconds(): number {
    if (this.expireFrom === 0) {
      return null;
    } else {
      switch (this.expirationType) {
        case 0: {
          return Math.ceil(3600 * this.expirationDate);
        }
        case 1: {
          return Math.ceil(86400 * this.expirationDate);
        }
        case 2: {
          return Math.ceil(2629743.83 * this.expirationDate);
        }
      }
    }
  }

  reset(): void {
    this.expirationType = 0;
    this.expirationDate = 2;
    this.expireFrom = 0;
  }
}
