import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AddMemberComponent} from '../add-member/add-member.component';
import {Team, TeamUser} from '../../../../models/team.model';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {TeamsService} from '../../../../services/teams.service';
import {forkJoin, Observable} from 'rxjs';
import {ConfirmPopupComponent} from '../../../../shared/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateTeamComponent implements OnInit {

  teamUsers: TeamUser[] = new Array<TeamUser>();
  newTeamForm: FormGroup;

  @Output('newTeamEvent') newTeamEvent: EventEmitter<Team> = new EventEmitter<Team>();

  constructor(
    private dialogRef: MatDialogRef<CreateTeamComponent>,
    private dialog: MatDialog,
    private authService: AuthService,
    private teamsService: TeamsService,
  ) {
    this.newTeamForm = new FormGroup({
      teamName: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  showAddMemberDialog(): void {
    const addMemberDialogRef = this.dialog.open(AddMemberComponent, {
      width: '70%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {
        // This will tell dialog only emit event when adding new email
        // It won't call any Rest API to invite new member.
        // This is helpful during team creation where new users can be added temporary to the list.
        emitEventOnly: true,
      }
    });

    addMemberDialogRef.componentInstance.newMemberEvent.subscribe(resp => {
      if (resp) {
        this.addNewMemberToList(resp);
      }
    });
  }

  addNewMemberToList(teamUser: TeamUser): void {
    this.teamUsers.push(teamUser);
  }

  removeNewMemberFromList(index: number): void {
    this.teamUsers.splice(index, 1);
  }

  createNewTeam(): void {
    (async () => {
      const emails = new Array<string>();
      this.teamUsers.forEach(t => {
        emails.push(t.email);
      });

      if (emails.length > 0) {
        const previewResp = await this.teamsService.inviteMemberPreview(this.authService.currentUser.username, emails).toPromise();
        const userPlan = this.authService.userPlan;
        const billingPeriod = (userPlan.plan.plan_code.includes('yearly')) ? 'year' : 'month';
        let userAddonPrice = 0;
        userPlan.plan.addons.forEach(a => {
          if (a.addon_code === 'addon_user') {
            userAddonPrice = a.price;
          }
        });

        const message = `
        You are adding <strong>${previewResp.total_new_members}</strong> new member to your team and your team currently has <strong>${previewResp.total_pending_members}</strong> pending invitation. <br />
        You will be charged total of <strong>$${userAddonPrice}/user/${billingPeriod}</strong> for each member that accepts your invitation. <br />
        The estimated pro-rated amount once all your member invitations are confirmed is <strong>$${previewResp.estimated_payment}</strong>. <br />
        To confirm, click the "Confirm" button below.`;

        const confirm = this.dialog.open(ConfirmPopupComponent, {
          width: '70%',
          height: 'fit-content',
          panelClass: 'popup-container',
          data: {header: 'Confirm', message, noCancelButton: true}
        });

        confirm.afterClosed().subscribe(confirmedClose => {
          if (confirmedClose) {
            this.teamsService.addNewTeam(this.authService.currentUser.username, this.newTeamForm.value.teamName).subscribe(resp => {
              if (resp) {
                const promises = new Array<Observable<any>>();
                this.teamUsers.forEach(t => {
                  promises.push(this.teamsService.inviteMember(resp.id, t.email));
                });

                forkJoin(promises).subscribe(() => {
                }, () => {
                }, () => {
                  this.newTeamEvent.emit(resp);
                  this.close();
                });
              }
            });
          }
        });
      } else {
        this.teamsService.addNewTeam(this.authService.currentUser.username, this.newTeamForm.value.teamName).subscribe(resp => {
          if (resp) {
            this.newTeamEvent.emit(resp);
            this.close();
          }
        });
      }

    })();
  }
}
