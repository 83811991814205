import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  @Input('imageUrl') imageUrl: string;
  @Input('title') title: string;
  @Input('message') message: string;
  @Input('showDashboardLink') showDashboardLink = true;

  constructor() { }

  ngOnInit(): void {
  }

}
