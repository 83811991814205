import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-misc-share-settings',
  templateUrl: './misc.component.html',
  styleUrls: ['./misc.component.scss']
})
export class MiscComponent implements OnInit {

  timedViewEnabled: FormControl = new FormControl(false);
  timedViewValue = 30;
  requireLoginEnabled: FormControl = new FormControl(false);
  onlyOwnerCanDeleteEnabled: FormControl = new FormControl(false);

  constructor() { }

  ngOnInit(): void {
  }

  reset(): void {
    this.timedViewEnabled.reset(false);
    this.timedViewValue = 30;
    this.requireLoginEnabled.reset(false);
    this.onlyOwnerCanDeleteEnabled.reset(false);
  }
}
