import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {SlackService} from '../../services/slack.service';
import {InputValidators} from '../../models/input-validators';
import {SlackModel} from '../../models/slack.model';
import {LoaderService} from 'src/app/services/loader.service';
import {ToastrService} from 'ngx-toastr';

import {MatDialog} from '@angular/material/dialog';
import {TermsAndServicesComponent} from '../../shared/terms-and-services-popup/terms-and-services-popup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  validators = InputValidators;
  showLoginForm: boolean;
  slackParams: SlackModel;
  /** 0 for not set. 1 for success. -1 for error */
  slackBindSuccess: 0 | 1 | -1 = 0;

  constructor(
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private slackService: SlackService,
    private notificationsService: ToastrService,
    private dialog: MatDialog
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl(),
      password: new FormControl()
    });

    this.loaderService.showLoading();
    this.route.queryParams.subscribe(params => {
      this.slackParams = params as SlackModel;
      if (this.slackParams.serviceType === 'slack') {
        this.authService.isUserAuthenticated(false).then(res => {
          if (res) {
            this.confirmSlackBindingToken();
          } else {
            this.showLoginForm = true
          }
          this.loaderService.hideLoading();
        })
      } else {
        this.showLoginForm = true;
        this.loaderService.hideLoading();
      }
    });
  }

  login(): void {
    this.loaderService.showLoading();
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).then(res => {
        if (res.success) {
          if (this.slackParams.serviceType === 'slack') {
            this.showLoginForm = false;
            this.confirmSlackBindingToken();
          } else {
            const redirectUrl = this.route.snapshot.queryParams['redirect_url'];
            if (redirectUrl) {
              window.location.replace(redirectUrl);
            } else if (this.router.url.includes('from-share')) {
              this.router.navigateByUrl(this.route.snapshot.params['short-code']);
            } else if (this.router.url.includes('from-secure-request')) {
              this.router.navigateByUrl('r/' + this.route.snapshot.params['short-code']);
            } else {
              this.router.navigate(['/']);
            }
          }
        } else if (res && res.error) {
          this.notificationsService.error(res.error.message);
          if (res.showTosPopup) {
            this.dialog.open(TermsAndServicesComponent, {
              width: '50%',
              height: 'fit-content',
              panelClass: 'popup-container',
            });
          }
        }
        this.loaderService.hideLoading();
      });
    } else {
      this.loaderService.hideLoading();
      this.notificationsService.error('Please provide valid data in order to login');
    }
  }

  confirmSlackBindingToken() {
    this.slackService.confirmTokenAfterLogin(this.slackParams.extuid, this.slackParams.serviceType, this.authService.currentUser.username, this.slackParams.token)
      .subscribe(res => {
        this.slackBindSuccess = 1;
        console.log(res);
      }, err => {
        this.slackBindSuccess = -1;
        console.log(err.error);
      });
  }
}
