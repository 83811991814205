import {StrengthData} from './password-strength-data.model';

export class Share {
  id: string;
  title: string;
  description: string;
  short_code: string;
  attempts: number;
  access_counts: number;
}

export class ShareContent {
  item: string;
  value: string;
  typeOfField: string;
  readonly: boolean;
  canDelete: boolean;
  strengthData: StrengthData;
  typeMenuOpen: boolean;
  order: number;
}

export class ShareContentType {
  value: string;
  selected: boolean;

  icon: string;
}

export class ShareForm {
  name: string;
  description: string;
  viewingTimes: boolean;
  viewingTimesValue: number;
  askPassword: boolean;
  passwordValue: string;
  failedAttemptsTolerance: boolean;
  failedAttemptsValue: number;
  timedView: boolean;
  timedViewValue: number;
  directSave: boolean;
  secureView: boolean;
  requireLogin: boolean;
  requireMfa: boolean;
  expirationDate: number;
  encryptionType: string;
  ipWhitelist: string[];
  domainWhitelist: string[];
  expirationType: number;
  unlimitedViewTime: boolean;
  expireFrom: number;
  secureViewMessage: string;
  onlyOwnerCanDelete: boolean;

  constructor() {
    this.viewingTimes = false;
    this.unlimitedViewTime = false;
    this.directSave = false;
    this.secureView = false;
    this.requireLogin = false;
    this.requireMfa = false;
    this.askPassword = false;
    this.timedView = false;
    this.failedAttemptsTolerance = false;
    this.viewingTimesValue = 2;
    this.failedAttemptsValue = 3;
    this.timedViewValue = 30;
    this.expirationDate = 2;
    this.expirationType = 0;
    this.encryptionType = 'aes256';
    this.ipWhitelist = new Array<string>();
    this.domainWhitelist = new Array<string>();
    this.expireFrom = 0;
    this.secureViewMessage = '';
    this.onlyOwnerCanDelete = false;
  }
}

export class ShareFormErrors {
  nameError: boolean;
  passwordError: boolean;
  dataError: boolean[];

  constructor() {
    this.dataError = new Array<boolean>();
  }
}

export class ShareCallError {
  error: {
    success: boolean;
    message: string;
    error_code: number;
    additional_data?: any;
  };
}
