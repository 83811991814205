<div class="page-container">
    <div class="purple-header">
        <div class="image-container">
            <img src="/assets/Assets Website/Logo/Credenshare-white.svg" />
        </div>
        <div class="login-form" *ngIf="!success; else successful">
            <p class="form-title">Resend Confirmation Link</p>
            <input type="text" placeholder="Email" [(ngModel)]="email" />
            <p class="error" *ngIf="error">{{error}}</p>
            <button class="signin-button" (click)="resendConfirmationLink()">Resend link</button>
        </div>
    </div>
</div>

<ng-template #successful>
    <app-success title="Link Sent" message="Please check your email for the registration code. Hang on while we redirect you to confirm your registration" imageUrl="/assets/Assets Website/Icn/sent email.svg"></app-success>
</ng-template>
