import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/internal/operators';
import {AuthService} from 'src/app/services/auth.service';
import {UserDeviceService} from '../../services/user-device.service';
import {MatSidenav} from '@angular/material/sidenav';
import {SharesComponent} from 'src/app/views/shares/shares.component';
import {HomeComponent} from 'src/app/views/home/home.component';
import {DataInputTypeService} from 'src/app/services/data-input-type.service';
import {TeamsService} from '../../services/teams.service';
import {SecureRequestsService} from '../../services/secure-requests.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  activeRoute: number;
  deviceType: number;
  showFab: boolean = true;
  hasTeamFeature: boolean;
  hasSecureRequestsFeature: boolean;

  constructor(
    public router: Router,
    public auth: AuthService,
    private device: UserDeviceService,
    private dataInputTypeService: DataInputTypeService,
    private sharesComponent: SharesComponent,
    private homeComponent: HomeComponent,
    private teamService: TeamsService,
    private secureRequestService: SecureRequestsService,
  ) {
    this.getActiveTab().subscribe(res => {
      if (res) {
        this.activeRoute = res.tab;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });
    if (this.router.url.includes('user-account')) {
      this.showFab = false;
    }

    this.hasTeamFeature = await this.teamService.hasAccessToTeamFeature(this.auth.currentUser.username);
    this.hasSecureRequestsFeature = await this.secureRequestService.hasAccessToSecureRequestFeature(this.auth.currentUser.username);
  }

  createShare() {
    this.dataInputTypeService.reset();
    if (this.router.url.includes('shares')) {
      this.sharesComponent.createShare();
    } else {
      this.homeComponent.createShare();
    }
  }

  logout(): void {
    this.auth.logout().then(res => {
      if (res.success) {
        this.router.navigate(['/login']);
      }
    });
  }

  getActiveTab(): Observable<any> {
    return this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(map(({urlAfterRedirects}: NavigationEnd) => {
      if (urlAfterRedirects === '/') {
        return {tab: 0};
      } else if (urlAfterRedirects === '/shares') {
        return {tab: 1};
      } else if (urlAfterRedirects === '/secure-paste') {
        return {tab: 2};
      } else if (urlAfterRedirects === '/secure-requests') {
        return {tab: 3};
      } else if (urlAfterRedirects === '/my-teams') {
        return {tab: 4};
      } else if (urlAfterRedirects === '/templates') {
        return {tab: 5};
      } else if (urlAfterRedirects === '/user-account') {
        return {tab: 6};
      }
    }));
  }

  openSidenav() {
    this.sidenav.open();
  }

  closeSidenav() {
    if (this.deviceType === 0) {
      this.sidenav.close();
    }
  }
}
