<div *ngIf="!isEmbedMode">
  <ng-container *ngTemplateOutlet="requestSubmissionFullComponent"></ng-container>
</div>

<div *ngIf="isEmbedMode" class="h-full">
  <ng-container *ngTemplateOutlet="requestSubmissionEmbedComponent"></ng-container>
</div>
<ng-template #requestSubmissionEmbedComponent>
  <div class="flex flex-col h-full w-full">
    <div class="w-full bg-[#773eb1] h-14 flex items-center px-4">
      <a href="https://credenshare.io"><img src="/assets/Assets Website/Logo/Credenshare-white.svg" class="h-9"/></a>
    </div>
    <re-captcha #captchaRef size="invisible" (resolved)="$event && confirmCaptcha($event)"></re-captcha>
    <ng-container class="elements-wrapper">
      <div class="flex-1">
        <div class="credentials-box !m-0 !w-full !max-w-full !rounded-none p-5"
             *ngIf="secureRequestBox && !submittedRequest; else expiredBox">
          <h3 class="!m-0">Secure Request Form</h3>
          <p *ngIf="failedCaptcha" class="failed-captcha">Captcha verification failed. Please refresh the page.</p>
          <ng-container *ngIf="this.secureRequestFull && this.secureRequestFull.metadata">
            <div class="input-wrapper !w-full"
                 *ngFor="let input of this.secureRequestFull.metadata.fields; let i = index">
              <span class="field-title">{{ input.item }}</span>
              <div class="wrapper-inner">
                <input *ngIf="input.typeOfField !== 'date' && input.typeOfField !== 'multiline'"
                       [id]="'user-input-' + i" [(ngModel)]="input.value"
                       [ngClass]="input.typeOfField === 'password' ? 'password-text' : 'regular-text'" class="flex-1"/>
                <textarea *ngIf="input.typeOfField === 'multiline'" class="regular-text flex-1" placeholder="Please enter"
                          [(ngModel)]="input.value">
                    </textarea>
                <mat-form-field *ngIf="input.typeOfField === 'date'" appearance="outline">
                  <input [id]="'user-input-' + i" [(ngModel)]="input.value" readonly placeholder="Choose a date"
                         [ngxMatDatetimePicker]="picker" class="regular-text !border-none flex-1" matInput>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                </mat-form-field>
                <div class="eye-container" *ngIf="input.typeOfField === 'password'">
                  <img class="eye-icon"
                       (click)="dataInputType.resetInputType(i, false); showPassword = !showPassword"
                       [src]="showPassword ? '/assets/Assets Website/Btn/view-on.svg' : '/assets/Assets Website/Btn/view-off.svg'"
                       alt="show/hide password"/>
                </div>
              </div>
            </div>
            <div class="input-wrapper !w-full">
              <div class="notice">
                <mat-icon color="primary">
                  info
                </mat-icon>
                <div>
                  You will be submitting your information securely to the requesting party. All data is transmitted
                  using TLS encryption and
                  stored using AES256 encryption. <span *ngIf="this.secureRequestFull.metadata.expire_from === 0">The information you share will be scrubbed from our database by {{ this.secureRequestFull.expired_at | date: 'MMM dd, yyyy, hh:mm:ss' }}</span>
                </div>
              </div>
            </div>
            <div class="action-buttons !w-full">
              <button class="button-default" (click)="submitRequest()">Submit</button>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-template #expiredBox>
        <app-success *ngIf="!submittedRequest" imageUrl="/assets/Assets Website/Icn/shared expired.svg"
                     [showDashboardLink]="false"
                     [title]="componentMessage ? componentMessage : 'Secure Request Form Expired'"
                     [message]="secureRequestExpired ? 'The secure request form is no longer accessible.' : null"></app-success>
      </ng-template>

      <app-success *ngIf="submittedRequest" imageUrl="/assets/Assets Website/Icn/share created.svg"
                   [showDashboardLink]="true"
                   [title]="componentMessage ? componentMessage : 'Secure Request Form Submitted'"
                   [message]="submittedRequest ? 'Thank you for submitting the requested information. The request sender will receive the data shortly.' : null"></app-success>
    </ng-container>
    <div class="w-full bg-[#773eb1] h-14 px-4 mt-2 text-center flex items-center justify-center">
      <div>
        <a href="https://credenshare.io/privacy-policy" class="text-white text-sm">Privacy Policy</a>
        <span class="text-white text-sm mx-2">|</span>
        <a href="https://credenshare.io/terms-of-service" class="text-white text-sm">Terms of Service</a>
        <div class="flex-1 text-white text-sm">© CredenShare Inc. 2024 - All Rights Reserved</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #requestSubmissionFullComponent>
  <div class="page-container">
    <div class="left-side">
      <re-captcha #captchaRef size="invisible" (resolved)="$event && confirmCaptcha($event)"></re-captcha>
      <ng-container class="elements-wrapper">
        <div class="app-logo">
          <img src="/assets/Assets Website/Logo/Credenshare.svg"/>
        </div>
        <div class="credentials-box" *ngIf="secureRequestBox && !submittedRequest; else expiredBox">
          <h3>Secure Request Form</h3>
          <p *ngIf="failedCaptcha" class="failed-captcha">Captcha verification failed. Please refresh the page.</p>
          <ng-container *ngIf="this.secureRequestFull && this.secureRequestFull.metadata">
            <div class="input-wrapper" *ngFor="let input of this.secureRequestFull.metadata.fields; let i = index">
              <span class="field-title">{{ input.item }}</span>
              <div class="wrapper-inner">
                <input *ngIf="input.typeOfField !== 'date' && input.typeOfField !== 'multiline'"
                       [id]="'user-input-' + i" [(ngModel)]="input.value"
                       [ngClass]="input.typeOfField === 'password' ? 'password-text' : 'regular-text'"/>
                <textarea *ngIf="input.typeOfField === 'multiline'" class="regular-text" placeholder="Please enter"
                          [(ngModel)]="input.value">
                    </textarea>
                <mat-form-field *ngIf="input.typeOfField === 'date'" appearance="outline">
                  <input [id]="'user-input-' + i" [(ngModel)]="input.value" readonly placeholder="Choose a date"
                         [ngxMatDatetimePicker]="picker" class="regular-text !border-none" matInput>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
                </mat-form-field>
                <div class="eye-container" *ngIf="input.typeOfField === 'password'">
                  <img class="eye-icon"
                       (click)="dataInputType.resetInputType(i, false); showPassword = !showPassword"
                       [src]="showPassword ? '/assets/Assets Website/Btn/view-on.svg' : '/assets/Assets Website/Btn/view-off.svg'"
                       alt="show/hide password"/>
                </div>
              </div>
            </div>
            <div class="input-wrapper">
              <div class="notice">
                <mat-icon color="primary">
                  info
                </mat-icon>
                <div>
                  You will be submitting your information securely to the requesting party. All data is transmitted
                  using TLS encryption and
                  stored using AES256 encryption. <span *ngIf="this.secureRequestFull.metadata.expire_from === 0">The information you share will be scrubbed from our database by {{ this.secureRequestFull.expired_at | date: 'MMM dd, yyyy, hh:mm:ss' }}</span>
                </div>
              </div>
            </div>
            <div class="action-buttons">
              <button class="button-default" (click)="submitRequest()">Submit</button>
            </div>
          </ng-container>
        </div>
        <ng-template #expiredBox>
          <app-success *ngIf="!submittedRequest" imageUrl="/assets/Assets Website/Icn/shared expired.svg"
                       [showDashboardLink]="false"
                       [title]="componentMessage ? componentMessage : 'Secure Request Form Expired'"
                       [message]="secureRequestExpired ? 'The secure request form is no longer accessible.' : null"></app-success>
        </ng-template>

        <app-success *ngIf="submittedRequest" imageUrl="/assets/Assets Website/Icn/share created.svg"
                     [showDashboardLink]="true"
                     [title]="componentMessage ? componentMessage : 'Secure Request Form Submitted'"
                     [message]="submittedRequest ? 'Thank you for submitting the requested information. The request sender will receive the data shortly.' : null"></app-success>
      </ng-container>
    </div>
    <div class="right-side">
      <div class="right-side-inner">
        <div class="app-logo">
          <img src="/assets/Assets Website/Logo/Credenshare-white.svg"/>
        </div>
        <div class="description-wrapper">
          <p class="description">Did you know that one of the leading causes for data breaches can be traced back to
            password compromise?

            With CredenShare, Share your sensitive credentials securely without risk of compromise. Whether you send
            them
            over email, text message or restaurant napkin, rest assured that we keep them safe until you're done with
            sharing.</p>
          <div class="buttons-box">
            <button *ngIf="!isLoggedIn" (click)="goToLogin()" class="login">Login</button>
            <button *ngIf="!isLoggedIn" [routerLink]="['/register']" class="new-acc">Create free account</button>
            <button *ngIf="isLoggedIn" [routerLink]="['/']" class="new-acc">Back to dashboard</button>
            <button class="more-info">More Info</button>
          </div>
        </div>

        <div class="logout-button">
          <label>
            <a href="https://credenshare.io/terms-of-service" target="_blank">Terms of service</a>
            and <a href="https://credenshare.io/privacy-policy" target="_blank">Privacy Policy</a>
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>
