<div class="popup-header">
    <h3>Cancel Your Subscription</h3>
    <div (click)="close()" class="close-icon">
        <img style="cursor: pointer;" src="/assets/Assets Website/Btn/close.svg">
    </div>
</div>
<div class="padding-class">
    <app-user-plan [planData]="authService.userPlan" [wide]="true" [cancellingSubscription]="true"></app-user-plan>
    <app-notification message="We will cancel your subscription, but you can use CredenShare before current billing period ends."></app-notification>
    <div class="reasons">
        <h3>Please let us know why you are leaving</h3>
        <div class="reasons-checkboxes">
            <div class="checkbox-container" *ngFor="let r of reasons; let z = last" [ngStyle]="{'flex-wrap': z ? 'wrap' : ''}">
                <input [value]="r.value" [(ngModel)]="r.selected" type="checkbox" />
                <label>{{r.description}}</label>
            </div>
            <div class="checkbox-container">
                <input [value]="otherReason.value" [(ngModel)]="otherReason.selected" type="checkbox" />
                <label>{{otherReason.description}}</label>
                <textarea [(ngModel)]="otherReason.reason" placeholder="Type..."></textarea>
            </div>
        </div>
    </div>
</div>
<div class="confirm-buttons">
    <button (click)="close()" class="button-secondary">Keep Subscription</button>
    <button class="button-default" (click)="cancel()">Cancel Subscription</button>
</div>
