import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {InputValidators} from '../../models/input-validators';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent {
  success: boolean = false;
  errors: string;
  validators = InputValidators;
  confirmPasswordForm: FormGroup = new FormGroup({
    password: new FormControl(),
    confirmPassword: new FormControl()
  });

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  checkCode(): void {
    if (this.confirmPasswordForm.valid) {
      if (this.confirmPasswordForm.value.password === this.confirmPasswordForm.value.confirmPassword) {
        this.authService.forgotPasswordConfirm(
          decodeURIComponent(this.route.snapshot.queryParams['email']),
          this.route.snapshot.queryParams['code'],
          this.confirmPasswordForm.value.password
        ).then(res => {
          if (res && res.success) {
            this.success = true;
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3500);
          } else if (res && res.error) {
            this.errors = res.error.message;
          }
        });
      } else {
        this.errors = 'The passwords don\'t match';
      }
    } else {
      this.errors = 'Please provide valid data to proceed';
    }
  }
}
