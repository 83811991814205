import {Component, OnInit} from '@angular/core';
import {InputValidators} from 'src/app/models/input-validators';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-reset-registration',
  templateUrl: './reset-registration.component.html',
  styleUrls: ['./reset-registration.component.scss']
})
export class ResetRegistrationComponent implements OnInit {
  email: string;
  validators = InputValidators;

  error: string;
  success: boolean = false;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  resendConfirmationLink(): void {
    if (!this.email && !this.validators.emailRegex.test(this.email)) {
      this.error = 'Please provide a valid email';
      return;
    } else {
      this.authService.resendConfirmationLink(this.email, 'register').then(res => {
        if (res && res.success) {
          this.success = true;
        } else {
          this.error = res.err.message;
        }
      });
    }
  }
}
