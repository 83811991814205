import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InputValidators } from '../../models/input-validators';

@Component({
  selector: 'app-input-popup',
  templateUrl: './input-popup.component.html',
  styleUrls: ['./input-popup.component.scss']
})
export class InputPopupComponent implements OnInit {
  list: string[] = new Array<string>();
  itemValue: string;

  regex: RegExp;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {type: number},
    private dialogRef: MatDialogRef<InputPopupComponent>,
    private notificationService: ToastrService
  ) { 
    if (data.type === 0) {
      // it's for IP
      this.regex = InputValidators.ipRegex;
    } else {
      // it's for Domain
      this.regex = InputValidators.domainRegex;
    }
   }

  ngOnInit(): void {
  }

  addToList() {
    if (this.itemValue && this.itemValue.length > 0) {
      if (this.regex.test(this.itemValue)) {
        this.list.push(this.itemValue);
        this.itemValue = null;
      } else {
        this.notificationService.error('Please provide valid data');
      }
    }
  }

  removeItem(index: number) {
    this.list.splice(index, 1);
  }

  close() {
    this.dialogRef.close(this.list);
  }

}
