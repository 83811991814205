import {AfterViewInit, Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserBillingInfo} from 'src/app/models/user.model';
import {AuthService} from 'src/app/services/auth.service';
import {SubscriptionPlansService} from 'src/app/services/subscription-plans.service';
import {UserService} from 'src/app/services/user.service';
import {CountriesService} from '../../services/countries.service';

import {environment} from 'src/environments/environment';
import {Country} from 'src/app/models/countries.model';

declare var recurly;

@Component({
  selector: 'app-recurly',
  templateUrl: './recurly.component.html',
  styleUrls: ['./recurly.component.scss']
})
export class RecurlyComponent implements OnInit, AfterViewInit {
  recurly: any;
  elements: any;
  paypal: any;

  creditCardError: string;
  payPallError: string;

  creditCardPayment: boolean = false;
  showBillingData: boolean;

  countriesList: Country[] = new Array<Country>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { plan: any, billingData: UserBillingInfo, addCardOnly: false, buyingAddon: boolean },
    private dialogRef: MatDialogRef<RecurlyComponent>,
    private subscriptionService: SubscriptionPlansService,
    private authService: AuthService,
    private zone: NgZone,
    private userService: UserService,
    private countriesService: CountriesService,
  ) {
    this.recurly = recurly.configure(environment.recurlyPublicKey);
    this.countriesList = this.countriesService.countryPicker.sort((c1, c2) => c1.name.localeCompare(c2.name));
  }

  ngOnInit() {
    if (!this.data.billingData) {
      this.newCreditCard();
    } else {
      this.showBillingData = true;
    }
  }

  newCreditCard() {
    this.creditCardPayment = true;
    this.showBillingData = false;
    this.elements = recurly.Elements();
    setTimeout(() => {
      const cardElement = this.elements.CardElement({
        inputType: 'mobileSelect',
        style: {
          fontSize: '1em',
          placeholder: {
            color: 'gray !important',
            fontWeight: 'bold',
            content: {
              number: 'Card number',
              cvv: 'CVC',
            }
          },
          invalid: {
            fontColor: 'red'
          },
        }
      });
      cardElement.attach('#recurly-elements');
    }, 500);

  }

  ngAfterViewInit() {

  }

  // @TODO: code for paypal recyrly payments

  // onTabChanged(event: any) {
  //   if (event.index === 1 ) {
  //     this.payPalTab();
  //   }
  // }

  // payPalTab() {
  //   this.paypal = recurly.PayPal({
  //     display: { displayName: this.data.description }
  //   });
  //   this.paypal.start();

  //   this.paypal.on('error', err => {
  //     this.zone.run(() => {
  //       console.log('ERROR:', err);
  //       this.payPallError = err.message;
  //     });
  //   });

  //   this.paypal.on('token', token => {
  //     this.zone.run(() => {
  //       console.log('TOKEN:', token);
  // let body = {
  //   "currency": "USD",
  //   "recurly_token": token.id,
  //   "plan_code": this.data.plan_code,
  //   "auto_renew": true
  // };

  // this.subscriptionService.subscribeToPlan(this.authService.currentUser.username, body).subscribe(res => {
  //   console.log(res);
  //   if (res) {
  //     this.dialogRef.close();
  //   }
  // });
  //     });
  //   });
  // }

  payWithCreditCard(savedCreditCard: boolean):void {
    this.creditCardError = null;
    const form = document.querySelector('#recurlyForm');
    if (!savedCreditCard) {
      recurly.token(this.elements, form, (err, token) => {
        this.zone.run(() => {
          if (err) {
            this.creditCardError = err.message;
          } else {
            if (!this.data.addCardOnly) {
              if (this.data.buyingAddon) {
                let body = this.data.plan;
                body['recurly_token'] = token.id;
                this.userService.purchaseAddon(this.authService.currentUser.username, body).subscribe((res) => {
                  if (res) {
                    this.close();
                  }
                });
              } else {
                const recurlyData = {
                  currency: 'USD',
                  recurly_token: token.id,
                  plan_code: this.data.plan.plan_code,
                  auto_renew: true
                };
                this.changeSubscription(recurlyData);
              }
            } else {
              this.userService.updateUserBillingInfo(this.authService.currentUser.username, token.id).subscribe(res => {
                if (res) {
                  this.dialogRef.close(res);
                }
              });
            }
          }
        });
      });
    } else {
      const body = {currency: 'USD',plan_code: this.data.plan.plan_code, auto_renew: true};
      this.changeSubscription(body);
    }
  }

  changeSubscription(body: any) {
    if (this.authService.userPlan.subscription_id === '') {
      this.subscriptionService.subscribeToPlan(this.authService.currentUser.username, body).subscribe(res => {
        if (res) {
          this.dialogRef.close({success: true});
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.subscriptionService.changeSubscription(this.authService.userPlan.subscription_id, body).subscribe(res => {
        if (res) {
          this.dialogRef.close({success: true});
        }
      }, error => {
        console.log(error);
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
