<div class="popup-header">
  <h3 class="popup-title">Create Team</h3>
  <div class="templates-box">
    <div (click)="close()" class="close-icon"><img src="/assets/Assets%20Website/Btn/close.svg"></div>
  </div>
</div>
<form [formGroup]="newTeamForm" (ngSubmit)="createNewTeam()">
  <div class="form-container">
    <div class="input-container">
      <input type="text" placeholder="Team Name" required formControlName="teamName" />
    </div>
    <div class="input-container">
      <mat-list>
        <div class="list-header">
          <h3 mat-subheader>Members</h3>
          <button class="button-default" type="button" (click)="showAddMemberDialog()">Add</button>
        </div>
        <mat-list-item mat-list-item *ngIf="teamUsers.length === 0">
          <div class="item-wrapper" style="justify-content: center;">
            <div class="item-name">No members</div>
          </div>
        </mat-list-item>
        <mat-list-item mat-list-item *ngFor="let teamUser of teamUsers; let i = index">
          <div class="item-wrapper">
            <div class="item-name">{{teamUser.email}}</div>
            <div class="item-action" title="Delete member" (click)="removeNewMemberFromList(i)">
              <img src="/assets/Assets%20Website/Btn/close.svg"/>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="confirm-buttons">
    <button (click)="close()" type="button" class="button-secondary">Close</button>
    <button class="button-default" type="submit" [disabled]="!newTeamForm.valid">Create</button>
  </div>
</form>
