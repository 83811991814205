import {Component, Input, OnInit} from '@angular/core';
import {StrengthData} from '../../../models/password-strength-data.model';
import {PasswordMeterService} from '../../../services/password-meter.service';
import {FormControl, Validators} from '@angular/forms';
import {UserSubscription} from '../../../models/plan.model';

@Component({
  selector: 'app-password-to-view',
  templateUrl: './password-to-view.component.html',
  styleUrls: ['./password-to-view.component.scss']
})
export class PasswordToViewComponent implements OnInit {
  @Input('user-plan') public userPlan: UserSubscription;
  showPassword: boolean = false;

  generalPasswordStrength: StrengthData = new StrengthData();
  passwordField: FormControl = new FormControl({value: '', disabled: true});
  askPassword: FormControl = new FormControl(false);
  failedAttemptsToleranceEnabled: FormControl = new FormControl({value: false, disabled: true});
  failedAttemptsValue = 3;
  secureViewPromptEnabled: FormControl = new FormControl(false);
  secureViewMessage: FormControl = new FormControl({value: '', disabled: true});

  constructor(
    private passwordMeter: PasswordMeterService) {
  }

  ngOnInit(): void {
    this.askPassword.valueChanges.subscribe((value) => {
      if (value) {
        this.passwordField.setValidators([Validators.required]);
        this.passwordField.enable();
        this.failedAttemptsToleranceEnabled.enable();
      } else {
        this.passwordField.clearValidators();
        this.passwordField.disable();
        this.failedAttemptsToleranceEnabled.disable();
      }
      this.passwordField.updateValueAndValidity();
    });

    this.secureViewPromptEnabled.valueChanges.subscribe((value) => {
      if (value) {
        this.secureViewMessage.enable();
      } else {
        this.secureViewMessage.disable();
      }
    });
  }

  checkSecurity(event): void {
    event.stopPropagation();
    this.passwordMeter.checkSecurity(event.target.value).then((res: StrengthData) => {
      if (res) {
        this.generalPasswordStrength = res;
      }
    });
  }

  hasValidationErrors(): boolean {
    return this.passwordField.invalid;
  }

  reset(): void {
    this.askPassword.reset(false);
    this.failedAttemptsToleranceEnabled.reset(false);
    this.secureViewPromptEnabled.reset(false);
    this.secureViewMessage.reset('');
    this.passwordField.reset('');
    this.generalPasswordStrength = new StrengthData();
  }
}
