import {Component, OnInit} from '@angular/core';
import {Team} from '../../../models/team.model';
import {Subject} from 'rxjs';
import {DataService} from '../../../services/data.service';
import {TeamsService} from '../../../services/teams.service';
import {AuthService} from '../../../services/auth.service';
import {UserDeviceService} from '../../../services/user-device.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmPopupComponent} from '../../../shared/confirm-popup/confirm-popup.component';
import {ActivatedRoute} from '@angular/router';
import {EditTeamComponent} from '../dialogs/edit-team/edit-team.component';
import {AddMemberComponent} from '../dialogs/add-member/add-member.component';
import {debounceTime} from 'rxjs/internal/operators';

@Component({
  selector: 'app-view-team',
  templateUrl: './view-team.component.html',
  styleUrls: ['./view-team.component.scss']
})
export class ViewTeamComponent implements OnInit {

  team: Team = new Team();
  searchValue = new Subject<string>();
  noSearchResults: boolean;
  deviceType: number;
  owner: string;
  hasAccess: boolean;

  constructor(
    private dataService: DataService,
    private teamsService: TeamsService,
    private authService: AuthService,
    private device: UserDeviceService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private teamService: TeamsService,
  ) {
  }

  ngOnInit(): void {
    this.device.deviceType.subscribe(type => {
      this.deviceType = type;
    });

    this.getTeamMembers();
    this.searchValue.pipe(debounceTime(500)).subscribe(data => {
      this.getTeamMembers(true, data);
    });


    this.teamService.hasAccessToTeamFeature(this.authService.currentUser.username)
      .then(res => this.hasAccess = res);
  }

  filtering(event): void {
    this.searchValue.next(event.target.value);
  }

  deleteMember(index: number): void {
    const confirm = this.dialog.open(ConfirmPopupComponent, {
      width: '70%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {header: 'Delete member', message: 'Are you sure you want to delete this member?'}
    });

    confirm.afterClosed().subscribe(confirmedClose => {
      if (confirmedClose) {
        this.teamsService.removeMember(this.team.id, this.team.users[index].user_id).subscribe(res => {
          if (res) {
            this.team.users.splice(index, 1);
          }
        });
      }
    });
  }

  resendInvitation(email: string): void {
    this.teamsService.resendInvitation(this.route.snapshot.params['team-id'], email).subscribe(() => {});
  }

  getTeamMembers(isUserSearching?: boolean, keyword?: string): void {
    this.teamsService.getTeamById(this.route.snapshot.params['team-id']).subscribe(resp => {
      if (resp) {
        this.team = resp as Team;
        if (keyword) {
          this.team.users = this.team.users.filter(u => u.email.includes(keyword));
          if (this.team.users.length > 0) {
            this.noSearchResults = !isUserSearching;
          } else {
            this.noSearchResults = isUserSearching;
          }
        }
        this.dataService.pageTitle.next(resp.name);
      }
    });
  }

  showEditTeamDialog(): void {
    const editTeamDialogRef = this.dialog.open(EditTeamComponent, {
      width: '70%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {
        team: this.team,
      },
    });

    editTeamDialogRef.componentInstance.editTeamEvent.subscribe(resp => {
      if (resp) {
        this.getTeamMembers();
      }
    });
  }

  showAddMemberDialog(): void {
    const addMemberDialogRef = this.dialog.open(AddMemberComponent, {
      width: '70%',
      height: 'fit-content',
      panelClass: 'popup-container',
      data: {
        team: this.team,
        emitEventOnly: false,
      },
    });

    addMemberDialogRef.componentInstance.newMemberEvent.subscribe(resp => {
      if (resp) {
        this.getTeamMembers();
      }
    });
  }

  isOwner(): boolean {
    if (this.owner) {
      return this.owner === this.authService.currentUser.username;
    } else {
      if (this.team.users && this.team.users.length > 0) {
        for (const u of this.team.users) {
          if (u.role === 'owner' && this.authService.currentUser.username === u.user_id) {
            this.owner = u.user_id;
            return true;
          }
        }
      }
      return false;
    }
  }
}
